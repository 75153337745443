<template>
  <div id="app" :class="{'dark' : state.theme === 'dark'}" :dir="state.lang === 'fa'? 'rtl' : 'ltr'">
    <the-navbar v-if="width >= 993"/>
    <the-mobile-navbar v-if="width <= 992"/>
    <router-view class="main-view"/>
    <the-footer v-if="!$route.matched ||
                      (
                          $route.matched && $route.matched[0].name !== 'Panel' &&
                          $route.matched[0].name !== 'Trade'&&
                          $route.matched[0].name !== 'EasyTrade'
                      )
     "/>
  </div>
</template>

<script>

import TheNavbar from "../src/components/shared/navbar/TheNavbar";
import TheFooter from "./components/shared/footer/TheFooter";
import TheMobileNavbar from "./components/shared/navbar/TheMobileNavbar";

export default {
  name: 'App',
  components: {TheMobileNavbar, TheNavbar, TheFooter},
  data() {
    return {
      width: 0,
      windowHeight: screen.width,
    }
  },

  watch: {
    windowHeight() {
      this.width = this.windowHeight;
      return this.width
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.width = screen.width
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },


  methods: {
    close() {
      this.state.account = false;
      this.state.help = false;
      this.state.alarm = false;
    },

    // mainclose(e){
    //   if (!e.target.closest('.help-menu , .user-info')) {
    //     this.state.help = false;
    //     this.state.account = false;
    //     this.state.alarm = true;
    //   }
    // },

    onResize() {
      this.windowHeight = screen.width;
    }
  }

}
</script>

<style>
#app {
  max-width: 1364px;
  margin: 0 auto;
  background-color: var(--background-global);
  color: var(--second-font-color);
  transition: background-color 1s;
}

.main-view {
  display: flex;
  flex-direction: column;
}

</style>
