<template>
  <div class="mainReward">
    <div class="title">
      <p>{{ state.lng.panel.rewardTitle }}</p>
    </div>
    <div class="border"></div>
    <div class="rewards">
      <div class="item-reward">
        <the-reward v-for="item in items" :key="item.rewardText" :items="item"/>
      </div>
    </div>
  </div>
</template>

<script>
import TheReward from "./TheReward";

export default {
  name: "MainReward",
  components: {TheReward},
  data(){
    return{
      items:[
        {rewardText:"rewardText1" , expired:false , date:"1400/08/10"},
        {rewardText:"rewardText2" , expired:true , date:"1400/08/10"},
        {rewardText:"rewardText3" , expired:true , date:"1400/08/10"},
        {rewardText:"rewardText4" , expired:true , date:"1400/08/10"},
        {rewardText:"rewardText5" , expired:false , date:"1400/08/10"},
        {rewardText:"rewardText6" , expired:true , date:"1400/08/10"},
        {rewardText:"rewardText7" , expired:true , date:"1400/08/10"},
        {rewardText:"rewardText8" , expired:false , date:"1400/08/10"},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.mainReward {
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  background-color: var(--background-global);
  height: auto;
  width: 90%;
  margin: 20px auto;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  gap: 10px;

  .title {
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    border-bottom: 1px solid var(--border-light);
    width: 100%;

    p {
      font-weight: bold;
    }
  }

  .border {
    border-right: 2px dashed lightgray;
    position: absolute;
    left: 50%;
    right: 50%;
    height: auto;
  }

  .rewards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    .item-reward{
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 50px;
      flex-wrap: wrap;
    }

  }
}

@media (max-width: 800px) {
  .rewards {
    display: flex;
    width: 100% !important;
    flex-wrap: wrap;
    justify-content: space-between;

  }
}
</style>