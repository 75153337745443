<template>
  <div class="main-table">
    <table @click="$router.push({name : 'Trade'})">
      <tr class="border-bottom">
        <th class="first-th">
          {{ state.lng.tableTitle.title1 }}
        </th>

        <th>
          {{ state.lng.tableTitle.title2 }}
        </th>

        <th v-if="width >= 450">
          {{ state.lng.tableTitle.title3 }}
        </th>

        <th>
          {{ state.lng.tableTitle.title4 }}
        </th>

        <th v-if="width > 768">
          {{ state.lng.tableTitle.title5 }}
        </th>
      </tr>
      <tr v-for="(d,index) in data" :key="index">
        <td>
          <div class="items">
            <div class="logo-coins">
              <img v-if="width > 768" class="logo-img" :src="require('../../assets/coins/'+d.logo)" alt="">
            </div>
            <div v-if="state.lang === 'fa'" class="fa-small">
              <span>{{ state.lng.dataTable[d.name] }}</span>
              <small v-if="width > 768"> {{ state.lng.dataTable[d.smallName] }}</small>
            </div>

            <div v-if="state.lang === 'en'" class="en-small">
              <span>{{ state.lng.dataTable[d.name] }}</span>
              <small v-if="width > 768"> {{ state.lng.dataTable[d.smallName] }}</small>
            </div>

          </div>
        </td>
        <td>

          <span>{{ state.lng.dataTable[d.Price] }}</span>

        </td>
        <td v-if="width >= 450">

            <span
                :class="{'red-number' :d.Changes <= 0 , 'green-number' : d.Changes > 0}">
                {{"%"+ " "  + d.Changes}}
            </span>

        </td>
        <td>

          <span>{{ state.lng.dataTable[d.Volume] }}</span>

        </td>
        <td v-if="width > 768">

          <img :src="require('../../assets/public/'+d.chart)" alt="">

        </td>

      </tr>
    </table>
    <a v-if="$route.name === 'Home'" @click="$router.push({name : 'TheMarket'})" class="more"
       href="">{{ state.lng.dataTable.more }}</a>
  </div>
</template>

<script>
export default {
  name: "TheTable",
  data() {
    return {
      width: 0,
      windowHeight: screen.width,

      data: [
        {
          name: 'BTC',
          Price: 'Price',
          Changes: 10,
          Volume: 'Volume',
          chart: 'chart.png',
          logo: 'BTC.png',
          smallName: 'smallName'
        },
        {
          name: 'BCH',
          Price: 'Price',
          Changes: 5,
          Volume: 'Volume',
          chart: 'chart.png',
          logo: 'BCH.png',
          smallName: 'smallName'
        },
        {
          name: 'BNB',
          Price: 'Price',
          Changes: 4,
          Volume: 'Volume',
          chart: 'chart.png',
          logo: 'BNB.png',
          smallName: 'smallName'
        },
        {
          name: 'DOGE',
          Price: 'Price',
          Changes: -3,
          Volume: 'Volume',
          chart: 'chart.png',
          logo: 'DOGE.png',
          smallName: 'smallName'
        },
        {
          name: 'ETH',
          Price: 'Price',
          Changes: -5,
          Volume: 'Volume',
          chart: 'chart.png',
          logo: 'ETH.png',
          smallName: 'smallName'
        },
        {
          name: 'LTC',
          Price: 'Price',
          Changes: 20,
          Volume: 'Volume',
          chart: 'chart.png',
          logo: 'LTC.png',
          smallName: 'smallName'
        },
        {
          name: 'TRON',
          Price: 'Price',
          Changes: -10,
          Volume: 'Volume',
          chart: 'chart.png',
          logo: 'TRON.png',
          smallName: 'smallName'
        },

      ],

      title: [
        {title: 'title1'},
        {title: 'title2'},
        {title: 'title3'},
        {title: 'title4'},
        {title: 'title5'},
      ],

    }
  },

  watch: {
    windowHeight() {
      this.width = this.windowHeight;
      return this.width
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.width = screen.width;
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowHeight = screen.width;
    }
  }
}
</script>

<style lang="scss" scoped>
.red-number {
  background: rgba(254, 42, 42, 0.12);
  color: #FE2A2A;
  width: 7vw;
  height: auto;
  display: inline-block;
  border-radius: 5px;
  text-align: center;
}

.green-number {
  background: rgba(0, 183, 106, 0.13);
  color: #00A35F;
  width: 7vw;
  height: auto;
  display: inline-block;
  border-radius: 5px;
  text-align: center;
}

.more {
  color: var(--font-alternate);
}

.main-table {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;

  table {
    width: 100%;
    margin: 30px auto;
    border-collapse: collapse;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    table-layout: fixed;
    font-size: clamp(12px, 2vw, 15px);
    line-height: 2.5;
    cursor: pointer;


    .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .border-bottom {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      height: 50px;
    }

    tr {

      th {
        width: auto;
      }

      td {

        text-align: center;

        .items {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          width: fit-content;
        }

        .logo-coins {

        }

        .logo-img {
          width: 35px;
          height: 35px;
        }

        .fa-small {
          display: flex;
          flex-direction: column;
          color: var(--font-light);
          position: relative;
          text-align: right;


          small {
            color: var(--second-font-color);
            position: relative;
            top: -10px;
          }
        }

        .en-small {
          display: flex;
          flex-direction: column;
          color: var(--font-light);
          text-align: left;

          small {
            color: var(--second-font-color);
          }
        }

      }
    }
  }
}

@media (max-width: 450px) {
  .main-table {
    padding: 0 !important;
  }
}

@media (max-width: 350px) {
  .main-table {
    padding: 0 !important;
  }
}
</style>

<style>
[dir="rtl"] th {
  text-align: center;
}

[dir="rtl"] .first-th {
  text-align: right;
  padding-right: 5%;
}


[dir="ltr"] th {
  text-align: center;
}

[dir="ltr"] .first-th {
  text-align: left;
  padding-left: 5%;
}
</style>
