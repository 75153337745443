import TheHomePage from "../components/Home/TheHomePage";
import TheRules from "../components/AboutUs/TheRules";
import TheMarket from "../components/AboutUs/TheMarket";
import TheAboutUs from "../components/AboutUs/TheAboutUs";
import FAQ from "../components/AboutUs/FAQ";
import TheGuide from "../components/AboutUs/TheGuide";
import Authentication from "../components/Authentication/Authentication";
import Login from "../components/Authentication/Login";
import Signup from "../components/Authentication/Signup";
import ForgetPassWord from "../components/Authentication/ForgetPassWord";
import ResetPassword from "../components/Authentication/ResetPassword";
import SingUp2fa from "../components/Authentication/SingUp2fa";
import ForgetVerify from "../components/Authentication/ForgetVerify";
import EditPassword from "../components/Authentication/EditPassword";
import Login2fa from "../components/Authentication/Login2fa";
import KYC from "../components/Panel/KYC/KYC";
import MobileNumber from "../components/Panel/KYC/MobileNumber";
import IdCard from "../components/Panel/KYC/IdCard";
import BankInfo from "../components/Panel/KYC/BankInfo";
import SendImage from "../components/Panel/KYC/SendImage";
import Address from "../components/Panel/KYC/Address";
import UserAccount from "../components/Panel/UserAccount";
import Panel from "../components/Panel/Panel";
import Wallet from "../components/Panel/Wallet/Wallet";
import History from "../components/Panel/History/TheHistory";
import Setting from "../components/Panel/Setting/TheSetting";
import AddFrinds from "../components/Panel/AddFrinds/AddFrinds";
import Dashbord from "../components/Panel/Dashbord/Dashbord";
import Trade from "../components/Trade/trade";
import easytrade from "../components/Trade/easy-trader/easytrade";
import MainReward from "../components/Panel/Reward/MainReward";
import fee from "../components/AboutUs/the-fees"
import reward from "../components/Panel/Reward/MainReward"

export default [
    {
        path: '/',
        name: 'Home',
        component: TheHomePage
    },
    {
        path: '/TheRules',
        name: 'TheRules',
        component: TheRules
    },
    {
        path: '/TheMarket',
        name: 'TheMarket',
        component: TheMarket
    },
    {
        path: '/TheAboutUs',
        name: 'TheAboutUs',
        component: TheAboutUs
    },
    {
        path: '/FAQ',
        name: 'FAQ',
        component: FAQ
    },
    {
        path: '/fee',
        name: 'Fee',
        component: fee
    },
    {
        path: '/reward',
        name: 'Reward',
        component: reward
    },
    {
        path: '/TheGuide',
        name: 'TheGuide',
        component: TheGuide
    },
    {
        path: '/UserAccount',
        name: 'UserAccount',
        component: UserAccount
    },
    {
        path: '/Trade',
        name: 'Trade',
        component: Trade
    },
    {
        path: '/EasyTrade',
        name: 'EasyTrade',
        component: easytrade
    },


    {
        path: '/panel',
        name: 'Panel',
        component: Panel,
        children: [
            {
                path: 'KYC',
                name: 'KYC',
                component: KYC,
                redirect:'KYC/MobileNumber',
                children: [

                    {
                        path: 'MobileNumber',
                        name: 'MobileNumber',
                        component: MobileNumber
                    },

                    {
                        path: 'IdCard',
                        name: 'IdCard',
                        component: IdCard
                    },

                    {
                        path: 'SendImage',
                        name: 'SendImage',
                        component: SendImage
                    },

                    {
                        path: 'BankInfo',
                        name: 'BankInfo',
                        component: BankInfo
                    },

                    {
                        path: 'Address',
                        name: 'Address',
                        component: Address
                    },

                ]
            },
            {
                path:'Wallet',
                name:'Wallet',
                component:Wallet,
            },
            {
                path:'History',
                name:'History',
                component:History,
            },
            {
                path:'Setting',
                name:'Setting',
                component:Setting,
            },
            {
                path: 'AddFrinds',
                name: 'AddFrinds',
                component: AddFrinds
            },
            {
                path: 'Dashbord',
                name: 'Dashbord',
                component: Dashbord
            },
            {
                path: 'Reward',
                name: 'Reward',
                component: MainReward
            },

        ]
    },

    {
        path: '/Authentication',
        name: 'Authentication',
        component: Authentication,
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login
            },
            {
                path: 'ForgetPassWord',
                name: 'ForgetPassword',
                component: ForgetPassWord
            },
            {
                path: 'ResetPassword',
                name: 'ResetPassword',
                component: ResetPassword
            },
            {
                path: 'Signup',
                name: 'Signup',
                component: Signup
            },
            {
                path: 'SingUp2fa',
                name: 'SingUp2fa',
                component: SingUp2fa
            },
            {
                path: 'ForgetVerify',
                name: 'ForgetVerify',
                component: ForgetVerify
            },
            {
                path: 'EditPassword',
                name: 'EditPassword',
                component: EditPassword
            },
            {
                path: 'Login2fa',
                name: 'Login2fa',
                component: Login2fa
            },
            {
                path: '/Authentication',
                redirect: '/Authentication/login'
            }

        ]
    },


]