<template>
  <div class="main-About">
    <the-header-about :value="state.lng.About.guide"/>
    <div class="main-question">
      <div class="right">
        <the-question-box v-for="(item , index) in data" :key="index" :data="item"/>
      </div>
      <div v-if="width > 992"  class="left">
        <img src="../../assets/About/setting.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import TheHeaderAbout from "../tools/TheHeaderAbout";
import TheQuestionBox from "../tools/TheQuestionBox";

export default {
  name: "TheGuide",
  components: {TheQuestionBox, TheHeaderAbout},
  data(){
    return{
      width : 0,
      windowHeight: screen.width,
      data:[
        {question : "question1" , answer : "answer1"},
        {question : "question2" , answer : "answer2"},
        {question : "question3" , answer : "answer3"},
        {question : "question4" , answer : "answer4"},
        {question : "question5" , answer : "answer5"},
        {question : "question6" , answer : "answer6"},
      ]
    }
  },

  watch: {
    windowHeight() {
      this.width = this.windowHeight;
      return this.width
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowHeight = screen.width;
    }
  }


}
</script>

<style lang="scss" scoped>

.main-question {
  display: flex;
  flex-direction: row;
  padding: 20px 60px;
  flex-wrap: wrap;

  .right {
    flex-grow: 2;
    flex-basis: 0;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .left {
    flex-grow: 1;
    flex-basis: 100px;
    justify-content: flex-end;
    display: flex;

    img{
      width:auto;
      height: 70%;
    }
  }
}
</style>