<template>
<div class="main-About">
  <the-header-about :value="state.lng.About.about"/>

  <div class="row-1">
    <div class="right">
      <p class="ph-1">{{state.lng.About.ph1}}</p>
      <p class="ph-2">{{state.lng.About.ph2}}</p>
      <p class="ph-3">{{state.lng.About.ph3}}</p>
    </div>
    <div class="left">
      <img v-if="state.theme === 'light'" src="../../assets/About/background.png" alt="">
      <img v-if="state.theme === 'dark'" src="../../assets/About/background-dark.png" alt="">
    </div>
  </div>

  <div class="row-2">
    <h3 class="aboutTitle">{{state.lng.About.aboutTitle}}</h3>
    <p class="aboutText">{{state.lng.About.aboutText}}</p>
  </div>

  <div class="row-3">
    <div class="box" v-for="(item , index) in items" :key="index" >
      <div class="img-box"> <img :src="require('../../assets/Home/'+item.logo)" alt=""></div>
      <div class="txt-box">
        <p class="title">{{ state.lng.possibilities[item.title] }}</p>
        <span class="text">{{ state.lng.possibilities[item.text] }}</span>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import TheHeaderAbout from "../tools/TheHeaderAbout";
export default {
  name: "TheAboutUs",
  components: {TheHeaderAbout},
  data() {
    return {
      items: [
        {title: 'title1', text: 'text1', logo: 'like.png'},
        {title: 'title2', text: 'text2', logo: 'secure.png'},
        {title: 'title3', text: 'text3', logo: 'headphones.png'},
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.row-1{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 10px 100px;
  gap: 20px;
  color: var(--font-alternate);
  font-size: clamp(12px , 2vw , 14px);

  .right{
    flex-grow: 1;
    flex-basis: 100px;
  }

  .left{
    flex-grow: 1;
    flex-basis: 100px;

    img{
      width: 100%;
    }

  }
}

.row-2{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 100px;
  gap: 20px;
  color: var(--font-alternate);
  font-size: clamp(12px , 2vw , 14px);

  .aboutTitle{
    font-weight: bold;
  }

}

.row-3{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 100px 70px 100px;
  gap: 33px;

  .box{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px;
    background-color: var(--background-global);
    border-radius: 5px;
    flex-basis: 20px;

    .title {
      font-size: small;
      color: var(--font-light);
    }

    .text {
      font-size:clamp(12px,2vw,14px);
    }

  }

}

@media (max-width: 500px) {
  .row-1 {
    margin: 10px 30px;
    flex-direction: column !important;
  }

  .row-2 {
    margin: 10px 30px;
    flex-direction: column !important;
  }

  .row-3{
    margin: 10px 30px;
    flex-direction: column !important;
  }
}
</style>