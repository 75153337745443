<template>
  <div class="mainTable">
    <div class="header">
        <span :class="{'selectedi' : select === 'Tomandata'}"
              @click="select = 'Tomandata';data=Tomandata">{{ state.lng.trade.toman }}</span>
      <span :class="{'selectedi2' : select === 'Usdtdata'}"
            @click="select = 'Usdtdata';data=Usdtdata;">{{ state.lng.trade.usdt }}</span>
    </div>
    <table>
      <tr>
        <th style="text-align: right">{{ state.lng.trade.TitleName }}</th>
        <th>{{ state.lng.trade.Price }}</th>
        <th>{{ state.lng.trade.Changes }}</th>
      </tr>
      <tr v-for="(item , index) in data" :key="index">

        <td>
          <div class="space">
            <img :src="require('../../assets/coins/' +item.logo+ '.png')" alt="item.name">
            <span>{{ state.lng.dataTable[item.logo] }}</span>
          </div>
        </td>

        <td>
          <div>
            <span>{{ item.Price.toLocaleString() }}</span>
          </div>
        </td>

        <td>
          <div>
            <span :class="{'red' : item.Changes < 0 , 'green' : item.Changes > 0}">{{ item.Changes }}</span>
          </div>
        </td>

      </tr>

    </table>
  </div>
</template>

<script>
export default {
  name: "TheTradeTable",
  data() {
    return {

      data: [],


      select: "Tomandata",

      Tomandata: [
        {
          logo: 'BTC',
          Price: 1322489.34,
          Changes: 98,
        },
        {
          logo: 'BCH',
          Price: 1322489.34,
          Changes: -47,
        },
        {
          logo: 'BNB',
          Price: 1322489.34,
          Changes: 98,
        },
        {
          logo: 'DOGE',
          Price: 1322489.34,
          Changes: -47,
        },
        {
          logo: 'ETH',
          Price: 1322489.34,
          Changes: 98,
        },

        {
          logo: 'LTC',
          Price: 1322489.34,
          Changes: 98,
        },
        {
          logo: 'TRON',
          Price: 1322489.34,
          Changes: -47,
        },
        {
          logo: 'ETH',
          Price: 1322489.34,
          Changes: 98,
        },

        {
          logo: 'LTC',
          Price: 1322489.34,
          Changes: 98,
        },
        {
          logo: 'TRON',
          Price: 1322489.34,
          Changes: -47,
        },
        {
          logo: 'TRON',
          Price: 1322489.34,
          Changes: -47,
        },
        {
          logo: 'ETH',
          Price: 1322489.34,
          Changes: 98,
        },

        {
          logo: 'LTC',
          Price: 1322489.34,
          Changes: 98,
        },
        {
          logo: 'TRON',
          Price: 1322489.34,
          Changes: -47,
        },
      ],

      Usdtdata: [

        {
          logo: 'LTC',
          Price: 1322489.34,
          Changes: 98,
        },
        {
          logo: 'TRON',
          Price: 1322489.34,
          Changes: -47,
        },
        {
          logo: 'BTC',
          Price: 1322489.34,
          Changes: 98,
        },
        {
          logo: 'BCH',
          Price: 1322489.34,
          Changes: -47,
        },
        {
          logo: 'BNB',
          Price: 1322489.34,
          Changes: 98,
        },
        {
          logo: 'DOGE',
          Price: 1322489.34,
          Changes: -47,
        },
        {
          logo: 'ETH',
          Price: 1322489.34,
          Changes: 98,
        },
        {
          logo: 'ETH',
          Price: 1322489.34,
          Changes: 98,
        },

        {
          logo: 'LTC',
          Price: 1322489.34,
          Changes: 98,
        },
        {
          logo: 'TRON',
          Price: 1322489.34,
          Changes: -47,
        },
      ]

    }
  },

  "mounted"() {
    this.width = screen.width;
    this.data = this.Tomandata
  },

}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  gap: 30px;
  font-weight: bold;
  width: 100%;
  border-bottom: 1px solid var(--border-light);
  padding: 0 10px;
  position: relative;

  span {
    cursor: pointer;
    color: var(--font-alternate);
    font-size: clamp(12px, 2vw , 14px);
    padding: 10px;
  }


  .selectedi {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 70px;
      background-color: var(--yellow);
      height: 3px;
      top: 95%;
      right: -10px;
      border-radius: 5px 5px 0 0;
    }
  }

  .selectedi2 {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      background-color: var(--yellow);
      height: 3px;
      top: 95%;
      right: 0;
      border-radius: 5px 5px 0 0;
    }
  }
}

table {
  width: 100%;
  border-spacing: 0.5em;
  table-layout: auto;

  th {
    color: var(--gray);
    font-size: clamp(12px, 2vw , 14px);
  }

  td {

    .space {
      display: flex;
      flex-direction: row;
      gap: 5px;
      justify-content: space-between !important;
      width: max-content;
      span{
        color: var(--font-alternate);
        font-size: clamp(10px, 2vw , 12px);
      }
    }

    div {
      display: flex;
      flex-direction: row;
      gap: 2px;
      justify-content: center;

      span {
        font-size: clamp(10px, 2vw , 12px);
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

</style>