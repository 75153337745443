<template>
  <div class="history-table">
    <div class="header">
      <span :class="{'selected' : Order === 'Buy'}"
            @click="Order = 'Buy'">{{ state.lng.history.BuyOrder }}
      </span>
      <span :class="{'selected2' : Order === 'Sell'}"
            @click="Order = 'Sell'">{{ state.lng.history.SellOrder }}
      </span>
      <span :class="{'selected2' : Order === 'Login'}"
            @click="Order = 'Login'">{{ state.lng.history.Login }}
      </span>
    </div>
    <div class="table">
      <table v-if="Order === 'Buy'" class="Table">
        <tr>
          <th>{{ state.lng.history.BuyDate }}</th>
          <th v-if="width > 800">{{ state.lng.history.Pair }}</th>
          <th v-if="width > 800">{{ state.lng.history.BuyTransactionID }}</th>
          <th v-if="width > 800">{{ state.lng.history.BuyAmount }}</th>
          <th v-if="width > 800">{{ state.lng.history.Receive }}</th>
          <th>{{ state.lng.history.BuyPrice }}</th>
          <th>{{ state.lng.history.BuyTotal }}</th>
          <th v-if="width > 800">{{ state.lng.history.BuyFee }}</th>
          <th>{{ state.lng.history.BuyStatus }}</th>
        </tr>
        <tr v-for="(item , index) in BuyOrder" :key="index">
          <td>
            <div>
              <span>{{ state.lng.history[item.buyDate] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.history[item.pair] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.history[item.buyTransactionID] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.history[item.buyAmount] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.history[item.receive] }}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{{ state.lng.history[item.buyPrice] }}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{{ state.lng.history[item.buyTotal] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.history[item.buyFee] }}</span>
            </div>
          </td>
          <td>
            <div>
              <span :class="{'red-span' : item.doneStatus}" v-if="item.doneStatus">{{
                  state.lng.history[item.doneStatus]
                }}</span>
              <span :class="{'green-span' : item.canceledStatus}"
                    v-if="item.canceledStatus">{{ state.lng.history[item.canceledStatus] }}</span>
            </div>
          </td>
        </tr>
      </table>
      <table v-if="Order === 'Sell'" class="Table">
        <tr>
          <th>{{ state.lng.history.BuyDate }}</th>
          <th v-if="width > 800">{{ state.lng.history.Pair }}</th>
          <th v-if="width > 800">{{ state.lng.history.BuyTransactionID }}</th>
          <th v-if="width > 800">{{ state.lng.history.BuyAmount }}</th>
          <th v-if="width > 800">{{ state.lng.history.Receive }}</th>
          <th>{{ state.lng.history.BuyPrice }}</th>
          <th>{{ state.lng.history.BuyTotal }}</th>
          <th v-if="width > 800">{{ state.lng.history.BuyFee }}</th>
          <th>{{ state.lng.history.BuyStatus }}</th>
        </tr>
        <tr v-for="(item , index) in BuyOrder" :key="index">
            <td>
            <div>
              <span>{{ state.lng.history[item.buyDate] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.history[item.pair] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.history[item.buyTransactionID] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.history[item.buyAmount] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.history[item.receive] }}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{{ state.lng.history[item.buyPrice] }}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{{ state.lng.history[item.buyTotal] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.history[item.buyFee] }}</span>
            </div>
          </td>
          <td>
            <div>
              <span :class="{'red-span' : item.doneStatus}" v-if="item.doneStatus">{{
                  state.lng.history[item.doneStatus]
                }}</span>
              <span :class="{'green-span' : item.canceledStatus}"
                    v-if="item.canceledStatus">{{ state.lng.history[item.canceledStatus] }}</span>
            </div>
          </td>
        </tr>
      </table>
      <table v-if="Order === 'Login'" class="Table">
        <tr>
          <th>{{ state.lng.history.LoginDate }}</th>
          <th v-if="width > 800">{{ state.lng.history.Device }}</th>
          <th v-if="width > 800">{{ state.lng.history.Platform }}</th>
          <th v-if="width > 800">{{ state.lng.history.Browser }}</th>
          <th>{{ state.lng.history.IP }}</th>
          <th>{{ state.lng.history.LoginStatus }}</th>
        </tr>
        <tr v-for="(item , index) in LoginData" :key="index">
          <td>
            <div>
              <span>{{ state.lng.history[item.loginDate] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.history[item.device] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.history[item.platform] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.history[item.browser] }}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{{ state.lng.history[item.iP] }}</span>
            </div>
          </td>
          <td>
            <div>
              <span :class="{'green-span' : item.successStatus}"
                    v-if="item.successStatus">{{ state.lng.history[item.successStatus] }}</span>
              <span :class="{'red-span' : item.unsuccessfulStatus}"
                    v-if="item.unsuccessfulStatus">{{ state.lng.history[item.unsuccessfulStatus] }}</span>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheHistory",
  data() {
    return {
      Order: 'Buy',
      width:0,
      windowHeight: screen.width,
      BuyOrder: [
        {
          buyDate: "buyDate",
          pair: "pair",
          buyTransactionID: "buyTransactionID",
          buyAmount: "buyAmount",
          receive: "receive",
          buyPrice: "buyPrice",
          buyTotal: "buyTotal",
          buyFee: "buyFee",
          doneStatus: "doneStatus"
        },
        {
          buyDate: "buyDate",
          pair: "pair",
          buyTransactionID: "buyTransactionID",
          buyAmount: "buyAmount",
          receive: "receive",
          buyPrice: "buyPrice",
          buyTotal: "buyTotal",
          buyFee: "buyFee",
          canceledStatus: "canceledStatus"
        },
        {
          buyDate: "buyDate",
          pair: "pair",
          buyTransactionID: "buyTransactionID",
          buyAmount: "buyAmount",
          receive: "receive",
          buyPrice: "buyPrice",
          buyTotal: "buyTotal",
          buyFee: "buyFee",
          doneStatus: "doneStatus"
        },
        {
          buyDate: "buyDate",
          pair: "pair",
          buyTransactionID: "buyTransactionID",
          buyAmount: "buyAmount",
          receive: "receive",
          buyPrice: "buyPrice",
          buyTotal: "buyTotal",
          buyFee: "buyFee",
          canceledStatus: "canceledStatus"
        },
        {
          buyDate: "buyDate",
          pair: "pair",
          buyTransactionID: "buyTransactionID",
          buyAmount: "buyAmount",
          receive: "receive",
          buyPrice: "buyPrice",
          buyTotal: "buyTotal",
          buyFee: "buyFee",
          doneStatus: "doneStatus"
        },
        {
          buyDate: "buyDate",
          pair: "pair",
          buyTransactionID: "buyTransactionID",
          buyAmount: "buyAmount",
          receive: "receive",
          buyPrice: "buyPrice",
          buyTotal: "buyTotal",
          buyFee: "buyFee",
          canceledStatus: "canceledStatus"
        },
        {
          buyDate: "buyDate",
          pair: "pair",
          buyTransactionID: "buyTransactionID",
          buyAmount: "buyAmount",
          receive: "receive",
          buyPrice: "buyPrice",
          buyTotal: "buyTotal",
          buyFee: "buyFee",
          doneStatus: "doneStatus"
        },
        {
          buyDate: "buyDate",
          pair: "pair",
          buyTransactionID: "buyTransactionID",
          buyAmount: "buyAmount",
          receive: "receive",
          buyPrice: "buyPrice",
          buyTotal: "buyTotal",
          buyFee: "buyFee",
          canceledStatus: "canceledStatus"
        },
        {
          buyDate: "buyDate",
          pair: "pair",
          buyTransactionID: "buyTransactionID",
          buyAmount: "buyAmount",
          receive: "receive",
          buyPrice: "buyPrice",
          buyTotal: "buyTotal",
          buyFee: "buyFee",
          doneStatus: "doneStatus"
        },
        {
          buyDate: "buyDate",
          pair: "pair",
          buyTransactionID: "buyTransactionID",
          buyAmount: "buyAmount",
          receive: "receive",
          buyPrice: "buyPrice",
          buyTotal: "buyTotal",
          buyFee: "buyFee",
          canceledStatus: "canceledStatus"
        },
      ],
      LoginData: [
        {
          loginDate: "loginDate",
          device: "device",
          platform: "platform",
          browser: "browser",
          iP: "iP",
          successStatus: "successStatus",
        },
        {
          loginDate: "loginDate",
          device: "device",
          platform: "platform",
          browser: "browser",
          iP: "iP",
          unsuccessfulStatus: "unsuccessfulStatus"
        },
        {
          loginDate: "loginDate",
          device: "device",
          platform: "platform",
          browser: "browser",
          iP: "iP",
          successStatus: "successStatus",

        },
        {
          loginDate: "loginDate",
          device: "device",
          platform: "platform",
          browser: "browser",
          iP: "iP",
          unsuccessfulStatus: "unsuccessfulStatus"
        },
        {
          loginDate: "loginDate",
          device: "device",
          platform: "platform",
          browser: "browser",
          iP: "iP",
          successStatus: "successStatus",

        },
        {
          loginDate: "loginDate",
          device: "device",
          platform: "platform",
          browser: "browser",
          iP: "iP",
          unsuccessfulStatus: "unsuccessfulStatus"
        },
        {
          loginDate: "loginDate",
          device: "device",
          platform: "platform",
          browser: "browser",
          iP: "iP",
          successStatus: "successStatus",

        },
        {
          loginDate: "loginDate",
          device: "device",
          platform: "platform",
          browser: "browser",
          iP: "iP",
          unsuccessfulStatus: "unsuccessfulStatus"
        }
      ]
    }
  },

  watch: {
    windowHeight() {
      this.width = this.windowHeight;
      return this.width
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.width = screen.width;
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowHeight = screen.width;
    }
  }
}
</script>

<style lang="scss" scoped>
.history-table {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--background-global);
  width: 95%;
  margin: 30px auto;
  padding: 20px;
  border-radius: 5px;
  font-size: clamp(12px , 2vw , 16px);

  .header {
    display: flex;
    gap: 30px;
    font-weight: bold;
    width: 100%;
    border-bottom: 1px solid var(--border-light);
   margin:0 10px;
    position: relative;

    span {
      cursor: pointer;
      color: var(--font-alternate);
      font-size: clamp(12px , 2vw , 16px);
      padding: 10px 0;
    }

    .selected {
      font-size: clamp(12px , 2vw , 16px);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 140%;
        background-color: var(--yellow);
        height: 3px;
        top: 95%;
        right:-30px;
        border-radius: 5px 5px 0 0;
      }
    }

    .selected2 {
      font-size: clamp(12px , 2vw , 16px);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        background-color: var(--yellow);
        height: 3px;
        top: 95%;
        right:0;
        border-radius: 5px 5px 0 0;
      }
    }
  }

  .Table {
    width: 100%;

    th {
      color: var(--gray);
    }

    td{
      color: var(--font-alternate);
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0 10px 0;
    }

    .red-span {
      color: #FF0000;
    }

    .green-span {
      color: #00A35F;
    }
  }
}

//Media------------
//@media (max-width: 800px) {
//  .history-table{
//    width: 100%;
//    font-size: 12px;
//
//    .selected {
//      font-size: 12px !important;
//
//      &::before {
//        content: "";
//        position: absolute;
//        width: 100%;
//        background-color: var(--yellow);
//        height: 3px;
//        bottom: 0;
//        top: 26px !important;
//        right: 0;
//        border-radius: 20px;
//      }
//    }
//  }
//}

</style>