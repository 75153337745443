<template>
      <div  @click="show =!show" class="question-box">
        <div class="row-1">
          <span class="question">{{ state.lng.About[data.question] }}</span>
          <img v-if="state.theme === 'light' " src="../../assets/public/black-arrow.png" alt="">
          <img v-if="state.theme === 'dark' "  src="../../assets/public/white-bottom-arrow.png"
               alt="">
        </div>
        <transition name="popup">
        <div v-if="show" class="row-2">
          <p class="text">
            {{ state.lng.About[data.answer] }}
          </p>
        </div>
        </transition>
      </div>
</template>

<script>
export default {
  name: "TheQuestionBox",
  props: ["data"],
  data() {
    return {
      show: false
    }
  }

}
</script>

<style lang="scss" scoped>

.question-box {
  display: flex;
  flex-direction: column;
  background-color: var(--background-global);
  color: var(--second-font-color);
  padding: 20px;
  height: min-content;
  cursor: pointer;

  .row-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 10px;
      height: 5px;
      cursor: pointer;
    }
  }

  .row-2 {
    .text {
      font-size: small;
    }
  }
}

.question{
  font-size: clamp(14px, 2vw , 17px);
}
</style>