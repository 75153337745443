<template>
  <div class="main-modal" @click.self="$emit('close')">
    <div class="modal">
      <div class="header">
        <inline-svg class="close"
                    :src="require('../../../assets/public/close.svg')"
                    @click="$emit('close')"
                    :class="{'close-dark' : state.theme==='dark'}"
        />
        <span class="title">{{ state.lng.setting.AuthenticationTitle }}</span>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  props: ['value'],
  name: "TheModal",
  emit: ['close'],
  data() {
    return {}
  }
}
</script>

<style scoped>
.main-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  position: fixed;
  inset: 0;
  background: #252424;
}

.modal {
  background-color: var(--background-global);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: 14px 30px;
  height: auto;
  position: relative;
  gap: 20px;
}

.header {
  display: flex;
  align-items: center;
}

.close {
  position: absolute;
  left: 10%;
  top: 7%;
  cursor: pointer;
}

.title {
  border-bottom: 1px solid var(--border-light);
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
  color: var(--font-alternate);
}

.close-dark {
  fill: #FFFFFF;
}

@media (max-width: 450px) {
  .modal {
    max-width: 300px !important;
  }
}
</style>