<template>
  <div class="main">

    <div :class="{'second':state.Token.name !== ''}" class="mobile-header">
      <div @click="submit" class="logo-nav-mobile">
        <img v-if="state.lang === 'fa' " src="../../../assets/public/fa-logo.png" alt="logo">
        <img v-else src="../../../assets/public/en-logo.png" alt="logo">
      </div>

      <div class="menu-mobile-nav">
        <the-notfications @close="alarm = false" v-if="alarm === true"/>
        <the-userpanel v-if="account"/>
        <a v-if="state.Token.name === ''" @click="login">{{state.lng.buttons.login}}</a>
        <a v-if="state.Token.name === ''" @click="signup">{{state.lng.buttons.signup}}</a>
        <img  @click="alarm = true" src="../../../assets/navbar/alarm.png" class="alarm" alt="">
        <img  v-if="state.Token.name !== ''"  @click="account = !account" src="../../../assets/navbar/Usericon.png" alt="">
        <span v-if="state.Token.name !== ''" @click="account = !account" class="user-name-class">{{state.lng.nav.name }}</span>
        <img @click="show = true" class="hamburger-btn" src="../../../assets/navbar/Hamburger.png" alt="">
      </div>
    </div>

    <transition name="slide">

      <div v-if="show" class="list">
        <img @click="show = false" class="close-window" src="../../../assets/navbar/close-window-icon.png" alt="">
        <ul>
          <li>
            <img v-if="state.theme === 'dark'"  @click="state.theme = 'light'" src="../../../assets/navbar/dark-icon.png" alt="">
            <img v-if="state.theme === 'light'"  @click="state.theme = 'dark'" src="../../../assets/navbar/light-icon.png" alt="">
          </li>
          <li>
            <a @click="changeLang('en')" href="#" v-if="state.lang === 'fa'">EN</a>
            <a @click="changeLang('fa')" href="#" v-if="state.lang === 'en'">FA</a>
          </li>
          <li v-for="(item ,index) in navItems.filter(e => e.name !== 'Help' || state.Token.name)" :key="index">
            <a class="link" @click="$router.push({name : item.name})">{{ state.lng.nav[item.name] }}</a>
          </li>
        </ul>

      </div>
    </transition>

  </div>
</template>

<script>
import en from "../../../lib/multiLang/en-lang/en";
import fa from "../../../lib/multiLang/fa-lang/fa";
import TheNotfications from "../../Notfications/notfications";
import TheUserpanel from "../../Panel/UserPanel/the-userpanel";

export default {
  name: "TheMobileNavbar",
  components: {TheUserpanel, TheNotfications},
  data() {
    return {
      alarm:false,
      account:false,
      navItems: [
        {
          name: "EasyTrade",
          route: "/EasyTrade"
        },
        {
          name: "Trade",
          route: "/Trade"
        },
        {
          name: 'TheMarket',
          route: "/TheMarket"
        },
        {
          name: 'TheRules',
          route: "/TheRules"
        },
        {
          name: 'Blog',
          route: "/Blog"
        },
        {
          name: 'TheGuide',
          route: "/Help"
        },
        {
          name: 'Login',
          route: "Authentication/login"
        },
        {
          name: 'Signup',
          route: "Authentication/Signup"
        },


      ],
      show: false
    }
  },

  methods: {
    changeLang(e) {
      localStorage.lang = e;
      this.state.lng = e === 'en' ? en : fa;
      this.state.lang = e;
      this.$root.$children[0].$children.forEach(e => e.$forceUpdate())
    },

    submit(){
      this.$router.push({name : 'Home'})
    },

    login(){
      this.$router.push({name : 'Login'})
    },

    signup(){
      this.$router.push({name : 'Signup'})
    }
  },

  created() {
    window.addEventListener("click" , (event)=>{
      if(!event.target.closest('.menu-mobile-nav')){
        this.show = false
      }
   })
  }

}
</script>

<style lang="scss" scoped>
//------------------variables
$font-size: mediume;

//--------------------Animation style

.slide-enter-active {
  animation: slide-in 100ms ease-in forwards;

}

.slide-leave-active {
  animation: slide-out 100ms ease-in forwards;
}

.user-name-class{
  font-size: 14px;
}

@keyframes slide-in {
  from {
    width:0;
    transform: translate(100px ,  0);
  }
  to {
   width: 50vw;
    transform: translate(0 , 0);
  }
}

@keyframes slide-out {
  from {
    width: 50vw;
    transform: translate(0 , 0);
  }
  to {
    width:0;
    transform: translate(0, 0);
  }
}


//--------------general styles
.main {
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;

  .second{
    background-color: var(--second-bg) !important;
  }

  .mobile-header {
    display: flex;
    flex-direction: row;
    background-color: var(--black);
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;

    .logo-nav-mobile {
      flex-basis: 100px;
    }

    .menu-mobile-nav {
      flex-grow: 1;
      flex-basis: 100px;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      align-items: center;
      position: relative;

      a{
        color: var(--white);
        text-decoration: none;
        font-size: small;
      }

      .hamburger-btn {
        width: 40px;
        cursor: pointer;
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    background-color: var(--black);
    opacity: 0.8;
    padding: 10px;
    position: absolute;
    z-index: 1;
    height: 100vh;
    width: 50vw;

    .close-window {
      width: 15%;
      cursor: pointer;
    }

    ul {
      gap: 25px;
      padding: 0;

      li {
        list-style: none;
        font-size: $font-size;
        cursor: pointer;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          color: var(--white);
          text-decoration: none;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .alarm{
    display: none;
  }
}
</style>
