<template>
  <div class="main">
    <div class="header">
      <div>
        {{ state.lng.kyc.BankTitle }}
      </div>
      <div class="add">
        <img class="addcart" @click="component++" src="../../../assets/public/Add.png" alt="">
        {{ state.lng.kyc.Addcard }}
      </div>
    </div>
    <div class="body">
      <div class="alert">
        <img src="../../../assets/KYC/exclamation.png" alt="">
        <p class="alert-text">{{ state.lng.kyc.alertText }}</p>
      </div>
      <the-show-cards @delet="deletCart()" v-for="n in component" :key="n"/>
    </div>
  </div>
</template>

<script>

import TheShowCards from "../../tools/TheShowCards";

export default {
  name: "BankInfo",
  components: {TheShowCards},
  data() {
    return {
      component: 1
    }
  },

  methods: {
    deletCart(){
      if (this.component > 1 ){
        this.component--
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  .header {
    border-bottom: 1px solid var(--border-input);
    padding-bottom: 10px;
    color: var(--font-alternate);
    display: flex;
    justify-content: space-between;

    .add {
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: flex-end;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 20PX;
    flex-wrap: wrap;

    .alert {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      img {
        width: 15px;
        height: 15px;
      }

      .alert-text {
        color: var(--yellow);
        font-size: small;
      }
    }
  }
}
</style>