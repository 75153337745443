<template>
  <div class="change-coins">
    <div class="row-1">
      <div class="component-change-coin">
        <select  v-model="ShowName" class="select-coin">
          <option :value="item.name" v-for="(item , index) in coins" :key="index">{{ item.name }}</option>
        </select>
        <input class="input-coin" type="text">
      </div>
      <div class="change-btn">
      <a href=""><img src="../../../assets/Home/change-btn.png" alt=""></a>
      </div>
      <div class="component-change-coin">
        <select v-model="ShowPrice" class="select-coin">
          <option style="font-family: VistaDev,serif" :value="item.name"   v-for="(item , index) in price" :key="index">{{ state.lng.price[item.name] }}</option>
        </select>
        <input v-model="changePrice" class="input-coin" type="text">
      </div>
    </div>

    <div class="row-2">
      <span class="text"> {{ state.lng.showChange.text1 }} {{ state.lng.coinsChange[ShowName] }} :</span>
      <span class="number"> {{ changePrice }} {{ state.lng.price[ShowPrice] }}</span>
    </div>


  </div>
</template>

<script>
export default {
  name: "TheChangCoins",
  data() {
    return {
      ShowPrice: 'USDT1',
      changePrice: 0,
      ShowName: 'BTC',

      coins: [
        {name: "BTC", price: "price1"},
        {name: "BCH", price: "price2"},
        {name: "BSV", price: "price3"},
        {name: "LTC", price: "price4"},
      ],

      price: [
        {name: "USDT1", price: "price1"},
        {name: "USDT2", price: "price2"},
        {name: "USDT3", price: "price3"},
        {name: "USDT4", price: "price4"},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
//-------------------media



.change-coins {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin: 30px 0;


  .row-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50%;
    gap: 20px;
    flex-wrap: wrap;
    direction: rtl !important;

    .component-change-coin {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      flex-basis: 100px;
      justify-content: center;

      .select-coin {
        background-color: var(--yellow);
        border-radius: 0 10px 10px 0;
        box-shadow: 0 0 5px 0 #00000033;
        border: 0;
        padding: 10px;
        width: 95px;
        -webkit-appearance: none !important;
        background-image: url("../../../assets/public/black-arrow.png");
        background-position: left 10px center;
        background-repeat: no-repeat;
        font-family: VistaDev,serif !important;
        cursor: pointer;
        
        option{
          -webkit-appearance: none !important;
          font-family: VistaDev,serif;
          font-style: inherit;
        }
      }
      

      .input-coin {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px 0 0 10px;
        border: 0;
        background-color: var(--text-box-global);
        color: var(--second-font-color);
        text-align: center;
        font-family: VistaDev,serif;
        -webkit-appearance: none;
      }
    }

    .change-btn{
      display: flex;
      flex-grow: 1;
      flex-basis: 100px;
      align-items: center;
      justify-content: center;
    }
  }

  .row-2 {
    display: flex;
    flex-direction: row;

    .text{
      color: var(--font-alternate);
    }

    .number {
      color: var(--gray);
    }
  }
}

@media screen and (max-width: 1200px) and (min-width: 700px) {
  .component-change-coin {
    flex-basis: 100% !important;
  }
}
</style>
