<template>
  <div class="main">
    <opt-modall v-if="WithdrawshowModal || DepositshowModal"
                :namemodal="namemodal"
                :opration="opration"
                @close="WithdrawshowModal  = false ; DepositshowModal = false"/>

    <div class="main-table">
      <span class="title">{{ state.lng.wallet.Title }}</span>
      <table>
        <tr>
          <th style="text-align: right">{{ state.lng.wallet.tableTitle1 }}</th>
          <th>{{ state.lng.wallet.tableTitle2 }}</th>
          <th v-if="width > 800">{{ state.lng.wallet.tableTitle3 }}</th>
          <th v-if="width > 800">{{ state.lng.wallet.tableTitle4 }}</th>
          <th>{{ state.lng.wallet.tableTitle5 }}</th>
        </tr>
        <tr v-for="(item , index) in data" :key="index">
          <td class="td-name">
            <div>
              <img v-if="width > 800" :src="require('../../../assets/LogoCoins/'+item.logo)" alt="">
              <span>{{ state.lng.wallet[item.name] }}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{{ state.lng.wallet[item.Price] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.wallet[item.Volume] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.wallet[item.value] }}</span>
            </div>
          </td>
          <td>
            <div class="btn-div">
              <button @click="Depositmodal(state.lng.wallet[item.name])" class="btn-red">{{
                  state.lng.wallet.redBtn
                }}
              </button>
              <button @click="Withdrawmodal(state.lng.wallet[item.name])" class="btn-green">{{
                  state.lng.wallet.greenBtn
                }}
              </button>
              <button v-if="item.logo !=='toman.png' " class="btn-yellow">{{ state.lng.wallet.yellowBtn }}</button>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="Operation">
      <div class="header">
        <span :class="{'selected' : select === 'Deposit'}"
              @click="select = 'Deposit'">{{ state.lng.wallet.greenBtn }}</span>
        <span :class="{'selected2' : select === 'Withdraw'}"
              @click="select = 'Withdraw'">{{ state.lng.wallet.redBtn }}</span>
      </div>
      <table v-if="select === 'Deposit'" class="Deposit-Table">
        <tr>
          <th class="rtl">{{ state.lng.wallet.Date }}</th>
          <th v-if="width > 800">{{ state.lng.wallet.Type }}</th>
          <th v-if="width > 800">{{ state.lng.wallet.TransactionID }}</th>
          <th v-if="width > 800">{{ state.lng.wallet.DepositID }}</th>
          <th>{{ state.lng.wallet.Amount }}</th>
          <th class="ltr">{{ state.lng.wallet.Status }}</th>
        </tr>
        <tr v-for="(item , index) in Deposit" :key="index">
          <td>
            <div class="rtl">
              <span>{{ state.lng.wallet[item.date] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.wallet[item.type] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.wallet[item.transactionID] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.wallet[item.depositID] }}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{{ state.lng.wallet[item.amount] }}</span>
            </div>
          </td>
          <td>
            <div class="ltr">
              <span :class="{'red-span' : item.statusFailed}"
                    v-if="item.statusFailed">{{ state.lng.wallet[item.statusFailed] }}</span>
              <span :class="{'yellow-span' : item.statusNotverified}"
                    v-if="item.statusNotverified">{{ state.lng.wallet[item.statusNotverified] }}</span>
              <span :class="{'green-span' : item.statusComplete}"
                    v-if="item.statusComplete">{{ state.lng.wallet[item.statusComplete] }}</span>
            </div>
          </td>
        </tr>
      </table>


      <table v-if="select === 'Withdraw'" class="Deposit-Table">
        <tr>
          <th class="rtl">{{ state.lng.wallet.Date }}</th>
          <th v-if="width > 800">{{ state.lng.wallet.Type }}</th>
          <th v-if="width > 800">{{ state.lng.wallet.TransactionID }}</th>
          <th v-if="width > 800">{{ state.lng.wallet.DepositID }}</th>
          <th>{{ state.lng.wallet.Amount }}</th>
          <th class="ltr">{{ state.lng.wallet.Status }}</th>
        </tr>
        <tr v-for="(item , index) in Withdraw" :key="index">
          <td>
            <div class="rtl">
              <span>{{ state.lng.wallet[item.date] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.wallet[item.type] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.wallet[item.transactionID] }}</span>
            </div>
          </td>
          <td v-if="width > 800">
            <div>
              <span>{{ state.lng.wallet[item.withdrawID] }}</span>
            </div>
          </td>
          <td>
            <div>
              <span>{{ state.lng.wallet[item.amount] }}</span>
            </div>
          </td>
          <td>
            <div class="ltr">
              <span :class="{'red-span' : item.statusFailed}"
                    v-if="item.statusFailed">{{ state.lng.wallet[item.statusFailed] }}</span>
              <span :class="{'yellow-span' : item.statusNotverified}"
                    v-if="item.statusNotverified">{{ state.lng.wallet[item.statusNotverified] }}</span>
              <span :class="{'green-span' : item.statusComplete}"
                    v-if="item.statusComplete">{{ state.lng.wallet[item.statusComplete] }}</span>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import OptModall from "./modal/optModall";

export default {
  name: "TheWallet",
  components: {OptModall},
  data() {
    return {
      width: 0,
      windowHeight: screen.width,
      DepositshowModal: false,
      WithdrawshowModal: false,
      namemodal: '',
      opration:'',
      select: 'Deposit',
      data:
          [
            {
              name: 'name1',
              Price: 'Price',
              Volume: 'Volume',
              logo: 'toman.png',
              value: 'value'
            },
            {
              name: 'name2',
              Price: 'Price',
              Volume: 'Volume',
              logo: 'btc.png',
              value: 'value'
            },
            {
              name: 'name3',
              Price: 'Price',
              Volume: 'Volume',
              logo: 'ada.png',
              value: 'value'
            },
            {
              name: 'name4',
              Price: 'Price',
              Volume: 'Volume',
              logo: 'bch.png',
              value: 'value'
            },
            {
              name: 'name5',
              Price: 'Price',
              Volume: 'Volume',
              logo: 'bnb.png',
              value: 'value'
            },
            {
              name: 'name6',
              Price: 'Price',
              Volume: 'Volume',
              logo: 'bsv.png',
              value: 'value'
            },
            {
              name: 'name7',
              Price: 'Price',
              Volume: 'Volume',
              logo: 'doge.png',
              value: 'value'
            },
            {
              name: 'name8',
              Price: 'Price',
              Volume: 'Volume',
              logo: 'eth.png',
              value: 'value'
            },
            {
              name: 'name9',
              Price: 'Price',
              Volume: 'Volume',
              logo: 'ltc.png',
              value: 'value'
            },
            {
              name: 'name10',
              Price: 'Price',
              Volume: 'Volume',
              logo: 'usdt.png',
              value: 'value'
            },
            {
              name: 'name11',
              Price: 'Price',
              Volume: 'Volume',
              logo: 'xlm.png',
              value: 'value'
            },
            {
              name: 'name12',
              Price: 'Price',
              Volume: 'Volume',
              logo: 'xrp.png',
              value: 'value'
            },

          ],
      Withdraw: [
        {
          date: "date",
          type: "type",
          transactionID: "transactionID",
          withdrawID: "withdrawID",
          amount: "amount",
          statusComplete: "statusComplete",
        },
        {
          date: "date",
          type: "type",
          transactionID: "transactionID",
          withdrawID: "withdrawID",
          amount: "amount",
          statusNotverified: "statusNotverified",
        },
        {
          date: "date",
          type: "type",
          transactionID: "transactionID",
          withdrawID: "withdrawID",
          amount: "amount",
          statusFailed: "statusFailed"
        },
      ],
      Deposit: [
        {
          date: "date",
          type: "type",
          transactionID: "transactionID",
          depositID: "depositID",
          amount: "amount",
          statusFailed: "statusFailed"
        },
        {
          date: "date",
          type: "type",
          transactionID: "transactionID",
          depositID: "depositID",
          amount: "amount",
          statusComplete: "statusComplete",
        },
        {
          date: "date",
          type: "type",
          transactionID: "transactionID",
          depositID: "depositID",
          amount: "amount",
          statusNotverified: "statusNotverified",
        }
      ]
    }
  },

  watch: {
    windowHeight() {
      this.width = this.windowHeight;
      return this.width
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.width = screen.width;
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },



  methods: {
    Depositmodal(e) {
      console.log(e);
      this.DepositshowModal = true;
      this.namemodal = e;
      this.opration = 'Deposit';
    },

    onResize() {
      this.windowHeight = screen.width;
    },

    Withdrawmodal(e) {
      console.log(e);
      this.WithdrawshowModal = true;
      this.namemodal = e;
      this.opration = 'Withdraw';
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .rtl{
    text-align: right;
    justify-content: right !important;
  }

  .ltr{
    text-align: left;
    justify-content: left !important;
  }

  .main-table {
    background-color: var(--background-global);
    width: 95%;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 5px;

    .td-name{
      width: 20%;

      div{
        display: flex;
        justify-content: flex-start !important;
      }
    }

    .buttons {
      width: 35%;
      height: auto;
      background-color: transparent;
      font-size: small;
      border-radius: 5px;
      color: var(--second-font-color);
      font-family: VistaDev;
      padding: 5px 0;
      cursor: pointer;
    }

    .title {
      border-bottom: 1px solid var(--border-light);
      width: 100%;
      padding: 10px;
      color: var(--font-alternate);
      font-size: large;
    }

    table {
      width: 100%;
      padding: 35px;
      table-layout: auto;

      th {
        color: var(--gray);
        font-size: clamp(12px , 2vw , 16px);
      }

      td {
         color: var(--font-alternate);

        .btn-div {
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 20px 30px 10px 0;
        }

        div {
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: center;
          align-items: center;
          margin: 20px 0 10px 0;

          img {
            width: auto;
          }
        }

        .btn-red {
          @extend .buttons;
          border: 1px solid #FE2A2A;
        }

        .btn-green {
          @extend .buttons;
          border: 1px solid #00A35F;
        }

        .btn-yellow {
          @extend .buttons;
          border: 1px solid #FEB700;
        }

      }
    }
  }

  .Operation {
    display: flex;
    flex-direction: column;
    background-color: var(--background-global);
    margin: 0 auto;
    width: 95%;
    padding: 20px;
    gap: 20px;

    .header {
      display: flex;
      gap: 30px;
      font-weight: bold;
      width: 100%;
      border-bottom: 1px solid var(--border-light);
      margin:0 10px ;
      position: relative;

      span {
        cursor: pointer;
        color: var(--font-alternate);
        padding: 10px 0;
        font-size: clamp(13px , 2vw , 17px);
      }


      .selected {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 70px;
          background-color: var(--yellow);
          height: 3px;
          top: 95%;
          right: -20px;
          border-radius: 5px 5px 0 0;
        }
      }

      .selected2 {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          background-color: var(--yellow);
          height: 3px;
          top: 95%;
          right: 0;
          border-radius: 5px 5px 0 0;
        }
      }
    }

    .Deposit-Table {
      width: 100%;

      th {
        color: var(--gray);
      }

      td{
        color: var(--font-alternate);
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0 10px 0;
      }

      .red-span {
        color: #FF0000;
      }

      .yellow-span {
        color: #FEB700;
      }

      .green-span {
        color: #00A35F;
      }
    }
  }
}

//Media------------
@media (max-width: 800px) {
  .main-table {
    font-size: 10px;
    width: 100%;

    .title {
      font-size: small;
      font-weight: bold;
    }

    .buttons {
      width: 100%;
      padding: 2px;
    }

    .btn-div {
      margin: 0 !important;
      flex-direction: column !important;
    }

    table {
      td {
        border-bottom: 1px solid var(--border-light);
        padding: 20px 0;
      }
    }
  }

  .Deposit-Table {
    font-size: 10px;
    width: 100%;
  }

  .header {
    span {
      font-size: 12px !important;
    }
  }

}
</style>