<template>
  <div class="header-aut">
    <span class="title">{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: "HeaderAut",
  props:["value"]
}
</script>

<style lang="scss" scoped>
.header-aut {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid lightgray;
  font-size: medium;
}
.title{
  color: var(--font-light);
}
</style>