<template>
  <div class="main-dashboard">
    <div class="box">
      <span class="title"> {{ state.lng.dashboard.title }}:</span>
      <div class="chart">
        <div class="right-box">
          <div class="toman">
            <p class="light-span">
              {{ state.lng.dashboard.TomanBalance }}:
            </p>
            <p>{{ toman }}</p>
          </div>

          <div class="dollar">
            <p class="light-span">
              {{ state.lng.dashboard.DollarBalance }}:
            </p>
            <p>{{ dollar }}$</p>

          </div>

        </div>

        <div class="border"></div>

        <div class="left-box">
          <the-chart/>
        </div>
      </div>
    </div>

    <div class="parent-box">
      <div class="child-box1">
        <span class="title"> {{ state.lng.dashboard.UserLevels }}:</span>

        <div class="leveles">
          <span class="little-span">{{ state.lng.dashboard.valuetext }}</span>
          <span class="little-span">{{ state.lng.dashboard.valuenumber }}</span>
        </div>

        <div v-for="(i , index) in item" :key="index" class="bar">
          <div :class="{'active-cricle' : i.active}" class="circle">
            <img src="../../../assets/Dashbord/star.png" alt="">
          </div>
          <div v-if="state.lang === 'fa' "
               :style="{backgroundImage:`linear-gradient(-90deg  , #D7DF36 ${i.percent}%, transparent ${i.percent}%  )`}"
               class="progresive">
            <span>{{ state.lng.dashboard[i.level] }}</span>
            <span>{{ i.percent }}</span>
          </div>
          <div v-if="state.lang === 'en' "
               :style="{backgroundImage:`linear-gradient(90deg  , #D7DF36 ${i.percent}%, transparent ${i.percent}%  )`}"
               class="progresive">
            <span>{{ state.lng.dashboard[i.level] }}</span>
            <span>{{ i.percent }}</span>
          </div>
        </div>

      </div>
      <div class="child-box2">
        <table>
          <tr>
            <th>{{ state.lng.dashboard.waletName }}</th>
            <th>{{ state.lng.dashboard.Balance }}</th>
            <th>{{ state.lng.dashboard.Price }}</th>
            <th v-if="width > 820">{{ state.lng.dashboard.Value }}</th>
          </tr>
          <tr v-for="(item , index) in data" :key="index">
            <td>
              <div>
                <img v-if="width > 800" :src="require('../../../assets/LogoCoins/'+item.logo)" alt="">
                <span>{{ state.lng.dashboard[item.name] }}</span>
              </div>
            </td>

            <td>
              <div>
                <span>{{ item.price }}</span>
              </div>
            </td>

            <td>
              <div>
                <span>{{ item.balance }}</span>
              </div>
            </td>

            <td>
              <div v-if="width > 820">
                <span>{{ item.value }}</span>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="box opration">
      <div class="header">
        <span :class="{'selected' : select === 'Deposit'}"
              @click="select = 'Deposit'">{{ state.lng.dashboard.Deposit2 }}</span>
        <span :class="{'selected2' : select === 'Withdraw'}"
              @click="select = 'Withdraw'">{{ state.lng.dashboard.Withdraw2 }}</span>
      </div>

      <table class="DepositTable" v-if="select === 'Deposit'">
        <tr>
          <th>{{ state.lng.dashboard.Date }}</th>
          <th>{{ state.lng.dashboard.Pair }}</th>
          <th v-if="width > 820">{{ state.lng.dashboard.TypeSide }}</th>
          <th v-if="width > 820">{{ state.lng.dashboard.Pricet }}</th>
          <th>{{ state.lng.dashboard.Amount }}</th>
          <th v-if="width > 820">{{ state.lng.dashboard.TotalPrice }}</th>
          <th v-if="width > 820">{{ state.lng.dashboard.status }}</th>
          <th v-if="width > 820">{{ state.lng.dashboard.Operation }}</th>
        </tr>
        <tr v-for="(item , index) in DepositTable" :key="index">
          <td>{{ item.date }}</td>
          <td>{{ state.lng.dashboard[item.pair] }}</td>
          <td :class="{'red' :item.statuse === 'red' , 'green':item.statuse === 'green' }" v-if="width > 820">{{ state.lng.dashboard[item.Type] }}</td>
          <td v-if="width > 820"> {{ item.price }} <span class="lit-span">BTC</span></td>
          <td>{{ item.amount }}</td>
          <td v-if="width > 820">{{ item.totalprice }}</td>
          <td v-if="width > 820">
            <img v-if="state.lang === 'fa'" :src="require('../../../assets/Dashbord/red-fa-status.png')" alt="">
            <img v-if="state.lang === 'en'" :src="require('../../../assets/Dashbord/red-en- status.png')" alt="">
          </td>
          <td v-if="width > 820">{{ state.lng.dashboard[item.opration] }}</td>
        </tr>
      </table>

      <table class="WithdrawTable" v-if="select === 'Withdraw'">
        <tr>
          <th>{{ state.lng.dashboard.Date }}</th>
          <th>{{ state.lng.dashboard.Pair }}</th>
          <th v-if="width > 820">{{ state.lng.dashboard.TypeSide }}</th>
          <th v-if="width > 820">{{ state.lng.dashboard.TransactionId }}</th>
          <th>{{ state.lng.dashboard.Amount }}</th>
          <th v-if="width > 820">{{ state.lng.dashboard.Recevied }}</th>
          <th v-if="width > 820">{{ state.lng.dashboard.Pricet }}</th>
          <th v-if="width > 820">{{ state.lng.dashboard.TotalPrice }}</th>
          <th v-if="width > 820">{{ state.lng.dashboard.Fee }}</th>
          <th v-if="width > 820">{{ state.lng.dashboard.status }}</th>
        </tr>
        <tr v-for="(item , index) in WithdrawTable" :key="index">
          <td>{{ item.date }}</td>
          <td>{{ state.lng.dashboard[item.pair] }}</td>
          <td v-if="width > 820">{{ state.lng.dashboard[item.Type] }}</td>
          <td v-if="width > 820">{{ item.transactionid }}</td>
          <td>{{ item.amount }}</td>
          <td v-if="width > 820">{{ item.resived }}</td>
          <td v-if="width > 820">{{ item.price }}</td>
          <td v-if="width > 820">{{ item.totalprice }}</td>
          <td v-if="width > 820">{{ item.fee }}</td>
          <td v-if="width > 820">{{ state.lng.dashboard[item.statuse] }}</td>
        </tr>
      </table>

    </div>
  </div>
</template>

<script>
import TheChart from "./the-chart";
export default {
  name: "TheDashboard",
  components: {TheChart},
  data() {
    return {
      toman: 123564324,
      dollar: 1345.05,
      width: 0,
      windowHeight: screen.width,
      select: 'Deposit',

      WithdrawTable: [
        {
          date: "15-06  13:04",
          pair: "type",
          Type: "typesell",
          transactionid: 1452545,
          amount: 1856458785,
          resived: 215425,
          price: 1856458785,
          totalprice: 1856458785,
          fee: 0.25,
          statuse: "stusdone"
        },

        {
          date: "15-06  13:04",
          pair: "type",
          Type: "typesell",
          transactionid: 1452545,
          amount: 1856458785,
          resived: 215425,
          price: 1856458785,
          totalprice: 1856458785,
          fee: 0.25,
          statuse: "stusdone"
        },

        {
          date: "15-06  13:04",
          pair: "type",
          Type: "typesell",
          transactionid: 1452545,
          amount: 1856458785,
          resived: 215425,
          price: 1856458785,
          totalprice: 1856458785,
          fee: 0.25,
          statuse: "stusdone"
        },

        {
          date: "15-06  13:04",
          pair: "type",
          Type: "typesell",
          transactionid: 1452545,
          amount: 1856458785,
          resived: 215425,
          price: 1856458785,
          totalprice: 1856458785,
          fee: 0.25,
          statuse: "stusdone"
        },
      ],

      DepositTable: [
        {
          date: "15-06  13:04",
          pair: "type",
          Type: "typesell",
          price: 1856458785,
          amount: 1856458785,
          totalprice: 1856458785,
          statuse: "red",
          opration: "optok",
        },
        {
          date: "15-06  13:04",
          pair: "type",
          Type: "typesell",
          price: 1856458785,
          amount: 1856458785,
          totalprice: 1856458785,
          statuse: "red",
          opration: "optok",
        },
        {
          date: "15-06  13:04",
          pair: "type",
          Type: "typebuy",
          price: 1856458785,
          amount: 1856458785,
          totalprice: 1856458785,
          statuse: "green",
          opration: "optok",
        },
      ],


      data: [
        {
          name: 'name',
          price: 1885785456,
          balance: 1885785456,
          logo: 'toman.png',
          value: 1885785456
        },
        {
          name: 'name',
          price: 1885785456,
          balance: 1885785456,
          logo: 'btc.png',
          value: 1885785456
        },
        {
          name: 'name',
          price: 1885785456,
          balance: 1885785456,
          logo: 'ada.png',
          value: 1885785456
        },
        {
          name: 'name',
          price: 1885785456,
          balance: 1885785456,
          logo: 'bch.png',
          value: 1885785456
        },
        {
          name: 'name',
          price: 1885785456,
          balance: 1885785456,
          logo: 'bnb.png',
          value: 1885785456
        },

      ],

      item: [
        {level: "level1", percent: 35, active: true},
        {level: "level2", percent: 0, active: false},
        {level: "level3", percent: 0, active: false},
      ],

      options: {
        series: [44, 55, 13, 33, 25, 30 , 22],
        labels: ['BTC', 'ETH', 'BNB', 'LTC', 'XLM', 'DOGE'],

        chart: {
          type: 'donut',
          width: '100%',
          height: 'auto',
          offsetX: 0
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            customScale: 1,
            endAngle: 360,
            offsetX: -100,
            width: '100%',
            donut: {
              size: '85%'
            },
            fill: {
              colors: ['#7956EC', '#009FC5', '#9C27B0', '#5134B2', '#FFC066', '#2FB9F8']
            }
          }
        },

        dataLabels: {
          enabled: false,
          style: {
            colors: ['#7956EC', '#009FC5', '#9C27B0', '#5134B2', '#FFC066', '#2FB9F8']
          }
        },

        legend: {
          show: true,
          offsetX: 150,
          offsetY: 10,
          position: 'right',
          width: 400,
          height: '100%',
          horizontalAlign: 'center',

          markers: {
            width: 35,
            height: 35,
            radius: 7,
            offsetX: 10,
            offsetY: 10
          },

          itemMargin: {
            horizontal: 5,
            vertical: 1
          },

        },

        responsive: [
          {
            breakpoint: 992,
            options: {
              plotOptions: {
                pie: {
                  startAngle: 0,
                  customScale: 1,
                  endAngle: 360,
                  offsetX: 0,
                  width: '100%',
                  donut: {
                    size: '85%'
                  },
                },
              },
              legend: {
                show: true,
                offsetX: 0,
                offsetY: 10,
                position: 'right',
                width: 300,
                height: '100%',
                horizontalAlign: 'center',
              }
            }
          },

          {
            breakpoint: 800,
            options: {
              plotOptions: {
                pie: {
                  startAngle: 0,
                  customScale: 1,
                  endAngle: 360,
                  offsetX: 0,
                  width: '100%',
                  height:'100%',
                  donut: {
                    size: '85%'
                  },
                },
              },
              legend: {
                show: true,
                offsetX: -50,
                offsetY: 10,
                position: 'right',
                width: 300,
                height: '100%',
                horizontalAlign: 'bottom',
              }
            }
          },

          {
            breakpoint: 600,
            options: {
              plotOptions: {
                pie: {
                  startAngle: 0,
                  customScale: 1,
                  endAngle: 360,
                  offsetX: 0,
                  width: '100%',
                  height:'100%',
                  donut: {
                    size: '85%'
                  },
                },
              },
              legend: {
                show: false,
              }
            }
          },
        ],
      },

      series: [44, 55, 41, 17, 15],

      chartOptions: {
        labels: ['Apple', 'Mango', 'Orange', 'Watermelon']
      }
    }
  },

  watch: {
    windowHeight() {
      this.width = this.windowHeight;
      return this.width
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.width = screen.width;
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowHeight = screen.width;
    }
  }

}
</script>

<style lang="scss" scoped>
//public class
.box {
  background-color: var(--background-global);
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.red{
  color:rgba(254, 42, 42, 1);
}

.green{
  color: rgba(0, 163, 95, 1);
}

//css
.main-dashboard {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px;

  .title {
    border-bottom: 1px solid var(--border-light);
    width: 100%;
    padding: 10px;
    font-weight: bold;
    color: var(--font-alternate);
  }

  .chart {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    position: relative;

    .border {
      border: 1px solid var(--border-light);
      margin: 10px 0;
      width: 1px;
      height: 100%;
      position: absolute;
      right: 30%;
      top: 0;
    }

    @media (max-width: 850px) {
      .border{
        display: none !important;
      }
    }

    .right-box {
      flex-grow: 1;
      flex-basis: 30px;
      flex-direction: column;
      gap: 20px;
      display: flex;

      p{
        color:var(--font-alternate);
      }

      .light-span {
        font-size: 12px;
        color: var(--gray);
      }

    }

    .left-box {
      flex-grow: 5;
      flex-basis: 70px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .parent-box {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;

    .child-box1 {
      @extend .box;
      flex-grow: 3;
      flex-basis: 30px;
      gap: 20px;
      max-width: 100%;

      .title{
        color: var(--font-alternate) !important;
      }

      .bar {
        display: flex;
        position: relative;
        align-items: center;

        .circle {
          display: flex;
          flex-grow: 0;
          flex-basis: 30px;
          padding: 15px;
          height: auto;
          border-radius: 50%;
          border: 1px solid transparent;
          background-color: #C9C9C9;
          position: relative;
          right: 5%;
        }

        .active-cricle {
          display: flex;
          flex-grow: 0;
          flex-basis: 30px;
          padding: 15px;
          height: auto;
          border-radius: 50%;
          border: 1px solid transparent;
          background-color: var(--yellow);
          position: relative;
          right: 5%;
        }

        .progresive {
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-grow: 2;
          flex-basis: 80px;
          height: 75%;
          border-radius: 20px 0 0 20px;
          border: 1px solid transparent;
          background-color: var(--kyc-bg);

          span{
            font-size: clamp(12px , 2vw , 16px);
          }
        }

      }

      .leveles {
        display: flex;
        justify-content: space-between;

        .little-span {
          font-size: 12px;
        }
      }
    }

    .child-box2 {
      @extend .box;
      flex-grow: 5;
      flex-basis: 70px;

      table {
        width: 100%;
        color: var(--font-light) !important;

        th{
          color: var(--gray) !important;
        }

        td{
          color: var(--font-alternate);
        }

        div {
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: center;
          align-items: center;
          margin: 20px 0 10px 0;

          img {
            width: auto;
          }
        }

        @media (max-width: 992px) {
          font-size: 12px;
        }
      }

    }
  }

  .opration {
    .header {
      display: flex;
      gap: 30px;
      width: 100%;
      border-bottom: 1px solid var(--border-light);
      margin:0 10px;
      position: relative;

      span {
        cursor: pointer;
        color: var(--font-alternate);
        font-size: clamp(12px , 2vw , 16px);
        width: max-content;
        padding: 10px 0;
      }
    ;

      .selected {
        position: relative;
        font-size: clamp(12px , 2vw , 16px);

        &::before {
          content: "";
          position: absolute;
          width: 130px;
          background-color: var(--yellow);
          height: 3px;
          top: 95%;
          right: -30px;
          border-radius: 5px 5px 0 0;
        }
      }

      .selected2 {
        position: relative;
        font-size: clamp(12px , 2vw , 16px);

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          background-color: var(--yellow);
          height: 3px;
          top: 96%;
          right: 0;
          border-radius: 5px 5px 0 0;
        }
      }
    }

    .WithdrawTable {
      width: 100%;
      border-spacing: 1.5em;

      th {
        color: var(--gray);
        font-size: clamp(12px , 2vw , 16px);
      }

      td {
        text-align: center;
        font-size: clamp(12px , 2vw , 16px);
      }

      img {
        width: auto;
      }
    }
  }

  .DepositTable {
    width: 100%;
    border-spacing: 1.5em;

    .lit-span{
      color: var(--gray);
      font-size: small;
    }

    th {
      color: var(--gray);
      font-size: clamp(12px , 2vw , 16px);
    }

    td {
      text-align: center;
      font-size: clamp(12px , 2vw , 16px);
    }

    img {
      width: auto;
    }
  }
}


@media (max-width: 992px) {
  .parent-box {
    flex-direction: column !important;
  }

  .DepositTable {
    font-size: 12px;
  }

  .WithdrawTable {
    font-size: 12px;
  }

  .header span {
    font-size: 12px;
  }

}
</style>

<style>
[dir="ltr"] .progresive {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-grow: 2;
  flex-basis: 80px;
  height: 75%;
  border-radius: 0 20px 20px 0 !important;
  border: 1px solid transparent;
  background-color: var(--kyc-bg);
}

[dir="ltr"] .circle {
  display: flex;
  flex-grow: 0;
  flex-basis: 30px;
  padding: 15px;
  height: auto;
  border-radius: 50%;
  border: 1px solid transparent;
  background-color: #C9C9C9;
  position: relative;
  left: 5% !important;
}

#apexcharts07ordvjx {
  width: 800px !important;
  height: 170.7px !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.apexcharts-legend {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
}

.apexcharts-legend-series {
  text-align: left;
  direction: ltr;
  margin-left: 0px;

}

.apexcharts-legend-marker {
  margin: 0 !important;
  margin-right: 20px !important;

}

@media (max-width: 800px){
  .apexcharts-legend {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    overflow: hidden !important;
  }

  .apexcharts-legend-marker{
    width: 20px!important;
    height: 20px!important;
  }

  .right-box{
    justify-content: center !important;
    align-items: center  !important;
  }
}

</style>