<template>
  <div class="header-about">
    <div class="layer-about">
    </div>
    <span class="text-about">{{ value }}</span>
  </div>
</template>

<script>
export default {
  props: ["value"],
  name: "TheHeaderAbout"
}
</script>

<style lang="scss" scoped>
.header-about {
  background-image: url("../../assets/About/blockchain.png");
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  .layer-about {
    background-color: rgba(0, 0, 0, 0.9);
  }

  span {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
  }
;
}
</style>