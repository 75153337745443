<template>
  <div class="grid-container">
    <div class="item1">

      <div class="head">
        <span>{{ state.lng.trade.total }}</span>
        <span>{{ state.lng.trade.amount }}</span>
        <span>{{ state.lng.trade.price }}</span>
      </div>

      <div v-for="(item , index) in item1" :key="index"
           :style="{backgroundImage:`linear-gradient(90deg  , rgba(254, 42, 42, 0.08) ${item.percent}%, transparent ${item.percent}%)`}"
           class="prog">
        <div>{{ item.price.toLocaleString() }}</div>
        <div>{{ item.amount.toLocaleString() }}</div>
        <div style="color: #FE2A2A">{{ item.total.toLocaleString() }}</div>
      </div>

      <div class="border">
        <span class="totla">{{ total.toLocaleString() }}</span>.
      </div>

      <div v-for="item in item2" :key="item.percent"
           :style="{backgroundImage:`linear-gradient(90deg  , rgba(0, 183, 106, 0.13) ${item.percent}%, transparent ${item.percent}%)`}"
           class="prog">
        <div>{{ item.price.toLocaleString() }}</div>
        <div>{{ item.amount.toLocaleString() }}</div>
        <div style="color: #00A35F">{{ item.total.toLocaleString() }}</div>
      </div>

    </div>


    <div class="item2">
      <div class="topBar">
        <div class="dropdown">
          <div @click="show = !show" class="txt-btn">
            <p class="p-drop">{{ state.lng.trade.Droptitle }}</p>
            <img src="../../assets/public/bottom-arrow.png" alt="bottom-arrow">
          </div>
          <div v-if="show" class="the-table">
            <the-trade-table/>
          </div>
        </div>
        <div class="show">
          <table>
            <tr>
              <th>{{ state.lng.trade.Price }}</th>
              <th class="remove">{{ state.lng.trade.HighestPrice }}</th>
              <th class="remove">{{ state.lng.trade.LowestPrice }}</th>
              <th>{{ state.lng.trade.Changes }}</th>
              <th>{{ state.lng.trade.Volume }}</th>
            </tr>
            <tr v-for="(item , index) in market" :key="index">
              <td style="color: var(--font-alternate);">{{ item.Price.toLocaleString() }}</td>
              <td class="green remove">{{ item.HighestPrice.toLocaleString() }}</td>
              <td class="red remove">{{ item.LowestPrice.toLocaleString() }}</td>
              <td :class="{'green':item.Changes > 0 , 'red':item.Changes <= 0}">{{ item.Changes + " " + '%' }}</td>
              <td style="color: var(--font-alternate);">{{ item.Volume.toLocaleString() }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>


    <div class="item3">
      <div class="mainTradingView">
        <div class="headerTradingView">
          <span>{{ state.lng.trade.headerTradingView }}</span>
        </div>
        <VueTradingView
            :key=" state.theme + state.local "
            :options="{
            theme : state.theme,
            autosize:true,
            height:'100%',
            width:'100%',
            timezone: 'Asia/Tehran',
            locale: state.local,
        }"/>
      </div>
    </div>


    <div class="item4">
      <marketorder/>
    </div>


    <div class="item5">
      <div class="header-item-5">
        <p>{{ state.lng.trade.MarketTrades }}</p>
      </div>
      <table>
        <tr>
          <th>{{ state.lng.trade.Time }}</th>
          <th>{{ state.lng.trade.amount }}</th>
          <th>{{ state.lng.trade.Price }}</th>
        </tr>

        <tr v-for="(item , index) in MarketTrades" :key="index">
          <td :class="{'red' : item.low === true , 'green' : item.low === false}">{{ item.Price.toLocaleString() }}</td>
          <td>{{ item.Amount.toLocaleString() }}</td>
          <td>{{ item.Time.toLocaleString() }}</td>
        </tr>
      </table>
    </div>


    <div class="item6">
      <TheTradeTable/>
    </div>


    <div class="item7">
      <div class="box opration">
        <div class="header">
           <span :class="{'selected-end' : select === 'Withdraw'}"
                 @click="select = 'Withdraw'">{{ state.lng.dashboard.Withdraw }}</span>
          <span :class="{'selected' : select === 'Deposit'}"
                @click="select = 'Deposit'">{{ state.lng.dashboard.Deposit }}</span>
        </div>

        <table class="DepositTable" v-if="select === 'Deposit'">
          <tr>
            <th>{{ state.lng.dashboard.Date }}</th>
            <th>{{ state.lng.dashboard.Pair }}</th>
            <th>{{ state.lng.dashboard.TypeSide }}</th>
            <th v-if="width > 820">{{ state.lng.dashboard.Pricet }}</th>
            <th>{{ state.lng.dashboard.Amount }}</th>
            <th v-if="width > 820">{{ state.lng.dashboard.TotalPrice }}</th>
            <th v-if="width > 820">{{ state.lng.dashboard.status }}</th>
            <th v-if="width > 820">{{ state.lng.dashboard.Operation }}</th>
          </tr>
          <tr v-for="(item , index) in DepositTable" :key="index">
            <td>{{ item.date }}</td>
            <td>{{ state.lng.dashboard[item.pair] }}</td>
            <td>{{ state.lng.dashboard[item.Type] }}</td>
            <td v-if="width > 820">{{ item.price.toLocaleString() }}</td>
            <td>{{ item.amount }}</td>
            <td v-if="width > 820">{{ item.totalprice.toLocaleString() }}</td>
            <td v-if="width > 820">
              <img v-if="state.lang === 'fa'" :src="require('../../assets/Dashbord/red-fa-status.png')" alt="">
              <img v-if="state.lang === 'en'" :src="require('../../assets/Dashbord/red-en- status.png')" alt="">
            </td>
            <td v-if="width > 820">{{ state.lng.dashboard[item.opration] }}</td>
          </tr>
        </table>

        <table class="WithdrawTable" v-if="select === 'Withdraw'">
          <tr>
            <th>{{ state.lng.dashboard.Date }}</th>
            <th>{{ state.lng.dashboard.Type }}</th>
            <th>{{ state.lng.dashboard.TypeSide2 }}</th>
            <th v-if="width > 820">{{ state.lng.dashboard.Price2 }}</th>
            <th>{{ state.lng.dashboard.Amount }}</th>
            <th v-if="width > 820">{{ state.lng.dashboard.TotalPrice }}</th>
            <th v-if="width > 820">{{ state.lng.dashboard.optok }}</th>
            <th class="cancel-th" v-if="width > 820">{{ state.lng.dashboard.CancelAll }}</th>
          </tr>
          <tr v-for="(item , index) in WithdrawTable" :key="index">
            <td>{{ item.date }}</td>
            <td>{{ state.lng.dashboard[item.pair] }}</td>
            <td>{{ state.lng.dashboard[item.Type] }}</td>
            <td v-if="width > 820">{{ item.transactionid }}</td>
            <td>{{ item.amount.toLocaleString() }}</td>
            <td v-if="width > 820">{{ item.resived.toLocaleString() }}</td>
            <td v-if="width > 820">
              <img v-if="state.lang === 'fa'" :src="require('../../assets/Dashbord/red-fa-status.png')" alt="">
              <img v-if="state.lang === 'en'" :src="require('../../assets/Dashbord/red-en- status.png')" alt="">
            </td>
            <td class="cancel-td" v-if="width > 820">{{ state.lng.dashboard[item.cancel] }}</td>
          </tr>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
import VueTradingView from 'vue-trading-view';
import TheTradeTable from "../tools/TheTradeTable";
import Marketorder from "./MarketOrder/marketorder";

export default {
  name: "trade-viwe",
  components: {Marketorder, TheTradeTable, VueTradingView},
  data() {
    return {
      width: 0,
      windowHeight: screen.width,
      show: false,
      select: 'Withdraw',
      item1: [
        {price: 123584, amount: 212359885, total: 125455, percent: 100},
        {price: 123584, amount: 212359885, total: 125455, percent: 50},
        {price: 123584, amount: 212359885, total: 125455, percent: 60},
        {price: 123584, amount: 212359885, total: 125455, percent: 70},
        {price: 123584, amount: 212359885, total: 125455, percent: 90},
        {price: 123584, amount: 212359885, total: 125455, percent: 80},
        {price: 123584, amount: 212359885, total: 125455, percent: 50},
        {price: 123584, amount: 212359885, total: 125455, percent: 90},
        {price: 123584, amount: 212359885, total: 125455, percent: 80},
        {price: 123584, amount: 212359885, total: 125455, percent: 50},
      ],
      item2: [
        {price: 123584, amount: 212359885, total: 125455, percent: 96},
        {price: 123584, amount: 212359885, total: 125455, percent: 55},
        {price: 123584, amount: 212359885, total: 125455, percent: 65},
        {price: 123584, amount: 212359885, total: 125455, percent: 75},
        {price: 123584, amount: 212359885, total: 125455, percent: 93},
        {price: 123584, amount: 212359885, total: 125455, percent: 85},
        {price: 123584, amount: 212359885, total: 125455, percent: 22},
        {price: 123584, amount: 212359885, total: 125455, percent: 92},
        {price: 123584, amount: 212359885, total: 125455, percent: 82},

      ],
      WithdrawTable: [
        {
          date: "15-06  13:04",
          pair: "type",
          Type: "typesell",
          transactionid: 1452545,
          amount: 1856458785,
          resived: 215425,
          price: 1856458785,
          totalprice: 1856458785,
          fee: 0.25,
          statuse: "stusdone",
          cancel:"cancel"
        },

        {
          date: "15-06  13:04",
          pair: "type",
          Type: "typesell",
          transactionid: 1452545,
          amount: 1856458785,
          resived: 215425,
          price: 1856458785,
          totalprice: 1856458785,
          fee: 0.25,
          statuse: "stusdone",
          cancel:"cancel"
        },

        {
          date: "15-06  13:04",
          pair: "type",
          Type: "typesell",
          transactionid: 1452545,
          amount: 1856458785,
          resived: 215425,
          price: 1856458785,
          totalprice: 1856458785,
          fee: 0.25,
          statuse: "stusdone",
          cancel:"cancel"
        },

        {
          date: "15-06  13:04",
          pair: "type",
          Type: "typesell",
          transactionid: 1452545,
          amount: 1856458785,
          resived: 215425,
          price: 1856458785,
          totalprice: 1856458785,
          fee: 0.25,
          statuse: "stusdone",
          cancel:"cancel"
        },
      ],
      DepositTable: [
        {
          date: "15-06  13:04",
          pair: "type",
          Type: "typesell",
          price: 1856458785,
          amount: 1856458785,
          totalprice: 1856458785,
          statuse: "red",
          opration: "optok",
        },
        {
          date: "15-06  13:04",
          pair: "type",
          Type: "typesell",
          price: 1856458785,
          amount: 1856458785,
          totalprice: 1856458785,
          statuse: "red",
          opration: "optok",
        },
        {
          date: "15-06  13:04",
          pair: "type",
          Type: "typesell",
          price: 1856458785,
          amount: 1856458785,
          totalprice: 1856458785,
          statuse: "green",
          opration: "optok",
        },
      ],
      MarketTrades: [
        {Price: 1235485, Amount: 0.61235, Time: "16:30:33", low: true},
        {Price: 1235485, Amount: 0.61235, Time: "16:30:33", low: false},
        {Price: 1235485, Amount: 0.61235, Time: "16:30:33", low: true},
        {Price: 1235485, Amount: 0.61235, Time: "16:30:33", low: false},
        {Price: 1235485, Amount: 0.61235, Time: "16:30:33", low: false},
        {Price: 1235485, Amount: 0.61235, Time: "16:30:33", low: false},
        {Price: 1235485, Amount: 0.61235, Time: "16:30:33", low: true},
        {Price: 1235485, Amount: 0.61235, Time: "16:30:33", low: false},
        {Price: 1235485, Amount: 0.61235, Time: "16:30:33", low: false},
        {Price: 1235485, Amount: 0.61235, Time: "16:30:33", low: false},
        {Price: 1235485, Amount: 0.61235, Time: "16:30:33", low: true},
        {Price: 1235485, Amount: 0.61235, Time: "16:30:33", low: false},
      ],
      market: [
        {Price: 1235485, Changes: 10, HighestPrice: 1235485, LowestPrice: 1235485, Volume: 1235485,}
      ],
      total: 154123585,

    }
  },

  watch: {
    windowHeight() {
      this.width = this.windowHeight;
      return this.width
    },

    getTheme(){

    }
  },

  mounted() {
    this.$nextTick(() => {
      this.width = screen.width;
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowHeight = screen.width;
    }
  }
}
</script>

<style lang="scss" scoped>
.red {
  color: #FE2A2A;
}

.green {
  color: #00A35F;
}

.cancel-th{
  font-weight: bold;
  text-decoration: underline;
  color: var(--font-alternate) !important;
  cursor: pointer;
}

.cancel-td{
  text-decoration: underline;
  color: var(--font-alternate);
  cursor: pointer;
}
.grid-container {
  display: grid;
  grid-gap: 10px;
  background-color: var(--kyc-bg);
  padding: 10px;
  font-size: clamp(13px, 2vw, 17px) !important;

  div {
    background-color: var(--background-global);
    border-radius: 2px;
  }

  .item1 {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-template-columns: auto;
    display: grid;
    border-radius: 3px;
    padding: 16px 0 16px 0;

    .border {
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid var(--border-light);
      border-bottom: 1px solid var(--border-light);
      position: initial;

      .totla {
        color: #00A35F;
        font-size: large;
      }
    }

    .prog {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px 0;
      direction: rtl !important;
      align-items: center;

      div {
        background-color: transparent;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        font-size: clamp(10px, 2vw, 12px);
      }
    }

    .head {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      text-align: center;
      font-size: clamp(12px, 2vw, 14px);
    }
  }

  .item2 {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 3;
    grid-column-end: 10;
    grid-template-row: auto;

    .topBar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 5px 16px;
      height: 100%;

      .dropdown {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-right: 10px;
        gap: 5px;
        position: relative;

        .the-table {
          position: absolute;
          z-index: 2;
          top: 105%;
          right: 0;
          width: 100%;
        }

        .txt-btn {
          display: flex;
          flex-direction: row;
          gap: 5px;
          align-items: center;
          cursor: pointer;
        }

        img {
          width: 10px;
          height: 5px;
        }

        .p-drop {
          font-weight: bold;
          color: var(--font-alternate);
          font-size: clamp(16px, 2vw, 19px);
        }
      }

      .show {
        flex-grow: 10;
        display: flex;
        flex-wrap: wrap;

        table {
          width: 100%;

          th {
            color: var(--gray);
            font-size: clamp(10px, 2vw, 12px);
          }

          td {
            text-align: center;
            font-size: clamp(12px, 2vw, 14px);

            .black {
              color: var(--font-alternate) !important;
            }
          }
        }
      }
    }
  }

  .item3 {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 3;
    grid-column-end: 9;
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    padding-bottom: 20px;

    .headerTradingView {
      color: var(--font-alternate);
      padding: 11px 20px;
      font-size: clamp(12px, 2vw, 14px);
    }

    .mainTradingView {
      display: flex;
      flex-direction: column;
    }
  }

  .item4 {
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 9;
  }

  .item5 {
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 9;
    grid-column-end: 9;


    .header-item-5 {
      border-bottom: 1px solid var(--border-light);
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      text-align: center;
      padding: 5px 10px;

      p {
        margin: 0;
        color: var(--font-alternate);
        font-size: clamp(12px, 2vw, 14px);
      }
    }

    table {
      width: 100%;
      border-spacing: 0.5em;

      th {
        font-size: clamp(12px, 2vw, 14px);
        color: var(--gray);
      }

      td {
        text-align: center;
        font-size: clamp(12px, 2vw, 14px);
      }

    }
  }

  .item6 {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 9;
    grid-column-end: 9;

  }

  .item7 {
    grid-column-start: 1;
    grid-column-end: 10;

    .opration {
      .header {
        display: flex;
        gap: 30px;
        font-weight: bold;
        width: 100%;
        border-bottom: 1px solid var(--border-light);
        padding: 0 20px;
        position: relative;

        span {
          cursor: pointer;
          color: var(--font-alternate);
          font-size: clamp(12px, 2vw, 14px);
          padding: 10px;
        }
      ;

        .selected {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            background-color: var(--yellow);
            height: 3px;
            top: 95%;
            right: 0;
            border-radius: 5px 5px 0 0;
          }
        }

        .selected-end {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            width: 132px;
            background-color: var(--yellow);
            height: 3px;
            top: 95%;
            right: -20px;
            border-radius: 5px 5px 0 0;
          }
        }
      }

      .WithdrawTable {
        width: 100%;
        border-spacing: 1.5em;

        th {
          color: var(--gray);
          font-size: clamp(10px, 2vw, 13px);
        }

        td {
          text-align: center;
          font-size: clamp(10px, 2vw, 13px);
        }

        img {
          width: auto;
        }
      }
    }

    .DepositTable {
      width: 100%;
      border-spacing: 1.5em;

      th {
        color: var(--gray);
        font-size: clamp(10px, 2vw, 13px);
      }

      td {
        text-align: center;
        font-size: clamp(10px, 2vw, 13px);
      }

      img {
        width: auto;
      }
    }
  }
}

//media query
@media (max-width: 768px) {
  .grid-container {
    div {
      grid-column-start: 1 !important;
      grid-column-end: 3 !important;
      grid-row-start: auto !important;
      grid-row-end: auto !important;
    }
  }

  .show {
    table {
      table-layout: fixed !important;
    }
  }
}

@media (max-width: 400px) {
  .grid-container {
    padding: 0 !important;
  }
}


</style>

<style>
@media (min-width: 768px) {
  #vue-trading-view {
    width: 200% !important;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px){
  .dropdown{
    flex-grow: 1 !important;

  }

  .show{
    flex-grow: 1  !important;

  }

  .remove{
    display: none;
  }

  .item1 {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .item2 {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 3;
    grid-column-end: 10;
  }

  .item3 {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 3 !important;
    grid-column-end: 10 !important;
  }

  .item4 {
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 3 !important;
    grid-column-end: 10 !important;
  }

  .item5 {
    grid-row-start: 4 !important;
    grid-row-end: 4!important;
    grid-column-start: 1 !important;
    grid-column-end: 5 !important;
  }

  .item6 {
    grid-row-start: 4!important;
    grid-row-end: 4!important;
    grid-column-start: 5 !important;
    grid-column-end: 10 !important;

  }
}
@media (max-width: 768px) {
  #vue-trading-view {
    width: 97% !important;
    margin: 0 auto;
  }
}
</style>
