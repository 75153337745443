<template>
  <div class="main">
    <div class="layer">
      <div class="title">
        <h3>{{ state.lng.trade.easytrader }}</h3>
        <p>{{ state.lng.trade.easytext }}</p>
      </div>
      <div class="modal">
        <div class="box">

          <div class="btn">
            <button style="border-radius: 0 2px 2px 0" class="grayBtn"
                    @click="market = 'buy'"
                    :class="{'green' : market === 'buy'}">
              {{ state.lng.trade.buy }}
            </button>
            <button style="border-radius: 2px 0 0 2px" class="grayBtn"
                    @click="market = 'sell'"
                    :class="{'red': market === 'sell'}">
              {{ state.lng.trade.sell }}
            </button>
          </div>

          <div class="inputs">
            <div class="select-coin">
              <div class="main-select">

                <div @click="openSelect =!openSelect" class="select">
                  <div class="logo-name">
                    <img class="btc-logo" :src="require('../../../assets/coins/'+selectItme1.img)" alt="">
                    <span class="coin-name">{{ state.lng.trade[selectItme1.namecoin] }}</span>
                  </div>
                  <img class="arrow-bottom" src="../../../assets/public/litle-arrow.png" alt="">
                </div>
              </div>
              <div class="input-text">
                <input type="text" class="input-text">
              </div>
            </div>
            <transition name="slide">
              <div class="main-opt" v-if="openSelect">
                <div @click="selectvalue = state.lng.trade[item.namecoin] ; ; openSelect = !openSelect ; selectItme1 = item"
                     v-for="(item , index) in dataOption" :key="index" class="option">
                  <img class="btc-logo" :src="require('../../../assets/coins/'+ item.img)" alt="">
                  <span class="coin-name">{{ state.lng.trade[item.namecoin] }}</span>
                </div>
              </div>
            </transition>
          </div>

          <div class="inputs">
            <div class="select-coin">
              <div class="main-select">
                <div @click="openSelect2 =!openSelect2" class="select">
                  <div class="logo-name">
                    <img class="btc-logo" :src="require('../../../assets/coins/'+ selectItme2.img)" alt="">
                    <span class="coin-name">{{ state.lng.trade[selectItme2.namecoin] }}</span>
                  </div>
                  <img class="arrow-bottom" src="../../../assets/public/litle-arrow.png" alt="">
                </div>
              </div>
              <div class="input-text">
                <input type="text" class="input-text">
              </div>
            </div>
            <transition name="slide">
              <div class="main-opt" v-if="openSelect2">
                <div @click="selectvalue2 = state.lng.trade[item.namecoin] ; openSelect2 = !openSelect2 ; selectItme2 = item"
                     v-for="(item , index) in dataOption" :key="index" class="option">
                  <img class="btc-logo" :src="require('../../../assets/coins/'+ item.img)" alt="">
                  <span class="coin-name">{{ state.lng.trade[item.namecoin] }}</span>
                </div>
              </div>
            </transition>
          </div>

          <div class="info">
            <div class="infoText">
              <span>{{ state.lng.trade.Balance }}</span>
              <span>10000{{ state.lng.trade.toman }}</span>
            </div>
            <div class="infoText">
              <span>{{ state.lng.trade.fee }}</span>
              <span>0.4 %</span>
            </div>
            <div class="infoText">
              <span>{{ state.lng.trade.Price2 }}</span>
              <span>10000{{ state.lng.trade.toman }}</span>
            </div>
            <div class="item-btn">
              <button class="market-button" :class="{'green' : market === 'buy' , 'red': market === 'sell'}">
                <span v-if="market === 'buy'">{{ state.lng.trade.buy }} {{selectvalue}}</span>
                <span v-if="market === 'sell'">{{ state.lng.trade.sell }} {{selectvalue}}</span>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "easy-trade",
  data() {
    return {
      market: 'buy',
      openSelect: false,
      openSelect2: false,
      selectvalue: '',
      selectvalue2: this.state.lng.trade.namecoin1,
      selectItme1: {img: 'BTC.png', namecoin: 'namecoin1'},
      selectItme2: {img: 'BTC.png', namecoin: 'namecoin1'},
      dataOption: [
        {img: 'BTC.png', namecoin: 'namecoin1'},
        {img: 'BNB.png', namecoin: 'namecoin2'},
        {img: 'BCH.png', namecoin: 'namecoin3'},
        {img: 'ETH.png', namecoin: 'namecoin4'},
        {img: 'LTC.png', namecoin: 'namecoin5'},
        {img: 'toman.png', namecoin: 'namecoin6'},
      ]
    }
  },

  computed:{
    getlang:function (){
      return this.state.lang;
    }
  },

  watch:{
    getlang:function (){
      this.selectvalue = ''
    }
  }
}
</script>

<style lang="scss" scoped>

[dir="ltr"] .layer {
  background: linear-gradient(-270deg, rgba(0, 0, 0, 0.95) 7.77%, rgba(0, 0, 0, 0.85) 32.43%, rgba(0, 0, 0, 0.75) 47.61%, rgba(0, 0, 0, 0.6) 63.59%, rgba(0, 0, 0, 0.2) 98.58%);
}

[dir="rtl"] .layer {
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.95) 7.77%, rgba(0, 0, 0, 0.85) 32.43%, rgba(0, 0, 0, 0.75) 47.61%, rgba(0, 0, 0, 0.6) 63.59%, rgba(0, 0, 0, 0.2) 98.58%);
}

.layer {
  display: flex;
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

//--------------Animations
.slide-enter {
  /* transform: translateY(20px); */
}

.slide-enter-active {
  animation: slide-in 1000ms ease forwards;
}

.slide-leave {
}

.slide-leave-active {
  animation: slide-out 100ms ease forwards;
}



.main {
  display: flex;
  background-image: url("../../../assets/public/bg-easy-trade.png");
  height: auto;
  position: relative;
  z-index: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  .title {
    h3 {
      color: var(--yellow);
    }

    p {
      color: white;
      font-size: clamp(12px , 2vw , 18px);
      padding: 20px;
    }

    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .modal {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;

    .box {
      display: flex;
      height: 100%;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 100px;
      background-color: var(--background-global);
      border-radius: 3px;
      padding: 20px;
      gap: 20px;
      align-items: center;
      margin: 10px 20px;

      .item-btn {
        display: flex;
        justify-content: center;
        align-items: center;

        .market-button {
          width: 80%;
          padding: 10px;
          margin-top: 20px;
          color: #FFFFFF;
          font-family: VistaDev;
          border: 0;
          border-radius: 3px;
          cursor: pointer;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;

        .infoText {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          color: var(--font-alternate);
        }
      }

      .green {
        background-color: #00A35F !important;
      }

      .red {
        background-color: #FE2A2A !important;
      }

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;

        .grayBtn {
          border: 0;
          background-color: #C6C6C6;
          color: white;
          font-size: medium;
          font-family: VistaDev;
          padding: 5px 10px;
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }

  .inputs {
    width: 100%;
    flex-basis: 50px;

    .main-opt{
      width: 23%;
      position: absolute;
    }

    .option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 135px;
      opacity: 1;
      border: 1px solid var(--trade-select);
      background-color:#FFFFFF;
      font-size: small;
      color: rgba(0, 0, 0, 1);
      position: relative;
      z-index: 5;
      cursor: pointer;
      padding: 5px;

      &:hover {
        border: 3px solid var(--yellow);
      }

      img {
        width: 20px;
      }
    }

    .select-coin {
      justify-content: center;
      font-family: VistaDev, serif !important;
      align-items: center;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      height: 45px;

      .input-text {
        display: flex;
        flex-grow: 2;
        height: 100%;
        width: 30%;
      }
    }

    input {
      flex-grow: 2;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 3px 0 0 3px;
      background-color: var(--trade-input);
      height: auto;
      color: var(--font-light);
    }

    .main-select {
      display: flex;
      flex-direction: column;
      height: 100%;

      .select {
        width: 135px;
        height: 100%;
        border-radius: 0 3px 3px 0;
        border: 1px solid var(--trade-select);
        background-color: var(--trade-select);
        align-items: center;
        display: flex;
        gap: 5px;
        cursor: pointer;
        .logo-name{
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .coin-name {
          font-size: small;
        }

        .btc-logo {
          width: 20px;
          margin-right: 10px;
        }

        .arrow-bottom {
          width: 5%;
          margin: 0 auto;
        }
      }
    }
  }
}



@media (max-width: 833px) {

  .inputs {
    flex-basis: 100px !important;
  }

  .modal {
    width: 300px;
  }
}

@media (max-width: 390px) {
  .modal {
    max-width: 350px !important;
  }

  .select{
    width: 80px !important;
  }

  .btc-logo{
    display: none;
  }

  .coin-name{
    font-size: 12px;
  }

  .option{
    width: 80px !important;
  }
}
</style>