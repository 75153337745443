<template>
  <div class="main-possibilities">
    <div class="layer">
      <div class="header">{{state.lng.possibilities.header}}</div>
      <div class="row-2">
        <div class="right-element">
          <div v-for="(item , index) in items" :key="index" class="item">
            <div class="sec-1">
              <img :src="require('../../../assets/Home/'+item.logo)" alt="">
            </div>
            <div class="sec-2">
              <span class="title">{{ state.lng.possibilities[item.title] }}</span>
              <span class="text">{{ state.lng.possibilities[item.text] }}</span>
            </div>
          </div>
        </div>
        <div class="left-element">
          <img src="../../../assets/Home/Device-Mashup.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ThePossibilities",
  data() {
    return {
      items: [
        {title: 'title1', text: 'text1', logo: 'like.png'},
        {title: 'title2', text: 'text2', logo: 'secure.png'},
        {title: 'title3', text: 'text3', logo: 'headphones.png'},
      ]
    }
  },

}
</script>

<style lang="scss" scoped>

.main-possibilities {
  display: flex;
  flex-direction: column;
  gap: 30px;



  .layer {
    width: 80%;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex-wrap: wrap;

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: rgba(114, 115, 118, 1);
    }

    .row-2 {
      display: flex;
      flex-direction: row;
      gap: 50px;
      flex-wrap: wrap;

      .left-element {
        background-image: url("../../../assets/Home/background.png");
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
          width: 100%;
        }
      }

      .right-element {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        flex-basis: 100px;

        .item {
          display: flex;
          flex-direction: row;
          gap: 30px;

          .sec-2 {
            flex-grow: 1;
            flex-basis: 100px;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            gap: 8px;

            .title {
              color: var(--font-alternate);
              font-weight: bold;
              font-size: clamp(14px , 2vw , 16px);
            }

            .text {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>