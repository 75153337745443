<template>
  <div class="main">
    <div class="header">
      {{ state.lng.kyc.Address }}
      <span class="alert-address">{{ state.lng.kyc.AlertAddress }}</span>
    </div>
    <div class="body">
      <div class="input">
        <div class="inputs">
          <div class="inp-1">
            <global-input width="200" :numberallow="true" :value="state.lng.kyc.mobileNumber"/>
          </div>
          <div class="inp-2">
            <global-input  :value="state.lng.kyc.inputEmail"/>
          </div>
        </div>
        <div class="btn">
          <global-input width="200" :numberallow="true" :value="state.lng.kyc.mobileNumber"/>
          <the-send-code/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalInput from "../../tools/GlobalInput";
import TheSendCode from "../../tools/TheSendCode";

export default {
  name: "TheAddress",
  components: {TheSendCode, GlobalInput}
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  .header {
    border-bottom: 1px solid var(--border-input);
    padding-bottom: 10px;
    color: var(--font-alternate);

    .alert-address {
      color: var(--yellow);
      font-size: small;
    }
  }

  .body {
    display: flex;
    flex-direction: column;

    .input {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;

      .inputs {
        display: flex;
        gap: 20px;
        flex-grow: 2;
        flex-wrap: wrap;

        .inp-1{
          display: flex;
          flex-grow: 0;
        }

        .inp-2{
          display: flex;
          flex-grow: 2;

          .main-global-input  {
            width: 100%;
          }
        }
      }

      .btn {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        width: 100%;
        flex-grow: 1;

        .btn-ruby {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
</style>