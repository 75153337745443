<template>
  <div>
    <user-account v-if="state.Userinfo === true"/>
    <div v-if="state.Userinfo === false" class="main-kyc">
      <div class="steps">
        <div v-for="(item , index) in images" :key="index" class="child-step">
          <div class="position">

            <!--            image icon        -->
            <inline-svg
                @click="next(item.name)"
                :class="{'circle-not-active' : index > images.findIndex(e => e.name === $route.name) , 'circle-active':$route.name  === item.name,
                        'activated':index < images.findIndex(e => e.name === $route.name)
                        }"
                :src="require('../../../assets/KYC/NotActiveIcon/' + item.name + '.svg')"></inline-svg>

            <!--            name icon          -->
            <div class="name-step">
              <span :class="{'active-title':$route.name  === item.name}"  class="span-title">{{ state.lng.kyc[item.id] }}</span>
            </div>
          </div>

          <div v-if="index <= 3" :class="{'border-not-active' : index > images.findIndex(e => e.name === $route.name) , 'border-active':$route.name  === item.name,
          'border-activated':index < images.findIndex(e => e.name === $route.name)}" class="border-active"
               style="margin-left: 10px">
          </div>
        </div>
      </div>

      <div v-if="state.Userinfo === false" class="Authentication">
        <router-view/>
      </div>


      <div class="submit-button">
        <div>
          <the-global-button @click="Submit" width="120" :value="state.lng.kyc.textSubmit"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import TheGlobalButton from "../../tools/TheGlobalButton";
import UserAccount from "../UserAccount";

export default {
  name: "KYC",
  components: {UserAccount, TheGlobalButton},
  data() {
    return {
      active: true,
      activated: true,
      width: 0,
      windowHeight: screen.width,
      images: [
        {name: 'MobileNumber', path: 'mobile.svg', id: 'mobileNumber'},
        {name: 'IdCard', path: 'idcart.svg', id: 'IdCardTitle'},
        {name: 'BankInfo', path: 'bank.svg', id: 'BankTitle'},
        {name: 'Address', path: 'phone.svg', id: 'Address'},
        {name: 'SendImage', path: 'adduser.svg', id: 'headerPicture'},
      ]
    }
  },


  watch: {
    windowHeight() {
      this.width = this.windowHeight;
      return this.width
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.width = screen.width;
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },


  methods: {
    onResize() {
      this.windowHeight = screen.width;
    },

    next(e) {
      this.$router.push({name: e});
    },

    Submit() {
      let currentIndex = this.images.findIndex(e => e.name === this.$route.name);
      if (currentIndex === this.images.length - 1) {
        this.state.Userinfo = true;
      } else
        this.next(this.images[currentIndex + 1].name);
    }
  }
}
</script>

<style lang="scss" scoped>

.main-kyc {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 50px;



  .steps {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    width: 90%;

    .position {
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;

      svg {
        cursor: pointer;
        width:8vw;
        height: 8vw;
        max-width: 110px;
      }


      .name-step {
        width: 8vw;
        top: 110%;
        text-align: center;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        .span-title {
          font-size: clamp(8px, 2vw, 14px);
          width:max-content;
        }

        .active-title{
          @extend .span-title;
          color:#C4D600;
        }
      }
    }

    .child-step {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;
    }


    .border-not-active {
      border: 2px dashed gray !important;
      width: 60%;
      margin: 0 20px;
    }

    .border-activated {
      border: 2px solid var(--yellow) !important;
      width: 60%;
      opacity: 0.6 !important;
      margin: 0 20px;
    }

    .border-active {
      border: 2px solid var(--yellow);
      width: 60%;
      margin: 0 20px;
    }


    .circle-active {
      fill: #C4D600;
      fill-opacity: 1;
      stroke: #C4D600;
      stroke-width: 2;
      stroke-dasharray: 0;
      opacity: 1;
      stroke-opacity: 1;

    }

    .circle-not-active {
      fill: rgba(0, 0, 0, 0.3);
      stroke-width: 0;
      stroke: rgba(0, 0, 0, 0.3);
      stroke-dasharray: 5;
      opacity: 0.6;
    }

    .activated {
      stroke: #C4D600;
      stroke-width: 2;
      fill-opacity: 0.3;
      stroke-opacity: 0.3;
      fill: #C4D600;
      stroke-dasharray: 0;
    }


  }


  .Authentication {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--background-global);
    margin: 30px auto;
    padding: 20px;
    border-radius: 5px;
  }


  .submit-button {
    width: 95%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;

  }
}

</style>