import { render, staticRenderFns } from "./SendImage.vue?vue&type=template&id=752624f6&scoped=true&"
import script from "./SendImage.vue?vue&type=script&lang=js&"
export * from "./SendImage.vue?vue&type=script&lang=js&"
import style0 from "./SendImage.vue?vue&type=style&index=0&id=752624f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "752624f6",
  null
  
)

export default component.exports