<template>
  <div class="header-slide container">
    <div class="container layer">
      <div class="rows">
        <div class="row-1">
          <p class="title-1">{{state.lng.slider.text1}}</p>
          <p class="text">{{state.lng.slider.text2}}</p>
        </div>

        <div class="row-2">
          <p class="title-2">{{state.lng.slider.text3}}</p>
          <button @click="$router.push({name : 'Login'})" class="colorBtn">{{state.lng.buttons.signup}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheHeader",

}
</script>

<style lang="scss" scoped>
/*--------------basis class*/
.container {
  position: relative;
  display: flex;
  flex-direction: column;
}

[dir="ltr"] .layer{
  background: linear-gradient(-270deg, rgba(0, 0, 0, 0.95) 7.77%, rgba(0, 0, 0, 0.85) 32.43%, rgba(0, 0, 0, 0.75) 47.61%, rgba(0, 0, 0, 0.6) 63.59%, rgba(0, 0, 0, 0.2) 98.58%);
}

[dir="rtl"] .layer{
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.95) 7.77%, rgba(0, 0, 0, 0.85) 32.43%, rgba(0, 0, 0, 0.75) 47.61%, rgba(0, 0, 0, 0.6) 63.59%, rgba(0, 0, 0, 0.2) 98.58%);
}


.header-slide {
  background-image: url("../../../assets/Home/slider.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

    .rows {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 50px auto;
      width: 90%;
      gap: 30px;
      color: var(--white);

      .row-1 {
        .title-1 {
          font-size: medium;
        }
        .text {
          color: var(--gray);
        }
      }

      .row-2 {
        .title-2 {
          font-size: small;
        }
      }
    }
}

</style>