import Vue from 'vue'
import en from "./multiLang/en-lang/en";
import fa from "./multiLang/fa-lang/fa";

Vue.prototype.state = Vue.observable({
 help:false,
 alarm:false,
 account:false,
 theme:'light',
 local: localStorage.local = 'fa_IR',
 lang : localStorage.lang = 'fa',
 lng :  localStorage.lang === 'fa' ? fa : en,
 Userinfo:false,
 Token:{name:''}
})