<template>
  <div class="main-input">
    <div class="input-div">

      <div class="input">
        <input class="input-trade" :class="{'gray' : bacground === 'action'}"
               v-model="datainput"
               @input="datainput = $A2N(datainput)"
               type="text">

        <span class="inputValue" v-if="inputValue">{{ inputValue }}</span>

        <datepicker
            key="state.lang"
            format="yyyy-MM-dd"
            :calendar-class="'calendar-class'" :input-class="'input-class'" v-if="img && showdate === true"
            :value="state.date" name="uniquename"
            :language="languages[state.lang]"
        >
        </datepicker>

      </div>


      <div class="inp-value">
        <span v-if="val" class="val borderme2">{{ val }}</span>
        <img v-if="!img" class="border-img" src="../../assets/public/border.png" alt="">
        <img v-if="img" @click="showdate = !showdate" class="date-inp" src="../../assets/public/Date.png" alt="">
      </div>


    </div>

  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

export default {
  name: "The-tradeinput",
  props: ['val', 'img', 'inputValue', 'bacground'],
  components: {
    Datepicker
  },

  data() {
    return {
      datainput: "",
      state: {
        date: new Date(2016, 9, 16)
      },

      languages: ['fa', 'en'],

      showdate: false,
    }
  }

}
</script>

<style lang="scss" scoped>
.main-input {
  display: flex;
  width: 100%;


  .gray {
    background-color: lightgray;
  }

  label {
    width: 100%;
    position: relative;
  }

  .input-trade {
    border: 1px solid #AAAAAA;
    border-radius: 3px;
    padding: 10px;
    width: 100%;
    text-align: center;
    background-color: transparent;
    color: var(--font-light);
    position: relative;
    outline: none;

  }
}

.input-div {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  border: 1px solid #AAAAAA;
  border-radius: 3px;

  .input {
    width: auto;
    position: relative;
    display: flex;
    align-items: center;

    .inputValue {
      position: absolute;
      top: 20%;
      left: 5px;
    }

    input {
      outline: none !important;
      border: 0 !important;
      text-align: left;

    }
  }

  .img {
    width: 20%;
    position: relative;

    img {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 25%;
      left: 5%;
    }
  }

  .inp-value {
    position: relative;

    .border-img {
      position: absolute;
    }

    .date-inp {
      position: absolute;
      left: 5px;
      top: 25%;
    }
  }
}


.vdp-datepicker {
  position: absolute;
  bottom: 10%;
  border: none;
  right: 10px;
  width: fit-content;
}

.datepiker {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
}

</style>

<style>
[dir='rtl'] .inp-value {
  position: absolute;
  width: 25%;
  margin-left: 10px;
}


[dir='ltr'] .inp-value {
  position: absolute;
  width: 45%;
  margin-right: 10px;
}

[dir='rtl'] .border-img {
  right: 0;
  top: 25%;
}


[dir='ltr'] .border-img {
  left: 0;
  top: 25%;
}

[dir='rtl'] .date-inp {
  position: absolute;
  right: 10px;
  top: 25%;
}

[dir='ltr'] .date-inp {
  position: absolute;
  left: 10px;
  top: 25%;
}


.input-class {
  outline: none !important;
  border: 0 !important;
  color: #727376;
  width: 100%;
}

@media screen and (max-width: 9992px) and (min-width: 768px) {
  .border-img {
    display: none;
  }
}
</style>