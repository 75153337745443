<template>
  <div class="login-main">
    <header-aut :value="state.lng.Authentication.forgetPassword"/>
    <global-input :value="state.lng.Authentication.emailInput"/>
    <the-global-button width="100" @click="submit" :value="state.lng.Authentication.next"/>
  </div>
</template>

<script>
import HeaderAut from "./Autcomponents/HeaderAut";
import TheGlobalButton from "../tools/TheGlobalButton";
import GlobalInput from "../tools/GlobalInput";
export default {
  name: "ForgetPassWord",
  components: {GlobalInput, TheGlobalButton, HeaderAut},
  methods:{
    submit(){
      this.$router.push({name : 'ForgetVerify'})
    }
  }
}
</script>

<style lang="scss" scoped>
.login-main {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>