<template>
  <div class="main">
    <div class="header">
      {{ state.lng.kyc.IdCardTitle }}
    </div>
    <div class="body">
      <div class="alert">
        <img src="../../../assets/KYC/exclamation.png" alt="">
        <p class="alert-text">{{ state.lng.kyc.alertText }}</p>
      </div>
      <div class="input">
        <div class="inputs">
          <global-input :numberallow="true" :value="state.lng.kyc.inputEmail"/>
          <global-input :numberallow="true" :value="state.lng.kyc.mobileNumber"/>
        </div>
        <div class="btn">
          <TheSendCode/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalInput from "../../tools/GlobalInput";
import TheSendCode from "../../tools/TheSendCode";

export default {
  name: "MobileNumber",
  components: {TheSendCode, GlobalInput},

}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  .header {
    border-bottom: 1px solid var(--border-input);
    padding-bottom: 10px;
    color: var(--font-alternate);
  }

  .body {
    display: flex;
    flex-direction: column;

    .alert {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      img {
        width: 15px;
        height: 15px;
      }

      .alert-text {
        color: var(--yellow);
        font-size: small;
      }
    }

    .input {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;

      .inputs {
        display: flex;
        gap: 20px;
        flex-grow: 2;
        flex-basis: 200px;
      }

      .btn {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        width: 30%;
        flex-grow: 1;
        flex-basis: 100px;

        .btn-ruby {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
</style>