<template>
  <div class="item">
    <div class="title">
      <p>{{state.lng.notification[item.title]}}</p>
      <img @click="$emit('removeitem')" src="../../assets/public/delete.png" alt="">
    </div>
    <div class="text"><p>{{state.lng.notification[item.text]}}</p></div>
    <div class="date-notif"><p>{{state.lng.notification[item.date]}}</p></div>
  </div>
</template>

<script>
export default {
  name: "ShowNotification",
  props: ['item']
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  flex-direction: column;

  .title{
    display: flex;
    justify-content: space-between;
    font-size: clamp(10px , 2vw , 14px);
    color: var(--font-light);
    font-weight: bold;
    align-items: center;

    img{
      width:20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .text{
    font-size: clamp(10px , 2vw , 11px);
    color: var(--font-light);
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .date-notif{
    color: lightgray;
    font-size: small;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
}
</style>