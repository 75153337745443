<template>
  <div class="main-submit">
    <the-solid-codinput :class="{'opacity' : show === false}" v-if="show === false"/>
    <div class="btn-ruby">
      <the-global-button disabled :class="{'opacity' : show === false}" v-if="show === false" @click="show = true" :value="state.lng.kyc.timer"/>
      <the-global-button  v-if="show === true" @click="show = false" :value="state.lng.kyc.textButton"/>
    </div>
  </div>
</template>

<script>
import TheGlobalButton from "./TheGlobalButton";
import TheSolidCodinput from "./TheSolidCodinput";

export default {
  name: "TheSendCode",
  components: {
    TheSolidCodinput,
    TheGlobalButton,
  },
  data() {
    return {
      show: true
    }
  }
}
</script>

<style scoped>
.main-submit{
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 20px;
}

.btn-ruby{
  display: ruby;
  justify-content: flex-end;
}

.opacity{
  opacity: 0.7;
  cursor: wait;
}
</style>