<template>
  <div :class="{'second': state.Token.name}" class="main-nav">
    <div @click="submit" class="logo">
      <img v-if="state.lang === 'fa' " src="../../../assets/public/fa-logo.png" alt="logo">
      <img v-else src="../../../assets/public/en-logo.png" alt="logo">
    </div>
    <div class="menu">
      <ul class="help-menu">
        <li v-for="(item ,index) in navItems.filter(e => e.name !== 'Help' || state.Token)" :key="index">
          <a class="link" :href="item.route">{{ state.lng.nav[item.name] }}</a>
        </li>
        <li v-if="state.Token.name !== ''" @click="state.help = !state.help">
          <a class="link">{{ state.lng.panel.help }}</a>
          <img class="arrow" src="../../../assets/public/white-bottom-arrow.png" alt="">
        </li>
      </ul>
      <transition name="slide">
        <the-help v-if="state.help" @close="state.help = !state.help"/>
      </transition>
    </div>
    <div class="login">
      <div class="buttons" v-if="!state.Token.name">
        <a @click="signup" class="solidBtn">{{ state.lng.buttons.signup }}</a>
        <a @click="login" class="colorBtn">{{ state.lng.buttons.login }}</a>
      </div>
      <div class="user-info" v-if="state.Token.name">
        <img @click="$router.push({name:'Wallet'})" src="../../../assets/navbar/walet.png" alt="">
        <img @click="state.alarm = !state.alarm" class="notification-img" src="../../../assets/navbar/alarm.png" alt="">
        <img @click="state.account = !state.account" class="user-icon-img" src="../../../assets/navbar/Usericon.png"
             alt="">
        <span @click="state.account = !state.account" class="user-name-class">{{ state.lng.nav.name }}</span>
        <transition name="slide">
          <the-userpanel v-if="state.account"/>
        </transition>
        <transition name="slide">
          <notfications @close="state.alarm = false" v-if="state.alarm === true"/>
        </transition>
      </div>
      <div class="chang-lang">
        <a @click="changeLang('en')" href="#" v-if="state.lang === 'fa'">EN</a>
        <a @click="changeLang('fa')" href="#" v-if="state.lang === 'en'">FA</a>
      </div>
      <div class="chang-theme">
        <img v-if="state.theme === 'dark'" @click="state.theme = 'light'" src="../../../assets/navbar/dark-icon.png"
             alt="">
        <img v-if="state.theme === 'light'" @click="state.theme = 'dark'" src="../../../assets/navbar/light-icon.png"
             alt="">
      </div>
    </div>
  </div>
</template>

<script>
import fa from "../../../lib/multiLang/fa-lang/fa";
import en from "../../../lib/multiLang/en-lang/en";
import Notfications from "../../Notfications/notfications";
import TheUserpanel from "../../Panel/UserPanel/the-userpanel";
import TheHelp from "../../Panel/UserPanel/theHelp";

export default {
  name: "TheNavbar",
  components: {TheHelp, TheUserpanel, Notfications},
  data() {
    return {

      navItems: [
        {
          name: "EasyTrade",
          route: "/EasyTrade"
        },
        {
          name: "Trade",
          route: "/Trade"
        },
        {
          name: 'TheMarket',
          route: "/TheMarket"
        },
        {
          name: 'TheRules',
          route: "/TheRules"
        },
        {
          name: 'Blog',
          route: "/Blog"
        },
        {
          name: '',
          route: ""
        },

      ],
    }
  },

  methods: {
    changeLang(e) {
      localStorage.lang = e;
      this.state.lng = e === 'en' ? en : fa;
      this.state.local = e === 'fa' ? 'fa_IR' : 'en'
      this.state.lang = e;
      this.$root.$children[0].$children.forEach(e => e.$forceUpdate())
    },

    login() {
      this.$router.push({name: 'Login'})
    },

    signup() {
      this.$router.push({name: 'Signup'})
    },

    submit() {
      this.$router.push({name: 'Home'})
    }
  },

  Create() {
    window.addEventListener("click", (event) => {
      if (!event.target.closest('.help-menu , .user-name-class ,.notification-img , .collection, .showNotif')) {
        this.state.help = false;
        this.state.account = false;
        this.state.alarm = false;
      }
    });
  },

}
</script>

<style lang="scss" scoped>
//--------------variables

$font-size: mediume;


//--------------Animations
.slide-enter {
  /* transform: translateY(20px); */
}

.slide-enter-active {
  animation: slide-in 10ms ease-out forwards;
}

.slide-leave {
}

.slide-leave-active {
  animation: slide-out 10ms ease-out forwards;
}

@keyframes slide-in {
  from {
    height: 0;
    padding: 0;
  }
  to {
    height: 100%;

  }
}

@keyframes slide-out {
  from {
    height: 100%;
  }
  to {
    height: 0;
    padding: 0;
  }
}

//---------general styles
.second {
  background-color: var(--second-bg) !important;
}

;
.main-nav {
  display: flex;
  width: 100%;
  background-color: var(--black);
  color: var(--white);
  padding: 0 20px;
  align-items: center;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;

  .list {
    display: flex;
    flex-direction: column;
    background-color: var(--black);
    opacity: 0.8;
    padding: 10px;
    position: absolute;
    z-index: 1;
    height: 100vh;
    width: 50vw;

    .close-window {
      width: 15%;
      cursor: pointer;
    }

    ul {
      gap: 25px;
      padding: 0;

      li {
        list-style: none;
        font-size: $font-size;
        cursor: pointer;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          color: var(--white);
          text-decoration: none;
        }
      }
    }
  }

  .logo {
    flex-grow: 1;
    flex-basis: 100px;
    cursor: pointer;
  }

  .menu {
    flex-grow: 3;
    flex-basis: 470px;
    position: relative;


    ul {
      display: inline-flex;
      gap: 25px;

      li {
        list-style: none;
        font-size: $font-size;
        cursor: pointer;

        a {
          color: var(--white);
          text-decoration: none;
        }

        .arrow {
          margin: 0 10px;
        }
      }
    }
  }

  .login {
    display: flex;
    flex-grow: 4;
    flex-basis: 400px;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;

    .buttons {
      display: flex;
      align-items: center;
      gap: 20px;

      &::after {
        content: "|";
      }
    }

    .user-info {
      display: flex;
      align-items: center;
      gap: 20px;
      z-index: 0;
      position: relative;

      img {
        cursor: pointer;
      }

      &::after {
        content: "|";
      }

    }

    .chang-lang a {
      color: var(--white);
      text-decoration: none;
      cursor: pointer;

      &::after {
        content: "|";
        margin: 15px;
      }
    }

    .chang-theme {
      display: flex;
      cursor: pointer;
    }
  }
}

.main-nav-login {
  display: flex;
  width: 100%;
  background-color: var(--gray);
  color: var(--white);
  direction: rtl;
  padding: 10px 20px;
  align-items: center;

}


</style>