<template>
  <div class="main-fee">
    <the-header-about :value="state.lng.Fee.title"/>
    <div class="fees">
     <span class="titr">
         {{ state.lng.Fee.titr1 }}
     </span>
      <p class="line-1">
        {{ state.lng.Fee.line1 }}
      </p>

      <div class="first-faq">
        <p :class="{'faq':this.state.lang === 'fa' , 'faq-en':this.state.lang === 'en'}">{{ state.lng.Fee.faq1 }}</p>
        <p :class="{'faq':this.state.lang === 'fa' , 'faq-en':this.state.lang === 'en'}">{{ state.lng.Fee.faq2 }}</p>
        <p :class="{'faq':this.state.lang === 'fa' , 'faq-en':this.state.lang === 'en'}">{{ state.lng.Fee.faq3 }}</p>
      </div>

      <div class="table-fees">
        <table>
          <tr>
            <th :class="{'right':state.lang === 'fa' , 'left':state.lang === 'en'}"> {{ state.lng.Fee.th1 }}</th>
            <th class="left"> {{ state.lng.Fee.th2 }}</th>
          </tr>
          <tr>
            <td>{{ state.lng.Fee.td1 }}</td>
            <td class="left">{{ state.lng.Fee.td2 }}</td>
          </tr>

          <tr>
            <td>{{ state.lng.Fee.td3 }}</td>
            <td class="left">{{ state.lng.Fee.td4 }}</td>
          </tr>

          <tr>
            <td>{{ state.lng.Fee.td5 }}</td>
            <td class="left">{{ state.lng.Fee.td6 }}</td>
          </tr>
        </table>
      </div>

      <div class="alert">
        <p class="alert-text">
          {{ state.lng.Fee.alert }}
        </p>
      </div>

      <div class="second-faq">
        <span class="titr">{{ state.lng.Fee.titr2 }}</span>
        <p :class="{'faq':this.state.lang === 'fa' , 'faq-en':this.state.lang === 'en'}">{{ state.lng.Fee.faq4 }}</p>
        <p :class="{'faq':this.state.lang === 'fa' , 'faq-en':this.state.lang === 'en'}">{{ state.lng.Fee.faq5 }}</p>
      </div>

      <div class="second-table">
        <table>
          <tr>
            <th :class="{'right':state.lang === 'fa' , 'left':state.lang === 'en'}"> {{ state.lng.Fee.th1 }}</th>
            <th class="left"> {{ state.lng.Fee.th2 }}</th>
          </tr>
          <tr>
            <td>{{ state.lng.Fee.td7 }}</td>
            <td class="left">{{ state.lng.Fee.td8 }}</td>
          </tr>

          <tr>
            <td>{{ state.lng.Fee.td9 }}</td>
            <td class="left">{{ state.lng.Fee.td10 }}</td>
          </tr>

          <tr>
            <td>{{ state.lng.Fee.td11 }}</td>
            <td class="left">{{ state.lng.Fee.td12 }}</td>
          </tr>

          <tr>
            <td>{{ state.lng.Fee.td13 }}</td>
            <td class="left">{{ state.lng.Fee.td4 }}</td>
          </tr>

          <tr>
            <td>{{ state.lng.Fee.td15 }}</td>
            <td class="left">{{ state.lng.Fee.td16 }}</td>
          </tr>

          <tr>
            <td>{{ state.lng.Fee.td17 }}</td>
            <td class="left">{{ state.lng.Fee.td18 }}</td>
          </tr>

        </table>
      </div>

      <div class="thi-faq">
        <span class="titr">{{ state.lng.Fee.titr2 }}</span>
        <p>{{ state.lng.Fee.faq5 }} </p>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeaderAbout from "../tools/TheHeaderAbout";

export default {
  name: "the-fees",
  components: {TheHeaderAbout}
}
</script>

<style lang="scss" scoped>
.main-fee {
  display: flex;
  flex-direction: column;
  background-color: rgba(196, 214, 0, 0.25);
  justify-content: center;
  align-items: center;

  .fees {
    width: 80%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    color: var(--font-alternate);
    padding: 15px 30px;
    margin: 30px 0;

    .titr {
      font-size: clamp(13px, 2vw, 16px);
      font-weight: bold;
    }

    .line-1 {
      font-size: clamp(13px, 2vw, 16px);
    }

    .faq {
      background-image: url("../../assets/About/faq-arrow.png");
      background-position: right 6px;
      background-repeat: no-repeat;
      font-size: clamp(13px, 2vw, 16px);
      padding-right: 20px;
    }

    .faq-en {
      background-image: url("../../assets/About/faq-en-arrow.png");
      background-position: left 3px;
      background-repeat: no-repeat;
      font-size: clamp(13px, 2vw, 16px);
      padding-left: 20px;
    }

    .table-fees {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 70px;


      .left {
        text-align: left;
      }

      .right {
        text-align: right;
      }

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 320px;
        line-height: 2;
        font-size: clamp(13px, 2vw, 16px);

        tr:first-child {
          border-bottom: 1px solid rgba(152, 166, 0, 1);
        }
      }
    }

    .second-table {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 70px;

      .left {
        text-align: left;
      }

      .right {
        text-align: right;
      }

      table {
        border-collapse: collapse;
        table-layout: auto;
        width: 320px;
        line-height: 2.5;
        font-size: clamp(13px, 2vw, 16px);

        tr:first-child {
          border-bottom: 1px solid rgba(152, 166, 0, 1);
        }
      }
    }

    .alert {
      display: flex;
      margin: 30px 0;

      .alert-text {
        font-size: clamp(13px, 2vw, 16px);
        color: rgba(152, 166, 0, 1);
      }
    }

    .thi-faq {
      display: flex;
      flex-direction: column;
      gap: 14px;
      font-size: clamp(13px, 2vw, 16px);
      margin: 50px 0;
    }
  }
}
</style>