<template>
<div>

</div>
</template>


<script>
export default {
  name: "ResetPassword"
}
</script>

<style scoped>

</style>