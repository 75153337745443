<template>
  <div class="main-notification">
    <div class="header">
      <div>
        <inline-svg
            :src="require('../../assets/public/Notif.svg')"
            :class="{'light-svg' : state.theme === 'dark'}"
        />
        {{ state.lng.notification.notification }}
      </div>
      <inline-svg
          :src="require('../../assets/public/close.svg')"
          :class="{'light-svg' : state.theme === 'dark'}"
          v-if="showItems === false"
          @click="$emit('close')"
      />

      <inline-svg
          :src="require('../../assets/public/back.svg')"
          :class="{'light-svg' : state.theme === 'dark'}"
          v-if="showItems === true"
          @click="showItems = false"
          class="back"
      />

    </div>
    <div class="showNotif">
      <notification-item :items="items" v-if="showItems === false" @senditem = "giveitem"/>
      <show-notification v-if="showItems === true" :item="item" @removeitem = "delet"/>
    </div>
  </div>
</template>

<script>
import NotificationItem from "./NotificationItem";
import ShowNotification from "./ShowNotification";

export default {
  name: "The-notfications",
  components: {ShowNotification, NotificationItem},
  data() {

    return {
      showItems: false,
      item:{},
      items: [
        {title: "title1", text: "text", date: "date", active: true},
        {title: "title2", text: "text", date: "date", active: false},
        {title: "title3", text: "text", date: "date", active: false},
        {title: "title4", text: "text", date: "date", active: false},
        {title: "title5", text: "text", date: "date", active: false},
      ]
    }
  },

  methods: {
    giveitem(e) {
      this.showItems = true;
      this.item = e
    },

    delet() {
      this.item = "";
      this.showItems = false;

    }
  }
}
</script>

<style lang="scss" scoped>
.main-notification {
  display: flex;
  flex-direction: column;
  background-color: var(--background-global);
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 150%;
  height: 100vh;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color: var(--font-light);
    align-items: center;
    border-bottom: 1px solid var(--border-light);

    svg {
      cursor: pointer;
    }

    .light-svg {
      fill: rgba(255, 255, 255, 1) !important;
    }

    div {
      align-items: center;
      gap: 20px;
      display: flex;
      justify-content: flex-start;
      width: 90%;
    }
  }

  .showNotif {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

}

@media (max-width: 868px) {
  .main-notification {
    width: 100% !important;
    top: 120%;
  }
}
</style>