<template>
  <div class="main-About">
    <TheHeaderAbout :value="state.lng.About.market"/>
    <div class="main-table-parent">
      <the-table/>
    </div>
  </div>
</template>

<script>
import TheTable from "../tools/TheTable";
import TheHeaderAbout from "../tools/TheHeaderAbout";

export default {
  name: "TheMarket",
  components: {TheHeaderAbout, TheTable}
}
</script>

<style scoped>
.main-table-parent{
  display: flex;
  width: 80%;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--background-global);
  align-items: center;
  padding: 20px;
  color: var(--second-font-color);
  margin: 10px auto;
  font-size: clamp(12px , 2vw , 16px);
}

@media (max-width: 992px){
  .main-table-parent{
    width: 100% !important;
  }
}

@media (max-width: 350px){
  .main-table-parent{
   padding: 0 5px;
  }
}
</style>