<template>
  <div class="main-order">
    <div class="header">
        <span :class="{'selectedm' : select === 'limit'}"
              @click="select = 'limit'">{{ state.lng.trade.limit }}</span>
      <span :class="{'selectedm2' : select === 'action'}"
            @click="select = 'action' ; selectAtion = true">{{ state.lng.trade.action }}</span>
    </div>
    <div class="market">
      <div class="buy">
      <bazar-comp market="buy" button="button" buybtn="buybtn" selectAtion="selectAtion" :act="select"/>
      </div>
      <div v-if="width > 578" class="border-div"></div>
      <div class="sell">
        <bazar-comp market="sell" button="button" sellbtn="sellbtn" selectAtion="selectAtion" :act="select"/>
      </div>
    </div>
  </div>
</template>

<script>
import BazarComp from "../../tools/bazarComp";
export default {
  name: "The-marketorder",
  components: {BazarComp},
  data() {
    return {
      select:'limit',
      width:0,
      windowHeight: screen.width,
      selectAtion:false
    }
  },

  watch: {
    windowHeight() {
      this.width = this.windowHeight;
      return this.width
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.width = screen.width;
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowHeight = screen.width;
    }
  }
}
</script>

<style lang="scss" scoped>
.main-order {
  display: flex;
  flex-direction: column;
  gap: 50px;

  .header {
    display: flex;
    gap: 30px;
    font-weight: bold;
    width: 100%;
    border-bottom: 1px solid var(--border-light);
    padding: 0 20px;
    position: relative;

    span {
      cursor: pointer;
      color: var(--font-alternate);
      font-size: clamp(12px ,2vw ,14px);
      position: relative;
      padding: 10px;
    }


    .selectedm {

      &::before {
        content: "";
        position: absolute;
        width: 77px;
        background-color: var(--yellow);
        height: 3px;
        top: 95%;
        right:-20px;
        border-radius: 5px 5px 0 0;
      }
    }

    .selectedm2 {
   

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        background-color: var(--yellow);
        height: 3px;
        top: 95%;
        right:0;
        border-radius: 5px 5px 0 0;
      }
    }
  }

  .market{
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    justify-content:space-around;
    flex-wrap: wrap;

    .border-div{
      border-right: 2px dashed var(--border-light);
      margin: 10px 0;
    }

    .buy{
      flex-grow: 1;
      display: flex;
    }

    .sell{
      flex-grow: 1;
      display: flex;
    }
  }
}

@media screen and (max-width: 9992px) and (min-width: 768px){
  .border-div{
    display: none;
  }
}
</style>