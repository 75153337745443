<template>
<div  class="main-cards">
  <div class="inputs">
  <global-input width="400" :numberallow="true"  :value="state.lng.kyc.CartNumber"/>
  <global-input   width="400"  :numberallow="true" :value="state.lng.kyc.Sheba"/>
  </div>
  <img class="delet" @click="$emit('delet')"  src="../../assets/public/delete.png" alt="">

</div>
</template>

<script>
import GlobalInput from "./GlobalInput";
export default {
  name: "TheShowCards",
  components: {GlobalInput},
  data(){
    return{

    }
  },
  methods:{
    test2(value){
      this.testme = value
    }
 }

}
</script>

<style lang="scss" scoped>
.main-cards{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  img{
    width: 30px;
    height: fit-content;
    cursor:pointer;
  }

  .inputs{
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-grow: 2;

  }

}
</style>