<template>
  <div class="login-main">
    <header-aut :value="state.lng.Authentication.EditPassword"/>
    <global-input :value="state.lng.Authentication.PassWordInput"/>
    <global-input type="password" :value="state.lng.Authentication.ConfirmPassword" logo="logo"/>
    <the-global-button width="100" @click="submit"  :value="state.lng.Authentication.submit"/>
  </div>
</template>

<script>
import TheGlobalButton from "../tools/TheGlobalButton";
import GlobalInput from "../tools/GlobalInput";
import HeaderAut from "./Autcomponents/HeaderAut";

export default {
  name: "EditPassword",
  components: {TheGlobalButton, GlobalInput, HeaderAut},
  methods:{
    submit(){
      this.$router.push({name : 'Login'})
    }
  }
}
</script>

<style scoped>
.login-main {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>