<template>
  <div class="main">
    <div class="layer">
      <carousel :navigationNextLabel="null" :navigationPrevLabel="null" :ltr="state.lang === 'en'"
                :rtl="state.lang === 'fa'"
                :perPage="4" :paginationEnabled="false" :navigationEnabled="true" :loop="false"
                :perPageCustom="[[320, 1], [1199, 4]]"
      >
        <slide  v-for="(i , index) in data" :key="index">
          <div class="box">
            <div class="img"><img :src="i.image" alt=""></div>
            <div class="text">
              <div class="row-1">{{ state.lng.carousel[i.title] }}</div>
              <div class="row-2">
                <div class="item">{{ state.lng.carousel[i.text] }}</div>
                <div class="item">{{ state.lng.carousel[i.date] }}</div>
              </div>
            </div>
          </div>
        </slide>
        <div  class="buttons">
          <button class="label-next"></button>
          <button class="label-perv"></button>
        </div>
      </carousel>

    </div>
  </div>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';

export default {
  name: "TheBox",
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      data: [
        {title: "title1", text: "text1", date: "date1", image: require('../../../assets/Home/image-carousel.png')},
        {title: "title1", text: "text1", date: "date1", image: require('../../../assets/Home/image-carousel.png')},
        {title: "title1", text: "text1", date: "date1", image: require('../../../assets/Home/image-carousel.png')},
        {title: "title1", text: "text1", date: "date1", image: require('../../../assets/Home/image-carousel.png')},
        {title: "title1", text: "text1", date: "date1", image: require('../../../assets/Home/image-carousel.png')},
        {title: "title1", text: "text1", date: "date1", image: require('../../../assets/Home/image-carousel.png')},
        {title: "title1", text: "text1", date: "date1", image: require('../../../assets/Home/image-carousel.png')},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;

  .layer {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 20px auto;
  }

  .box {
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);

    .text {
      display: flex;
      flex-direction: column;
      padding: 10px;
      background-color: var(--bg-box);
      justify-content: space-between;
      height: 14vh;
      bottom: 10px;
      position: relative;
    }

    .row-1 {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      color: var(--font-light);
      font-size: small;
    }

    .row-2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: var(--font-light);

      .item {
        display: flex;
        font-size: small;
        color: var(--second-font-color);
      }

    }
  }
}
</style>

<style lang="scss">
.owl-theme .owl-nav {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 40%;
  right: 27px;
  left: -5px;



}
</style>

<style>

.VueCarousel-navigation{
  position: absolute;
  bottom: 40%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.VueCarousel-inner{
  display: flex;
  gap: 10px;
}

[dir = 'rtl'] .VueCarousel-navigation-next {
  color: #FFF;
  font-size: 14px;
  padding: 60px 30px !important;
  display: inline-block;
  cursor: pointer;
  background-image: var(--right-arrow);
  background-color: var( --arrow-bg);
  opacity: 0.5;
  border-radius: 60px 0 0 60px;
  background-size: inherit;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 60px !important;

}

[dir = 'rtl'] .VueCarousel-navigation-prev {
  color: #FFF;
  font-size: 14px;
  padding: 60px 30px !important;
  display: inline-block;
  cursor: pointer;
  background-image: var(--left-arrow);
  background-color: var( --arrow-bg);
  opacity: 0.5;
  border-radius: 0 60px 60px 0;
  background-size: inherit;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 60px !important;
}

[dir = 'ltr'] .VueCarousel-navigation-next {
  color: #FFF;
  font-size: 14px;
  padding: 60px 30px !important;
  display: inline-block;
  cursor: pointer;
  background-image: var(--right-arrow);;
  background-color: var( --arrow-bg);
  opacity: 0.5;
  border-radius:  60px 0 0 60px;
  background-size: inherit;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 60px!important;
}

[dir = 'ltr'] .VueCarousel-navigation-prev {
  color: #FFF;
  font-size: 14px;
  padding: 60px 30px !important;
  display: inline-block;
  cursor: pointer;
  background-image: var(--left-arrow);;
  background-color: var( --arrow-bg);
  opacity: 0.5;
  border-radius: 0 60px 60px 0 ;
  background-size: inherit;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 60px!important;
}
</style>