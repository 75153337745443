<template>
  <the-modal @close="$emit('close')">

      <p v-if="type === 'google'">{{ state.lng.setting.text3 }}</p>
      <p v-if="type === 'email'">{{ state.lng.setting.text2 }}</p>
      <p v-if="type === 'sms'">{{ state.lng.setting.text4 }}</p>
      <div v-if="type === 'google'" class="svg">
        <inline-svg
            :src="require('../../../assets/Authentication/Qrcode.svg')"
            :class="{'light-qr-code' : state.theme === 'dark'}"
        />

    </div>
    <div class="info">
      <the-codinput v-if="type!=='google'"/>
      <the-global-button width="100" :value="state.lng.setting.textBtn"/>
    </div>
  </the-modal>
</template>

<script>
import TheModal from "./TheModal";
import TheCodinput from "../../tools/Thecodinput";
import TheGlobalButton from "../../tools/TheGlobalButton";

export default {
  name: "TheAuthentication",
  props: ['type'],
  components: {TheGlobalButton, TheCodinput, TheModal}
}
</script>

<style scoped>
p {
  font-size: 14px;
}

.light-qr-code {
  fill: #FFFFFF;
}


.svg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 50px;
  gap: 30px;
}
</style>