<template>
  <div class="slidecontainer">
    <input type="range" min="0" max="10000000" class="slider" id="myRange" @input="inputRange" v-model="val">
    <div class="steps-dote">
      <div class="step1" v-for="(step , index) in steps" :key="index"
           @click="setValue(index)">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InputRange',
  components :{

  },

  data() {
    return {

      val:0,

      steps: [
        {
          range:0,
        },
        {
          range:25,
        },
        {
          range:50,
        },
        {
          range:75,
        },
        {
          range:100,
        }
      ]
    }
  },
  methods : {

    setValue(index) {
      this.val=this.steps[index].range
      this.$emit('rangeValue' , this.val)
    },

    inputRange(){
      this.$emit('rangeValue' , this.val)
    }
  }
}
</script>

<style>
.slidecontainer {
  width: 60%;
  position: relative;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #D4D4D4;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  direction: ltr;
  z-index: 2;
  position: relative;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background:var(--yellow);
  cursor: pointer;
  z-index: 3;
  position: relative;
  bottom: 1px;
}

.slider::-ms-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background:var(--yellow);
  cursor: pointer;
  z-index: 3;
  position: absolute;
}

.slider::-moz-range-thumb {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: var(--yellow);
  cursor: pointer;
  z-index: 3;
  position: absolute;
}


.steps-dote {
  position: absolute;
  top: 50%;
  left: -2px;
  width: 100%;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  direction: ltr;
  padding: 0 4px;
  z-index: 1;
}
.step1 {
  width: 20px;
  height: 20px;
  background: #D4D4D4;
  border-radius: 50%;
  cursor: pointer;
  border: 4px solid #D4D4D4;
  z-index:2;
}
</style>