<template>
  <div class="cod-input">
    <CodeInput :width="width" :loading="false" class="input" :fields="5"/>
    <div class="time-div">
      <span class="time">{{ state.lng.Authentication.time }}</span>
      <span class="timer">02:40</span>
    </div>
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input"

export default {
  name: "TheCodinput",
  components: {
    CodeInput,
  },
}
</script>

<style lang="scss">
.cod-input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}


.react-code-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-input);
  border-radius: 5px;
  height: 60px;
  direction: ltr !important;
  width: 100% !important;
}

.react-code-input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-code-input > input {
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  margin: 0 5px !important;
  width: 15% !important;
  height: 10% !important;
}

.time-div{
  display: flex;
  gap: 20px;
  .time{
    font-size: small;
    color: var(--second-font-color);
  }
  .timer{
    font-size: small;
    color: var(--font-light);
  }
}
</style>