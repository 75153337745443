<template>
<button :style="{'width' : width+'%'}" class="global-btn" @click="$emit('click')">{{value}}</button>
</template>

<script>
export default {
  name: "TheGlobalButton",
  props:["value" , "width"]
}
</script>

<style lang="scss" scoped>

.global-btn{
  background-color: var(--yellow);
  font-size: medium;
  color: var(--font-alternate);
  border: 0;
  border-radius: 5px;
  padding: 10px;
  font-family: VistaDev;
  cursor: pointer;
  width: 88px;
}

</style>