<template>
  <div class="Statistics">
    <div class="layer">
      <div class="items">
        <div v-for="( item ,index) in data" :key="index" class="item">
          <span class="title">{{state.lng.Statistics[item.number]}}+ {{state.lng.Statistics[item.symbol]}}</span>
          <span class="text">{{state.lng.Statistics[item.text]}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheStatistics",
  data() {
    return {
      data:[
        {number : "num1" , text : "text1" , symbol : 'symbol1'},
        {number : "num2" , text : "text2" , symbol : 'symbol2'},
        {number : "num3" , text : "text3" , symbol : 'symbol3'},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.Statistics {
  display: flex;
  flex-direction: row;
  gap: 50px;
  background-image: url("../../../assets/Home/art.png");
  background-repeat: repeat;
  background-position: center;
  background-size: cover;

  .layer {
    background: linear-gradient(90deg, #000000 26.04%, rgba(0, 0, 0, 0.74) 49.48%, #000000 76.04%);
    opacity: 0.9;
    display: flex;
    flex-direction: row;
    gap: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px 0;

    .items {
      display: flex;
      flex-direction: row;
      width: 80%;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .item {
        flex-grow: 1;
        flex-basis: 100px;
        color: #FFFFFF;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;

        .title {
          font-size: 25px;
          color: var(--white);
        }

        .text {
          font-size: small;
          color: var(--gray);
        }
      }
    }
  }
}
</style>