<template>
  <div class="Authentication">
    <div class="box">
      <id-card/>
    </div>
    <div class="box">
      <bank-info/>
    </div>
    <div class="box">
      <Address/>
    </div>
  </div>
</template>

<script>
import BankInfo from "./KYC/BankInfo";
import IdCard from "./KYC/IdCard";
import Address from "./KYC/Address";

export default {
  name: "UserAccount",
  components: {Address, IdCard, BankInfo}
}
</script>

<style scoped>
.Authentication {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 30px auto;

}

.box{
  background-color: var(--background-global);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}
</style>