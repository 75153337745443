<template>
  <div class="main-global-input">

    <label class="label-input">
      <input v-model="datainput" :style="{'width' : width+'px'}"
             @input="notAllow"
             :type="type" class="global-input"
      >
      <span>{{ value }}</span>
    </label>
    <img v-if="logo" class="visibility" src="../../assets/Authentication/visibility.png" alt="">
    <img v-if="logo" class="visibility" src="../../assets/Authentication/visibility(2).png" alt="">

  </div>
</template>

<script>
export default {
  name: "GlobalInput",
  props: ["type", "value", "logo", "width", "numberallow"],
  data() {
    return {
      active: false,
      datainput:""
    }
  },

  methods: {
    notAllow(){
      if(this.numberallow === true){
        this.datainput = this.$A2N(this.datainput)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main-global-input {
  display: flex;
  position: relative;

  .label-input {
    position: relative;
    width: 100%;

    input{
      text-align: center;
    }

    .global-input {
      background-color: transparent;
      border: 2px solid var(--border-input);
      border-radius: 5px;
      padding: 10px;
      color: var(--second-font-color);
      font-size: small;
      font-family: VistaDev;
      width: 100%;
      position: relative;
      outline: none;


      &:focus {
        border: 2px solid var(--yellow);
        border-radius: 5px;
        padding: 10px;
        background-color: transparent;
      }
    }

    span {
      color: var(--gray);
      position: absolute;
      bottom: 25%;
      right: 2%;
      padding: 0 10px;
      font-size: small;
      font-family: VistaDev;
      display: ruby;
      width: fit-content;
    }

    .global-input:focus + span {
      color: var(--yellow);
      position: absolute;
      bottom: 75%;
      background-color: var(--background-global);
      padding: 0;
      width: fit-content;
    }
  }

}


.visibility {
  position: absolute;
  right: 85%;
  top: 25%;
}


@media (max-width: 992px) {
  .email-input {
    width: 100% !important;
  }
}

[dir="ltr"] .global-input:focus + span {
  color: var(--yellow);
  position: absolute;
  bottom: 75%;
  background-color: var(--background-global);
  right: 70%;
  padding: 0;
  font-size: small;
  font-family: VistaDev;
  width: fit-content;
}

[dir="ltr"] .global-input+span {
  color: var(--gray);
  position: absolute;
  bottom: 25%;

  left: 2%;
  padding: 0;
  font-size: small;
  font-family: VistaDev;
  width: fit-content;
}

@media (max-width: 992px) {
  .global-input {
    width: 100% !important;
  }
}

[dir="ltr"] .main-global-input {
  .visibility {
    right: auto;
    left: 85%;
  }
}

</style>