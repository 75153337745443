<template>
  <div class="main-setting">
    <Authentication @close="Authentication = '' " :type="Authentication" v-if="Authentication"/>
    <div class="item">
      <p class="title">{{ state.lng.setting.title }}</p>
      <div class="form">
        <p class="text-1">{{ state.lng.setting.text1 }}</p>
        <div class="check">
          <div class="checkbox">
            <label>
              <input v-model="Authentication" value="google" class="checkmark" type="radio" name="radio">
              <span>{{ state.lng.setting.check1 }}</span>
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input v-model="Authentication" value="email" class="checkmark" type="radio" name="radio">
              <span>{{ state.lng.setting.check2 }}</span>
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input v-model="Authentication" value="sms" class="checkmark" type="radio" name="radio">
              <span>{{ state.lng.setting.check3 }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="item password">
      <p class="title">{{ state.lng.setting.title2 }}</p>
      <div class="item-pass-1">
        <div class="inputs">
          <global-input :numberallow="true" :value="state.lng.setting.CurrentPassword" width="250"/>
          <global-input :numberallow="true" :value="state.lng.setting.NewPassword" width="250"/>
          <global-input :numberallow="true" :value="state.lng.setting.ConfirmNewPassword" width="250"/>
        </div>
        <div class="btn">
          <the-global-button width="100" :value="state.lng.setting.btnText"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Authentication from "../Modal/Authentication";
import GlobalInput from "../../tools/GlobalInput";
import TheGlobalButton from "../../tools/TheGlobalButton";

export default {
  name: "TheSetting",
  components: {TheGlobalButton, GlobalInput, Authentication},
  data() {
    return {
      Authentication: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.main-setting {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 5%;

 .cod-input .react-code-input-container{
    width: 100% !important;
  }

  .item {
    background-color: var(--background-global);
    width: 90%;
    margin: 10px auto;
    display: flex;
    padding: 10px;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 5px;

    .password {
      background-color: var(--background-global);
      width: 90%;
      margin: 10px auto;
      display: flex;
      padding: 10px;
      flex-direction: row;
    }

    .text-1 {
      font-size: small;
      color: var(--font-alternate);
    }

    .title {
      border-bottom: 1px solid var(--border-light);
      width: 100%;
      padding: 10px;
      color: var(--font-alternate);
    }

    .form {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px;
      flex-wrap: wrap;

      .check {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;

        .checkbox {
          border: 1px var(--yellow) solid;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: small;
          padding: 10px;

          label {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
          }

          .checkmark {
            -webkit-appearance: none;
            appearance: none;
            width: 1.15em;
            height: 1.15em;
            border: 0.15em solid var(--yellow);
            color: var(--yellow);
            border-radius: 50%;
            margin: 0;
            transform: translateY(-0.075em);
            background-color: #fff;
            display: grid;
            place-content: center;

            &::before {
              content: "";
              width: 0.50em;
              height: 0.50em;
              border-radius: 50%;
              transform: scale(0);
              transition: 120ms transform ease-in-out;
              background-color: var(--yellow);

            }

            &:checked::before {
              background-color: var(--yellow);
              transform: scale(1);
            }
          }
        }
      }
    }
  }

  .item-pass-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    border-radius: 5px;

    .inputs{
      display: flex;
      flex-direction: row;
      gap: 30px;
      flex-grow: 1;
      flex-wrap: wrap;
    }

    .btn{
      display: flex;
      flex-grow: 0;
    }
  }
}
</style>