<template>
  <div class="view-coins">
    <div class="layer">
      <div v-for="(item ,index) in data" :key="index" class="items">
        <div class="logo">
          <img :src="require('../../../assets/public/'+item.logo)" alt="">
        </div>
        <div class="column-container">
          <div class="sec-2">
            <p class="title-bit">{{state.lng.coins[item.name]}}</p>
            <p :class="{'red-number' : state.lng.coins[item.btc] <0 , 'green-number' : state.lng.coins[item.btc] >0}">
              {{ state.lng.coins[item.btc] }}
            </p>
          </div>
          <div class="sec-3"><span class="fee">{{ state.lng.coins[item.usd] }}</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const a = {
  ada: 'ada.png',
  bch: 'bch.png',
  bnb: 'bnb.png',
  bsv: 'bsv.png'
  // btc: 'btc.png',
  // doge: 'doge.png',
  // eth: 'eth.png',
  // ltc: 'ltc.png',
  // usdt: 'usdt.png',
  // xrp: 'xrp.png',
  // xlm: 'xlm.png'
}
export default {
  name: "ViewCoins",
  data() {
    return {

      data: [
        {
          name: 'Bitcoin',
          btc: 'btc1',
          usd: 'usd1',
          logo: 'btc.png'
        },

        {
          name: 'Ethereum',
          btc: 'btc2',
          usd: 'usd2',
          logo: 'eth.png'
        },

        {
          name: 'Ripple',
          btc: 'btc3',
          usd: 'usd3',
          logo: 'xrp.png'
        },
        {
          name: 'Tether',
          btc: 'btc4',
          usd: 'usd4',
          logo: 'bsv.png'
        },
      ]
    }
  },

  computed: {
    icon() {
      return a[this.data.logo]
    }
  },

}
</script>

<style lang="scss" scoped>
//---------------base classes
.item {
  display: flex;
  flex-direction: row;
}

.share-flex {
  display: flex;
  flex-direction: row;
}

.red-number {
  color: #FF1717;
}

.green-number {
  color: #00D33B;
}


//--------------general class
.view-coins {
  @extend .share-flex;
  flex-wrap: wrap;
  gap: 20px;
  background-color: var(--black);

  .layer {
    @extend .share-flex;
    width: 95%;
    margin: 10px auto;
    flex-wrap: wrap;
    gap: 20px;

    .items {
      @extend .share-flex;
      align-items: center;
      flex-grow: 1;
      flex-basis: 160px;

      .logo {
        @extend .item;
        flex-basis: 60px;

        img {
          width: 60%;
          height: max-content;
        }
      }
    ;

      .sec-2 {
        @extend .item;
        gap: 20px;
        align-items: center;

        .title-bit {
          color: #FFFFFF;
        }
      ;

        .btc {
          color: red;
        }
      ;
      }
    ;

      .sec-3 {
        @extend .item;

        .fee {
          color: var(--yellow);
        }
      }
    ;

      .column-container {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>