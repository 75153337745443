<template>
  <CodeInput :loading="false" class="input" :fields="5"/>
</template>

<script>
import CodeInput from "vue-verification-code-input";

export default {
  name: "TheSolidCodinput",
  components: {
    CodeInput,

  },
}
</script>

<style lang="scss" scoped>
.react-code-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-input);
  border-radius: 5px;
  height: 45px;
  direction: ltr !important;
  width: 60%!important;

}

.react-code-input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-code-input > input {
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  margin: 0 5px !important;
  width: 15% !important;
  height: 10% !important;
}

</style>