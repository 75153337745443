<template>
  <div>
    <div v-for="(item , index) in items" :key="index" class="collection">
      <span @click="$emit('senditem' , item)" :class="{'active' : item.active === true}" class="title">{{ state.lng.notification[item.title] }}</span>
      <div class="text">
        <small>{{ state.lng.notification[item.text] }}</small>
        <span>{{ state.lng.notification[item.date] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationItem",
  props: ['items'],
}
</script>

<style lang="scss" scoped>
.collection {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  position: relative;
  border-bottom: 1px solid var(--border-light);


  a {
    font-size: clamp(10px, 2vw, 12px);
    color: var(--font-light);
    text-decoration: none;
    cursor: pointer;
    padding-right: 0;
  }

  .active{
    color: rgba(152, 166, 0, 1) !important;
    position: relative;
    right: 10px;

    &:before{
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 35%;
      right: -12px;
      background-color:rgba(152, 166, 0, 1) ;
    }
  }

  .title:hover + .dote {
    display: block;
  }

  .title{
    font-size: clamp(10px, 2vw, 12px);
    color: var(--font-light);
    text-decoration: none;
    cursor: pointer;
    padding-right: 0;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: clamp(8px, 2vw, 9px);
    color: var(--font-light);

    small {
      flex-grow: 2;
    }

    span {
      color: lightgray;
      flex-grow: 1;
    }
  }
}
</style>

<style>


[dir="ltr"] .active:before{
  left: -12px !important;
}

[dir="ltr"] .active{
  left: 10px !important;
}
</style>