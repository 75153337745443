import Vue from 'vue'
import App from './App.vue'
import  router from "./router"
import './lib/globalFunctions'
import  './styles/style.css'
import  './lib/state'
import './lib/multiLang'
import InlineSvg from 'vue-inline-svg';
import VueApexCharts from 'vue-apexcharts'

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

Vue.use(VueApexCharts)
Vue.component('apex-chart', VueApexCharts)

Vue.component('inline-svg', InlineSvg);
/* eslint-disable */

Vue.config.productionTip = false



new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
