<template>
  <div class="main-panel">

    <div class="right-row">

      <div v-for="(item,index) in data" :key="index" class="icon">
        <inline-svg
            @click="changeRoute(item.name)"
            :src="require('../../assets/panel/'+item.path)"
            class="icon"
            :class="{'icon-active' : $route.matched[1].name === item.name}"
        />

        <small @click="changeRoute(item.name)" v-if="item.name && width > 860">{{ state.lng.panel[item.name] }}</small>


      </div>
    </div>

    <div class="left-row">
      <div v-if="Completionofdocuments === false" class="Alert">
        <div class="img-alert"><img src="../../assets/panel/Alert.png" alt=""></div>
        <div class="text-alert"><p>{{ state.lng.panel.Alert }}</p> <a
            @click="Compelete">{{ state.lng.panel.Completionofdocuments }}</a></div>
      </div>
      <router-view/>
    </div>

  </div>
</template>

<script>
export default {
  name: "UserPanel",
  data() {
    return {
      width: 0,
      windowHeight: screen.width,
      Completionofdocuments:false,
      data: [
        {name: "Dashbord", path: "dashboard.svg"},
        {name: "KYC", path: "user.svg"},
        {name: "Wallet", path: "walet.svg"},
        {name: "History", path: "document.svg"},
        {name: "Setting", path: "setting.svg"},
        {name: "AddFrinds", path: "addfriend.svg"},
      ]
    }
  },

  methods: {
    changeRoute(e) {
      this.$router.push({name: e});
    },

    onResize() {
      this.windowHeight = screen.width;
    },

    Compelete() {
        this.$router.push({name: 'MobileNumber'})
    }
  },

  watch: {
    windowHeight() {
      this.width = this.windowHeight;
      return this.width
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.width = screen.width;
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },



}
</script>

<style lang="scss" scoped>

.icon-active {
  fill: var(--yellow) !important;
}

.icon {
  fill: var(--kyc-bg);
  cursor: pointer;

  small {
    color: var(--yellow);
  }
}

.main-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: auto;

  .right-row {
    display: flex;
    flex-direction: column;
    background-color: var(--second-bg);
    height: auto;
    flex-grow: 4;
    justify-content: flex-start;
    align-items: center;
    width: 8%;
    gap: 50px;
    padding-bottom: 20px;

    .icon {
      display: flex;
      flex-direction: column;
      gap: 5px;
      justify-content: center;
      align-items: center;
    }

    &:first-child {
      padding-top: 50px;
    }

    img {
      width: fit-content;
    }

    small {
      display: ruby;
    }
  }

  .left-row {
    display: flex;
    flex-direction: column;
    background-color: var(--kyc-bg);
    flex-grow: 12;
    width: 90%;
    height: auto;

    .Alert {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 10px 30px;
      border-radius: 5px;

      .img-alert {
        background-color: #EAA0A0FF;
        flex-grow: 1;
        padding: 10px;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .text-alert {
        background-color: rgba(255, 211, 211, 1);
        height: 100%;
        flex-grow: 15;
        flex-wrap: wrap;
        align-items: center;
        display: flex;
        color: black;
        padding:0 10px;
        font-size: clamp(12px,2vw,16px);

        a {
          color: rgba(255, 0, 0, 1);
          padding-right: 10px;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}
</style>