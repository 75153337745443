<template>
  <div class="box-dashboard">

      <div class="chart">
        <apex-chart  width="70%" type="donut" :options="options" :series="series"></apex-chart>
      </div>

  </div>
</template>

<script>
export default {
  name: "the-chart",
  data(){
    return{
      options : {

        legend: {
          width:400,

          markers:{
            width:32,
            height:32,
            radius: 8,
          },

          itemMargin: {
            horizontal: 5,
            vertical: 10
          },

        },

        dataLabels: {
          enabled: false,
        },

        chart: {
          width: 200,
          height: 200,

          toolbar: {
            show: false,
          },

          brush: {
            enabled: false,
          }
        },

        responsive: [

          {
            breakpoint: 1200,

            options: {

              legend: {
                width:350,
              },

              chart: {
                width:800,
              },
            }
          },


          {
            breakpoint: 900,

            options: {

              legend: {
                position: "bottom",
                width:"100%",
              },

              chart: {
                width:380,
                height: 380,
              },
            }
          },

          {
            breakpoint: 800,

            options: {

              legend: {
                position: "bottom",
                width:"100%",
              },

              chart: {
                width:380,
                height: 380,
              },
            }
          },


          {
            breakpoint: 700,

            options: {

              legend: {
                position: "bottom",
                width:"100%",
              },

              chart: {
                width:350,
                height: 350,
              },
            }
          },

          {
            breakpoint: 600,

            options: {

              legend: {
                position: "bottom",
                width:350,
              },

              chart: {
                width:350,
                height: 280,
              },
            }
          },

          {
            breakpoint: 500,

            options: {



              legend: {
                position: "bottom",
                width:300,
              },

              chart: {
                width:300,
                height: 280,
              },
            }
          },

          {
            breakpoint: 400,

            options: {

              legend: {
                position: "bottom",
                width:'auto',
              },

              chart: {
                width:150,
                height: 480,
              },
            }
          },


        ],
      },

      series: [44, 55, 13, 33, 11, 15],

      chartOptions: {
        labels: ['Apple', 'Mango', 'Orange', 'Watermelon','Apple', 'Mango']
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.box-dashboard {
  display: flex;
  flex-grow: 2;
  flex-basis: 100px;

  .chart{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

@media (max-width: 611px) {
  .chart{
    justify-content: center !important;
    align-items: center  !important;
  }


}
</style>