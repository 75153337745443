<template>
  <div class="addFrinds">

    <div class="row-1">
      <span class="title">{{ state.lng.addFrinds.title }}</span>
      <div class="ReferralCode">
        <div class="box-1">
          <img src="../../../assets/Addfrinds/add.png" alt="">
          <div class="text-code">
            <span>{{ state.lng.addFrinds.inviteCode }}</span>
            <div class="copy">
              <span class="code">47876351</span>
              <img class="copy-img" src="../../../assets/Addfrinds/copy.png" alt="">
            </div>
          </div>
        </div>
        <div class="text">
          {{ state.lng.addFrinds.text1 }}
        </div>
      </div>
    </div>


    <div class="row-2">
      <div class="item-1">
        <span class="title">{{ state.lng.addFrinds.title2 }}</span>

        <div class="boxes">

          <div class="box-2">
            <img src="../../../assets/Addfrinds/Icon.png" alt="">
            <div class="text-code">
              <span >{{ state.lng.addFrinds.People }}</span>
              <div class="copy">
                {{state.lng.addFrinds.PeopleNumber}}<small class="light-span">{{state.lng.addFrinds.PeopleText}}</small>
              </div>
            </div>
          </div>

          <div class="box-2">
            <img src="../../../assets/Addfrinds/money-bag.png" alt="">
            <div class="text-code">
              <span>{{ state.lng.addFrinds.YourProfit }}</span>
              <div class="copy">
                {{state.lng.addFrinds.ProfitNumber}}<small class="light-span">{{state.lng.addFrinds.Toman}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="item-2">
        <span class="title">{{ state.lng.addFrinds.title3 }}</span>
        <p>{{state.lng.addFrinds.text2}}</p>
        <div class="input-code">
          <global-input  width="250" :value="state.lng.addFrinds.ReferralCode"/>
          <the-global-button :value="state.lng.addFrinds.Save"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalInput from "../../tools/GlobalInput";
import TheGlobalButton from "../../tools/TheGlobalButton";
export default {
  name: "AddFrinds",
  components: {TheGlobalButton, GlobalInput}
}
</script>

<style lang="scss" scoped>

.box {
  flex-grow: 1;
  background-color: var(--kyc-bg);
  padding: 10px;
  border-radius: 5px;
  flex-direction: row;
  gap: 10px;
  display: flex;
  align-items: center;
  color: var(--font-alternate);

  .text-code {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .copy {
      display: flex;
      gap: 10px;
      align-items: center;

      .copy-img{
        cursor: pointer;
      }

      .light-span{
        color: var(--gray);
      }

      img {
        height: fit-content;
      }
    }
  }
}

.box-1{
  @extend .box;
  justify-content: space-between;
  gap: 30px;
  flex-grow: 1;
  flex-basis: 20px;
}

.box-2{
  @extend .box;
  justify-content: center;
  gap: 20px;
}

.addFrinds {
  display: flex;
  flex-direction: column;
  gap: 10px;


  .title {
    border-bottom: 1px solid var(--border-light);
    padding: 10px;
    width: 100%;
    color: var(--font-alternate);
    font-weight: bold;
  }

  .row-1 {
    display: flex;
    background-color: var(--background-global);
    border-radius: 5px;
    padding: 10px;
    width: 90%;
    margin: 50px auto 0 auto;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;

    .ReferralCode {
      display: flex;
      flex-direction: row;
      gap: 30px;
      flex-wrap: wrap;

      .text {
        flex-grow: 8;
        flex-basis: 80px;
        color: var(--font-alternate);
        font-size: clamp(14px , 2vw , 16px);
      }

    }

  }

  .row-2 {
    display: flex;
    gap: 20px;
    width: 90%;
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: wrap;

    .item-1 {
      display: flex;
      flex-grow: 1;
      background-color: var(--background-global);
      border-radius: 5px;
      padding: 20px;
      flex-direction: column;
      flex-basis: 100px;

      span{
        color: var(--font-alternate);
        font-weight: bold;
        font-size: medium;
      }

      .boxes{
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-top: 20px;
        flex-wrap: wrap;

        .box{
          flex-grow: 1;
        }
      }
    }

    .item-2 {
      display: flex;
      flex-grow: 1;
      background-color: var(--background-global);
      border-radius: 5px;
      padding: 20px;
      flex-direction: column;
      flex-basis: 100px;
      color: var(--font-alternate);

      p{
        font-size: clamp(14px , 2vw , 16px);
      }

      .input-code{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 20px;
      }
    }
  }
}
</style>