<template>
  <div class="main-modal" @click.self="$emit('close' , false)">
    <div class="modal">
      <div class="header">
        <span v-if="opration === 'Deposit'">{{ state.lng.wallet.redBtn }}</span>
        <span v-if="opration === 'Withdraw'">{{ state.lng.wallet.greenBtn }}</span>
        <span>{{ namemodal }}</span>
      </div>

      <div class="text">
        <p>{{ state.lng.wallet.testText }}</p>
        <p class="alert-text">
          <img src="../../../../assets/public/alert.png" alt="">
          {{ state.lng.wallet.alertText }}
        </p>
      </div>


      <div v-if=" namemodal !== 'تومان' &&  namemodal !== 'Toman' && opration === 'Deposit'" class="input-coins">
        <p>{{ state.lng.wallet.Walletaddress }} :</p>
        <div class="Walletaddress">
          <span class="copy">{{ state.lng.wallet.copy }}</span>
            <p>0xe2b27c522585051fde6f3536af4f46c15ef71e84</p>
        </div>
        <div class="qr-code">
          <img src="../../../../assets/public/Qrcode.png" alt="">
        </div>
      </div>

      <div v-if=" namemodal !== 'تومان' &&  namemodal !== 'Toman' && opration === 'Withdraw'" class="input-coins">
        <global-input :value="state.lng.wallet.CoinWithdrawAmount"/>
        <div v-if="opration === 'Withdraw'" class="amount">
          <small>{{ state.lng.wallet.Balance }}</small> : <small>1000000 BTC</small>
        </div>
        <global-input :value="state.lng.wallet.Destinationaddress"/>
        <div class="fee">
          <p>{{state.lng.wallet.fee}}</p>
          <div>
           <span>0.001 {{namemodal}}</span>
          </div>
        </div>
        <div class="btn">
          <button class="payBtn">{{ state.lng.wallet.ViewFees }}</button>
          <button  class="RequestBtn">{{ state.lng.wallet.SubmitRequest }}</button>
        </div>
      </div>

      <div v-if="namemodal === 'Toman' || namemodal === 'تومان'" class="input-Deposit-toman">
        <global-input v-if="opration === 'Deposit'" :value="state.lng.wallet.WithdrawAmountinTomans"/>
        <global-input v-if="opration === 'Deposit'" :value="state.lng.wallet.DeposittoCardNumber"/>
        <div v-if="opration === 'Deposit'" class="amount">
          <small>{{ state.lng.wallet.Balance }}</small> : 1000000 {{ state.lng.wallet.name1 }}
        </div>
        <global-input v-if="opration === 'Withdraw'" :value="state.lng.wallet.inputDeposittoman"/>
        <global-input v-if="opration === 'Withdraw'" :value="state.lng.wallet.PaymentCardNumber"/>
        <div class="btn">
          <button v-if="opration === 'Withdraw'">{{ state.lng.wallet.Payment }}</button>
          <button v-if="opration === 'Deposit'" class="RequestBtn">{{ state.lng.wallet.SubmitRequest }}</button>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import GlobalInput from "../../../tools/GlobalInput";

export default {
  name: "optModall",
  components: {GlobalInput},
  props: ['namemodal', 'opration']
}
</script>

<style lang="scss" scoped>
.main-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  position: fixed;
  inset: 0;
  background: #1322468f;

  .modal {
    background-color: var(--background-global);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 400px;
    padding: 10px;
    height: auto;
    margin: 20px;
    position: relative;

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      flex-direction: row;
      gap: 5px;
      border-bottom: 1px solid var(--border-light);
    }

    .text {
      font-size: 12px;
    }

    .alert-text {
      color: rgba(254, 42, 42, 1);
      font-size: 12px;
    }

    .input-Deposit-toman {
      display: flex;
      flex-direction: column;
      gap: 20px;


      .amount {
        font-size: 13px;
        border-bottom: 1px solid var(--border-light);
        width: fit-content;
      }

      .btn {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        button {
          border: 0;
          background-color: rgba(0, 163, 95, 1);
          padding: 10px 30px;
          color: white;
          border-radius: 5px;
          font-family: VistaDev,serif;
          cursor: pointer;
        }

        .RequestBtn {
          border: 0;
          background-color: rgba(215, 223, 54, 1);
          padding: 10px 30px;
          color: var(--font-light);
          border-radius: 5px;
          font-family: VistaDev,serif;
          cursor: pointer;
        }
      }
    }

    .input-coins {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-bottom: 20px;

      .amount {
        font-size: 13px;
        border-bottom: 1px solid var(--border-light);
        width: fit-content;
      }

      p {
        font-size: small;
        color: var(--gray);
      }

      .Walletaddress {
        display: flex;
        justify-content: space-between;
        background-color: var(--kyc-bg);
        border-radius: 5px;
        padding: 10px;
        gap: 10px;
        flex-wrap: wrap;
        align-items: center;

        .copy {
          color: rgba(254, 183, 0, 1);
          cursor: pointer;
        }
      }

      .qr-code {
        display: flex;
        justify-content: center;
      }

      .fee{
        div{
          background-color: var(--kyc-bg);
          border-radius: 3px;
          padding: 10px;

          span{
            font-size: 12px;
          };
        }
      }

      .btn{
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        .RequestBtn {
          border: 0;
          background-color: rgba(215, 223, 54, 1);
          padding: 5px 30px;
          color: var(--font-light);
          border-radius: 5px;
          font-family: VistaDev,serif;
          cursor: pointer;
        }
        .payBtn {
          border: 1px solid var(--yellow);
          background-color: transparent;
          padding: 5px 30px;
          color: var(--font-light);
          border-radius: 5px;
          font-family: VistaDev,serif;
          cursor: pointer;
        }
      }

    }

  }
}

</style>