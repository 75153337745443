<template>
  <div class="main-footer">
    <div class="padding-footer">

      <div class="row-4">
        <div>
          <img v-if="state.lang === 'fa' " src="../../../assets/public/fa-logo.png" alt="logo">
          <img v-else src="../../../assets/public/en-logo.png" alt="logo">
        </div>

        <div>
          <span class="text-Icon-link">{{ state.lng.footer.text1 }}</span>
          <div class="icon-Network">
            <a :href="telegram"><img src="../../../assets/public/telegram-icon.png" alt=""></a>
            <a :href="whatsapp"><img src="../../../assets/public/whatsapp.png" alt=""></a>
            <a :href="twitter"><img src="../../../assets/public/twitter-icon.png" alt=""></a>
            <a :href="facebook"><img src="../../../assets/public/facebook-icon.png" alt=""></a>
            <a :href="instagram"><img src="../../../assets/public/instagram.png" alt=""></a>
            <a :href="youtube"><img src="../../../assets/public/youtube-icon.png" alt=""></a>
          </div>
        </div>
      </div>


      <div class="menu-items">

        <div class="row-2">
          <ul>
            <li class="lime">{{ state.lng.footer.title1 }}</li>
            <li v-for="n in nav2" :key="n[0]"><a @click="$router.push({name:n.path})">{{ state.lng.footer[n.name] }}</a>
            </li>
          </ul>
        </div>
        <div class="row-3">
          <ul>
            <li class="lime">{{ state.lng.footer.title2 }}</li>
            <li v-for="n in nav1" :key="n[0]"><a @click="$router.push({name:n.path})">{{ state.lng.footer[n.name] }}</a>
            </li>
          </ul>
        </div>
        <div class="row-1">
          <ul>
            <li class="lime">{{ state.lng.footer.title3 }}</li>
            <li class="address-icon">{{ state.lng.footer.address }}</li>
            <li class="phone-icon">{{ state.lng.footer.phone }}</li>
            <li class="email-icon">farnaz.ygh77@yahoo.com</li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
      telegram: '#',
      whatsapp: '#',
      twitter: '#',
      facebook: '#',
      instagram: '#',
      youtube: '#',


      nav1: [
        {path: 'TheAboutUs', name: 'AboutUs'},
        {path: 'TheRules', name: 'Terms'},
        {path: 'Fee', name: 'Wage'},
      ],
      nav2: [
        {path: 'TheGuide', name: 'Help'},
        {path: 'FAQ', name: 'Support'},
        {path: '', name: 'Blog'},
      ],
    }
  }
}
</script>

<style lang="scss" scoped>

//-----------------variables
$max-content: max-content;
//--------------base classes
[dir="ltr"] .icon-footer {
  background-repeat: no-repeat;
  background-position: left;
  padding: 0 30px;
}

[dir="rtl"] .icon-footer {
  background-repeat: no-repeat;
  background-position: right;
  padding: 0 30px;
}

.menu-row-items {
  flex-grow: 1;
  align-items: flex-start;
  flex-basis: 100px;
}

.main-class-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

//-----------------media query
@media only screen and (max-width: 543px) {
  .row-4 {
    align-items: center !important;
  }
}

//--------------general styles
.main-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  background-image: url("../../../assets/footer/bg-footer.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;


  .padding-footer {
    @extend .main-class-footer;
    margin: 24px 100px;

    div {
      display: flex;
      flex-direction: column;
      color: var(--white);
      line-height: 2;
    }

    ul {
      li {
        text-decoration: none;
        list-style: none;

        a {
          color: var(--white);
          text-decoration: none;
        }
      }
    }

    .row-4 {
      display: flex;
      align-items: flex-start;
      gap: 60px;
      flex-grow: 1;
      flex-basis: 100px;
      flex-direction: column;
      margin-top: 40px;

      .icon-Network {
        display: flex;
        gap: 20px;
        flex-direction: row;

        img {
          width: $max-content;
          height: $max-content;
        }
      }

      .text-Icon-link {
        font-size: small;
      }

      img {
        width: $max-content;
      }
    }

    .menu-items {
      display: flex;
      flex-direction: row;
      flex-grow: 2;
      flex-basis: 200px;
      flex-wrap: wrap;

      .row-1 {
        flex-grow: 2;
        flex-basis: 200px;
        align-items: flex-start;

        .address-icon {
          @extend .icon-footer;
          background-image: url("../../../assets/public/location.png");
        }

        .phone-icon {
          @extend .icon-footer;
          background-image: url("../../../assets/public/phone.png");
        }

        .email-icon {
          @extend .icon-footer;
          background-image: url("../../../assets/public/email.png");
        }

      }

      .row-2 {
        @extend .menu-row-items;

        li {
          cursor: pointer;
        }
      }

      .row-3 {
        @extend .menu-row-items;

        li {
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .padding-footer{
    margin: 0 !important;
  }
}

</style>