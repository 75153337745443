<template>
  <div class="main">
    <the-header/>
    <view-coins v-if="width > 768"/>
    <the-chang-coins/>
    <div class="table">
      <div class="row-2">
        <span class="price-text">{{ state.lng.showChange.text2 }}</span>
      </div>
      <the-table/>
    </div>
    <the-possibilities/>
    <the-statistics/>
    <the-box/>
  </div>
</template>

<script>
import TheHeader from "./HomepageSection/Header";
import ViewCoins from "./HomepageSection/ViewCoins";
import TheChangCoins from "./HomepageSection/TheChangCoins";
import ThePossibilities from "./HomepageSection/ThePossibilities";
import TheStatistics from "./HomepageSection/TheStatistics";
import TheTable from "../tools/TheTable";
import TheBox from "./HomepageSection/TheBox";

export default {
  name: "TheHomePage",
  components: {TheBox, TheTable, TheStatistics, ThePossibilities, TheChangCoins, ViewCoins, TheHeader},
  data() {
    return {
      width: 0,
      windowHeight: screen.width,
    }
  },
  watch: {
    windowHeight() {
      this.width = this.windowHeight;
      return this.width
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.width = screen.width;
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowHeight = screen.width;
    }
  }


}

</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
}

.table {
  width: 80%;
  margin: 10px auto;
}

.row-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  color: var(--gray);
  font-weight: 700;
}

@media (max-width: 500px) {
  .table {
    width: 95% !important
  }
}
</style>