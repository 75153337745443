<template>
<div class="help" @click.self="$emit('close')">
  <ul>
    <li v-for="(item , index) in items" :key="index">
      <a :href="item.rout">{{state.lng.panel[item.name]}}</a>
    </li>
  </ul>
</div>
</template>

<script>
export default {
  name: "the-Help",
  data(){
    return{
      items:[
        {name:"name1" , rout:"/TheAboutUs"},
        {name:"name2" , rout:"/FAQ"},
        {name:"name3" , rout:"/TheGuide"},
        {name:"name4" , rout:"/Blog"},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.help{
  display: flex;
  flex-direction: column;
  width: 30%;
  position: absolute;
  z-index: 2;
  background-color: var(--user-panel);
  border-radius: 3px;
  padding-right: 10px;

  ul{
    padding-right: 0;
    li{
      list-style: none;
      margin-bottom: 20px;
      a{
        text-decoration: none;
        font-family: VistaDev;
        color: var(--white);
      }
    }
  }
}
</style>