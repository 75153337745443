<template>
  <div class="login-main">
    <header-aut :value="state.lng.Authentication.login"/>
    <global-input :value="state.lng.Authentication.emailInput"/>
    <global-input type="password" :value="state.lng.Authentication.password" logo="logo"/>
    <router-link class="forget-password" to="ForgetPassWord">{{ state.lng.Authentication.forgetPassword }}</router-link>
    <the-global-button  width="100" @click="submit" :value="state.lng.Authentication.login"/>
    <router-link  class="google-link" to="#">
      <img src="../../assets/Authentication/search.png" alt="">
      {{ state.lng.Authentication.google }}
    </router-link>
    <p class="singup-link"> {{ state.lng.Authentication.question }}
      <router-link class="link-qu" to="Signup">
        {{ state.lng.Authentication.answerLogin }}
      </router-link>
    </p>
  </div>
</template>

<script>
import HeaderAut from "./Autcomponents/HeaderAut";
import GlobalInput from "../tools/GlobalInput";
import TheGlobalButton from "../tools/TheGlobalButton";

export default {
  name: "Login-modal",
  components: {TheGlobalButton, GlobalInput, HeaderAut},
  data() {
    return {
      check: true,
    }
  },
  methods:{
    submit(){
      this.$router.push({name : 'Trade'});
      this.state.Token.name = 'hadi'
    }
  }
}
</script>

<style lang="scss" scoped>
.login-main {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .google-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--second-font-color);
    gap: 10px;
  }

  .forget-password{
    color: var(--second-font-color);
    font-size: small;
    text-decoration: none;
  }

  .singup-link {
    font-size: small;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .link-qu{
    color: var(--yellow);
    text-decoration: none;
  }

  .check-box-label {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 13px;
    color: var(--second-font-color);

    .check-box-icon {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #B4B4B4;
      background-color: var(--yellow);
    }

    .link-terms {
      color: var(--second-font-color);
      text-decoration: none;
    }

    &.checked {
      .check-box-icon {
        background-color: #fff;
      }
    }

    input[type="checkbox"]:checked {
      border: #040F26;

      + {
        .check-box-icon {
          background-color: #fff;
        }
      }
    }

  }
}
</style>