<template>
  <div :class="{'reward-light-bg' : state.theme === 'light' , 'reward-dark-bg' : state.theme === 'dark'}" class="reward">
    <div class="item-1">
      <span>{{ state.lng.panel[items.rewardText] }}</span>
      <span>{{ state.lng.panel.toman }}</span>
    </div>
    <div class="items">
      <div class="item-2">
        <div class="flex-item">
          <div class="cash">
            <span>{{ state.lng.panel.Voucher }}</span>
            <small>{{ state.lng.panel.EXP }} : {{items.date}}</small>
          </div>

          <div class="exp">
            <span class="limit">{{ state.lng.panel.rew }}</span>
            <span v-if="items.expired" class="expired">{{ state.lng.panel.expired }}</span>
          </div>
        </div>
      </div>
      <div class="item-3">
        <div class="flex-item">
          <div class="cash">
            <span class="limit">{{ state.lng.panel.Limit }}</span>
          </div>

          <div class="exp">
           <button v-if="!items.expired" class="reward-btn">{{state.lng.panel.Redeemed}}</button>
            <button v-if="items.expired" class="not-reward-btn">{{state.lng.panel.expired}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheReward",
  props:['items']
}
</script>

<style lang="scss" scoped>
.reward-light-bg{
  background-image: url("../../../assets/panel/bg-reward.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.reward-dark-bg{
  background-image: url("../../../assets/panel/bg-dark-reward.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.reward {
  display: flex;
  flex-direction: row;
  width: 45%;

  .limit {
    background-color: rgba(215, 223, 54, 0.5);
    padding: 3px 15px;
    border-radius: 12px;
    width: fit-content;
    font-size: small;
  }

  .expired {
    font-size: 10px;
    color: rgba(254, 42, 42, 1);
  }

  .flex-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
    padding: 10px 20px;
    .cash {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .exp {
      display: flex;
      flex-direction: column;

      .reward-btn{
        background-color: var(--yellow);
        font-size: small;
        color: var(--second-font-color);
        border: 0;
        border-radius: 5px;
        font-family: VistaDev;
        cursor: pointer;
        width: 88px;
        font-weight: bold;
        padding: 3px 5px;
      }

      .not-reward-btn{
        background-color: rgba(205, 205, 205, 1);
        font-size: small;
        color: var(--font-alternate);
        border: 0;
        border-radius: 5px;
        font-family: VistaDev;
        cursor: pointer;
        width: 88px;
        padding: 3px 5px;
      }
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    flex-basis: 100px;
    flex-grow: 10;
    .item-2 {
      flex-grow: 2;
      display: flex;
      justify-content: space-between;
    }

    .item-3 {
      flex-grow: 2;
      display: flex;
      justify-content: space-between;
    }
  }
}

@media  (max-width: 800px){
  .reward{
    background-position: left;
    width: 100%;
  }
}

@media  (max-width: 460px){
  .flex-item{
    padding: 5px !important;
    font-size: 12px !important;
  }

  .cash{
    flex-wrap: wrap;
    flex-basis: 100px;
  }
}
</style>