<template>
  <div class="login-main">
    <header-aut :value="state.lng.Authentication.forgetPassword"/>
    <p class="email-send">{{ email }}</p>
    <span class="title">{{ state.lng.Authentication.titleForger }}</span>
    <the-codinput/>
    <the-global-button width="100" @click="submit" :value="state.lng.Authentication.ChangePassword"/>
  </div>
</template>

<script>
import HeaderAut from "./Autcomponents/HeaderAut";
import TheCodinput from "../tools/Thecodinput";
import TheGlobalButton from "../tools/TheGlobalButton";

export default {
  name: "ForgetVerify",
  components: {TheGlobalButton, TheCodinput, HeaderAut},
  data() {
    return {
      email: 'farnaz.ygh77@yahoo.com'
    }
  },
  methods:{
    submit(){
      this.$router.push({name : 'EditPassword'})
    }
  }
}
</script>

<style lang="scss" scoped>
.login-main {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .email-send {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-size: 12px;
    display: flex;
    justify-content: center;
    color: var(--font-light);
  }
}
</style>