<template>
  <div class="login-main">
    <header-aut :value="state.lng.Authentication.sinUp2fa"/>
    <span class="title">{{ state.lng.Authentication.Title2fa }}</span>
    <TheCodinput/>
    <div class="btn">
    <TheGlobalButton width="100" @click="submit" :value="state.lng.Authentication.submit"/>
    </div>
  </div>
</template>

<script>
import HeaderAut from "./Autcomponents/HeaderAut";
import TheCodinput from "../tools/Thecodinput";
import TheGlobalButton from "../tools/TheGlobalButton";

export default {
  name: "SingUp2fa",
  components: {TheGlobalButton, TheCodinput, HeaderAut},
  methods:{
    submit(){
      this.$router.push({name : 'Trade'});
      this.state.Token.name = 'hadi'
    }
  }
}
</script>

<style lang="scss" scoped>
.login-main {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    color: var(--second-font-color);
    font-size: 12px;
  }


  .btn{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    button{
      width: 60%;
    }
  }

}
</style>
