<template>
  <div class="panel"  @click="$emit('close')">
    <ul>
      <li>
        <div @click="$router.push({name: item.name})" v-for="(item , index) in items" :key="index" class="item">
          <inline-svg
              :src="require('../../../assets/panel/'+item.img)"
          />
          <a class="span">{{state.lng.panel[item.Li]}}</a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "the-userpanel",
  data(){
    return{
      items:[
        {Li:"Li1" , img:"danger.svg" , name:"MobileNumber"},
        {Li:"Li2" , img:"dashboard.svg", name:"Dashbord"},
        {Li:"Li3" , img:"user.svg", name:"MobileNumber"},
        {Li:"Li4" , img:"reward.svg", name:"Reward"},
        {Li:"Li5" , img:"document.svg", name:"History"},
        {Li:"Li6" , img:"setting.svg", name:"Setting"},
        {Li:"Li7" , img:"addfriend.svg", name:"AddFrinds"},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  ul{
    padding-right: 0 !important;
    li{
      list-style: none;
    }
  }

  .item{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 10px;
    cursor: pointer;

    a{
      text-decoration: none;
      color: lightgray;
    }

    svg{
      fill: rgba(255, 255, 255, 1) !important;
      width: 20px;
      height: 20px;
    }

  }

  .item:hover  .span{
    color: rgba(254, 183, 0, 1);
    cursor: pointer;
  }

  .item:hover  svg{
    fill: rgba(254, 183, 0, 1) !important;
    cursor: pointer;
  }
}

@media(max-width:992px){
  .panel{
    top: 100% !important;
  }
}
</style>

<style>
[dir="rtl"] .panel{
  left: 10% !important;
  width: fit-content;
  display: flex;
  flex-direction: column;
  background-color: var(--user-panel);
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 150%;
  padding: 10px;
}

[dir="ltr"] .panel{
  right: 10% !important;
  width: fit-content;
  display: flex;
  flex-direction: column;
  background-color: var(--user-panel);
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 150%;
  padding: 10px;
}
</style>