<template>
  <div class="main">
    <div class="header">
      {{ state.lng.kyc.IdCardTitle }}
    </div>
    <div class="body">
      <global-input width="250" :value="state.lng.kyc.NameInfo"/>
      <global-input :numberallow="true" width="250" :value="state.lng.kyc.Idcode"/>
      <global-input width="250" :value="state.lng.kyc.FatherName"/>
      <global-input :numberallow="true" width="250" :value="state.lng.kyc.Idcode"/>
      <global-input :numberallow="true" width="250" :value="state.lng.kyc.IDNumber"/>
    </div>
  </div>
</template>

<script>
import GlobalInput from "../../tools/GlobalInput";

export default {
  name: "IdCard",
  components: {GlobalInput}
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  .header {
    border-bottom: 1px solid var(--border-input);
    padding-bottom: 10px;
    color: var(--font-alternate);
  }

  .body {
    display: flex;
    flex-direction: row;
    gap: 20PX;
    flex-wrap: wrap;
  }
}
</style>