<template>
  <div class="main-About">
    <the-header-about :value="state.lng.About.faq"/>
    <div class="main-question">
      <div class="right">
        <the-question-box v-for="(item , index) in data" :key="index" :data="item"/>
      </div>
      <div class="left">
        <img src="../../assets/About/question.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import TheHeaderAbout from "../tools/TheHeaderAbout";
import TheQuestionBox from "../tools/TheQuestionBox";

export default {
  name: "FAQ",
  components: {TheQuestionBox, TheHeaderAbout},
  data(){
    return{
      width:null,
      data:[
        {question : "question1" , answer : "answer1"},
        {question : "question2" , answer : "answer2"},
        {question : "question3" , answer : "answer3"},
        {question : "question4" , answer : "answer4"},
        {question : "question5" , answer : "answer5"},
        {question : "question6" , answer : "answer6"},
      ]
    }
  },

  "mounted"() {
    this.width = screen.width
  },
}
</script>

<style lang="scss" scoped>
.main-question {
  display: flex;
  flex-direction: row;
  padding: 20px 60px;
  flex-wrap: wrap;

  .right {
    flex-grow: 2;
    flex-basis: 100px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .left {
    flex-grow: 1;
    flex-basis: 100px;
    justify-content: flex-end;
    display: flex;

    img{
      width: auto;
      height: 70%;
    }
  }
}

@media (max-width: 668px) {
  .left{
    display: none !important;
  }
}
</style>