<template>
  <div class="main">
    <div class="header" v-if="header">
        <span :class="{'selected' : show === 'limit'}"
              @click="show = 'limit'">{{ state.lng.trade.limit }}</span>
      <span :class="{'selected' : show === 'action'}"
            @click="show = 'action'">{{ state.lng.trade.action }}</span>
    </div>

    <div class="btn" v-if="marketbutton">
      <button style="border-radius: 0 2px 2px 0" class="grayBtn"
              @click="market = 'buy'"
              :class="{'green' : market === 'buy'}">
        {{ state.lng.trade.buy }}
      </button>
      <button style="border-radius: 2px 0 0 2px" class="grayBtn"
              @click="market = 'sell'"
              :class="{'red': market === 'sell'}">
        {{ state.lng.trade.sell }}
      </button>
    </div>


    <div class="inputs">

      <div class="value-text">
        <div class="amount">{{state.lng.trade.Balance}}: <span style=" border-bottom: 1px solid var(--font-alternate);">100000</span></div>
        <div class="fee">{{state.lng.trade.fee}} : <span>0.4</span></div>
      </div>

      <div class="item">
        <div class="txt">
          <span>{{state.lng.trade.Price}} :</span>
        </div>
        <div class="inp">
        <the-tradeinput  :bacground="act || show" :val="state.lng.trade.toman"/>
        </div>
      </div>

      <div class="item">
        <div class="txt">
          <span>{{state.lng.trade.amount}} :</span>
        </div>
        <div class="inp">
          <the-tradeinput :inputValue="range"  val="BTC"/>
        </div>
      </div>

      <div class="item-range">
        <input-range @rangeValue="inputValue"/>
      </div>

      <div class="item">
        <div class="txt">
          <span>{{state.lng.trade.total}} :</span>
        </div>
        <div class="inp">
          <the-tradeinput :val="state.lng.trade.toman"/>
        </div>
      </div>

      <div v-if="show ==='limit' && act === 'limit'"  class="item">
        <div class="txt">
          <span>{{state.lng.trade.Date}} :</span>
        </div>
        <div class="inp">
          <the-tradeinput  img="img"/>
        </div>
      </div>

      <div v-if="button" class="item-btn">
        <button class="market-button" :class="{'green' :buybtn  , 'red': sellbtn}">
          <span v-if="buybtn">{{ state.lng.trade.buy }}</span>
          <span v-if="sellbtn">{{ state.lng.trade.sell }}</span>
        </button>
      </div>

      <div v-if="btn" class="item-btn">
        <button class="market-button" :class="{'green' : market === 'buy' , 'red': market === 'sell'}">
          <span v-if="market === 'buy'">{{ state.lng.trade.buy }}</span>
          <span v-if="market === 'sell'">{{ state.lng.trade.sell }}</span>
        </button>
      </div>

    </div>

  </div>
</template>

<script>
import TheTradeinput from "./The-tradeinput";
import InputRange from "./InputRange";

export default {
  name: "bazarComp",
  components: {InputRange, TheTradeinput},
  data() {
    return {
      show: 'limit',
      market: 'buy',
      range:0
    }
  },
  props: ['header' , 'marketbutton' , 'btn' , 'button' , 'buybtn' , 'sellbtn' , 'selectAtion' , 'act'],

  methods:{
    inputValue(e){
      this.$emit('inputrangeValue' , e);
      this.range = e
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;

  .green {
    background-color: #00A35F !important;
  }

  .red {
    background-color: #FE2A2A !important;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;

    .grayBtn {
      border: 0;
      background-color: #C6C6C6;
      color: white;
      font-size: medium;
      font-family: VistaDev;
      padding: 5px 10px;
      width: 45%;
      cursor: pointer;
    }
  }

  .inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .value-text{
      width: 90%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0 auto;
      gap: 40px;
      color: var(--font-light);
      font-size: 12px;
      span{
        color: var(--gray);
        font-size: 12px;
      }
    }

    .item {
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
      width: 90%;

      .market-button{
        width: 100%;
        padding: 10px;
        color: #FFFFFF;
        font-family: VistaDev;
        border: 0;
        border-radius: 3px;
        cursor: pointer;
      }

      .txt{
        display: flex;
        flex-grow: 1;
        flex-basis: 100px;

        span{
          font-size: small;
          font-weight: bold;
        }
      }

      .inp{
        display: flex;
        flex-grow: 5;
        flex-basis: 50px;
      }
    }

    .item-range{
      @extend .item;
      justify-content: flex-end;
    }

    .item-btn{
      @extend .item;
      justify-content: center;
      padding-bottom: 20px;
    }
  }

  .header {
    display: flex;
    gap: 30px;
    font-weight: bold;
    width: 100%;
    border-bottom: 1px solid var(--border-light);
    padding: 10px;
    position: relative;

    span {
      cursor: pointer;
      color: var(--font-alternate) !important;
    }


    .selected {
      font-size: medium;
      position: relative;
      color: var(--font-alternate);

      &::before {
        content: "";
        position: absolute;
        width: 70px;
        background-color: var(--yellow);
        height: 3px;
        bottom: 0;
        top: 32px;
        right: -20px;
        border-radius: 5px 5px 0 0;
      }
    }
  }
}

@media screen and (max-width: 9992px) and (min-width: 768px){
  .border-div{
    display: none;
  }
}
</style>