<template>
  <div class="main-modal" @click.self="$emit('close')">
    <div class="modal">
      <slot></slot>
    </div>
  </div>
</template>
<script>

export default {
  props: ['value'],
  name: "TheModal",
  data() {
    return {}
  }
}
</script>

<style scoped>
.main-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  height: 100vh;
  background-image: url("../../assets/Authentication/bg-modal.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.modal {
  background-color:var(--global-transparent);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: 30px;
  height: auto;
  position: relative;
  width: 400px;

}

@media (max-width: 350px) {
  .modal {
    max-width: 300px !important;
  }
}/*}*/

</style>