<template>
  <div class="main-About">
    <TheHeaderAbout :value="state.lng.About.Rules"/>
    <div class="text-main">
      <p class="text">{{state.lng.About.text}}</p>
    </div>
  </div>
</template>

<script>
import TheHeaderAbout from "../tools/TheHeaderAbout";

export default {
  name: "TheRules",
  components: {TheHeaderAbout}
}
</script>

<style lang="scss" scoped>
.text-main {
  display: flex;
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: var(--background-global);
  align-items: center;
  padding: 20px;
  color: var(--font-alternate);
  line-height: 2;
}
</style>