<template>
  <div class="main">
    <div class="header">
      {{ state.lng.kyc.Authen }}
    </div>
    <div class="body">
      <p>
        {{state.lng.kyc.textSendImage}}
      </p>
      <div class="main">
        <div class="send-image-row">
          <header>{{ state.lng.kyc.headerIdCart }}</header>
          <div class="main-row">
            <div class="Upload-btn">
              <div class="icon-plus">
                <label class="btn btn-default btn-file input-file">
                  <img src="../../../assets/KYC/plus.png" alt=""> <input type="file" style="display: none;" required>
                </label>
              </div>
              <the-global-button width="100" :value="state.lng.kyc.sendButton"/>
            </div>
            <div class="image-send">
              <img src="../../../assets/KYC/pic-user-send.png" alt="">
            </div>
          </div>
        </div>
        <div class="send-image-row">
          <header>{{ state.lng.kyc.headerPicture }}</header>
          <div class="main-row">

            <div class="Upload-btn">
              <div class="icon-plus">
                <label class="btn btn-default btn-file input-file">
                  <img src="../../../assets/KYC/plus.png" alt=""> <input type="file" style="display: none;" required>
                </label>
              </div>
              <the-global-button width="100" :value="state.lng.kyc.sendButton"/>
            </div>

            <div class="image-send">
              <img src="../../../assets/KYC/pic-user-send.png" alt="">
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheGlobalButton from "../../tools/TheGlobalButton";

export default {
  name: "SendImage",
  components: {TheGlobalButton},

}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  .header {
    border-bottom: 1px solid var(--border-input);
    padding-bottom: 10px;
    color: var(--font-alternate);
    font-weight: bold;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 20PX;
    flex-wrap: wrap;

    p{
      color: var(--font-alternate);
    }

    .main {
      display: flex;
      width: 70%;
      margin: 0 auto;
      justify-content: space-between;
      flex-direction: row;
      gap: 40px;
      flex-wrap: wrap;

      .send-image-row {
        background-color: var(--kyc-bg);
        display: flex;
        flex-grow: 1;
        flex-basis: 100px;
        border-radius: 3px;
        flex-direction: column;
        padding: 10px;
        gap: 20px;

        header {
          display: flex;
          justify-content: center;
        }

        .main-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0 20px;
          gap: 20px;
          flex-wrap: wrap;


          .Upload-btn {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-basis: 100px;
          }

          .icon-plus {
            border: 2px dashed gray;
            border-bottom: 0;
            justify-content: center;
            align-items: center;
            display: flex;
            padding: 10px;
          }

          .image-send {
            display: flex;
            flex-grow: 1;
            flex-basis: 100px;

            img {
              width: 100%;
              height: auto;
            }
          }

        }

      }
    }
  }
}
</style>