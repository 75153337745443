export default {
    nav: require('./nav.json'),
    slider: require('./slider.json'),
    buttons: require('./buttons.json'),
    footer: require('./footer.json'),
    coins: require('./coins.json'),
    price : require('./price.json'),
    coinsChange :require('./coinsChange.json'),
    showChange :require('./showChange.json'),
    possibilities :require('./possibilities.json'),
    Statistics : require('./Statistics.json'),
    tableTitle: require('./tableTitle.json'),
    dataTable: require('./dataTable.json'),
    carousel: require('./carousel.json'),
    About : require('./About.json'),
    Fee : require('./fee.json'),
    Authentication :require('./Authentication.json'),
    kyc:require('./kyc.json'),
    wallet:require('./wallet.json'),
    history:require('./history.json'),
    setting:require('./setting.json'),
    panel:require('./panel.json'),
    addFrinds:require('./addFrinds.json'),
    dashboard:require('./dashboard.json'),
    trade:require('./trade.json'),
    notification:require('./notification.json'),
}